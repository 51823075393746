import { useLocation } from 'react-router-dom';

export const useTabTitle = () => {
  const location = useLocation();

  const deprecatedSymbols = ['/', '-', '&'];

  let title = location.pathname;

  deprecatedSymbols.forEach(
    (symbol) => (title = title.replaceAll(symbol, ' ')),
  );
  title = title.trim();

  if (title.length === 0) return 'Lab K19';

  return `${title[0].toUpperCase()}${title.slice(1)}`;
};
