import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  Text,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { CustomSlider } from './Slider';

export const TopServices = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${1250}px)`,
  });

  return (
    <Section h={isMobile || isTablet ? 'auto' : '700px'}>
      <FlexColumn
        w="100%"
        h="auto"
        p="40px 0"
        alignItems="center"
        bg={COLORS.darkBlue}
      >
        <FlexColumn w="80%">
          <Text
            m="30px 0"
            size={!isMobile ? TEXT_SIZES.mega : '36px'}
            color={COLORS.lightBlue}
            weight={TEXT_WEIGHTS.bolder}
            type={TEXT_TYPES.title}
            animatable
          >
            {t('landing.topServicesSection.title')}
          </Text>
        </FlexColumn>
        <FlexRow w="90%" h="auto">
          <CustomSlider />
        </FlexRow>
      </FlexColumn>
    </Section>
  );
};
