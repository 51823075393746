import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopVideoCreation } from './VideoCreation.desktop';
import { MobileVideoCreation } from './VideoCreation.mobile';

export const VideoCreation = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileVideoCreation />;

  return <DesktopVideoCreation />;
};
