import { useMediaQuery } from 'react-responsive';
import { DesktopPartners } from './Partners.desktop';
import { MobilePartners } from './Partners.mobile';

export const Partners = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  if (isMobile) return <MobilePartners />;

  return <DesktopPartners />;
};
