import styled from 'styled-components';
import { COLORS, FlexColumn, FlexRow } from 'modules/ui/components/Flex';
import { TEXT_WEIGHTS } from 'modules/ui/components';

export const StyledHeader = styled(FlexRow)`
  z-index: 10;
  width: calc(100% - 60px);
  height: 60px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
`;

export const StyledDropdownWrapper = styled(FlexColumn)`
  position: relative;
  height: 120%;
  width: 170px;
  align-items: center;

  &:hover .dropdown {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
`;

export const StyledDropDown = styled(FlexColumn)`
  transition: 0.3s all;
  background: #8cffff;
  width: 100%;
  border-radius: 25px;
  align-items: center;
  position: absolute;
  top: 90%;
  padding: 17px 5px;
  opacity: 0;
  visibility: hidden;
  color: ${COLORS.darkBlue};

  .link {
    color: ${COLORS.darkBlue} !important;
    font-weight: ${TEXT_WEIGHTS.bolder} !important;

    &:hover {
      color: white !important;
    }
  }
`;
