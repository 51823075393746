export const subCard = new Array(6).fill(' ');
export const tabs = new Array(3).fill(' ');

export const sliderMobileStyles = `
.slick-arrow {
    width: 70px;
    height: 100%;
    top: 150px;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 5px;
  };

  .slick-next {
    right: -30px
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
  }

  .slick-active {
   
  }

  .slick-slide {
    transform: translateX(-20px);
    // visibility: hidden;
  }
`;

export const tabsSliderMobileStyle = `
transform: translateX(-5%);
  .slick-arrow {
    width: 40px;
    height: 50%;
    background: none !important;
    top: 250px;
  };

  .img {
    transform: scale(0.5);
  }

  .slick-prev {
    left: 15%;
  };

  .slick-next {
    right: 5%
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

 
`;
