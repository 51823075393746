export const cards = new Array(3).fill(' ');
export const subCards = new Array(8).fill(' ');
export const tabs = new Array(7).fill(new Array(5).fill(' '));

export const sliderMobileStyles = `
transform: translateX(-10%);
 .slick-arrow {
    width: 40px;
    height: 100px;
    background: none !important;
    top: 150px;
 };

  .img {
    max-width: 100px;
    height: auto;
  }

  .slick-prev {
    left: 10%;
  };

  .slick-next {
    right: -10%
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

`;

export const sliderTabsMobileStyles = `
transform: translateX(-8%);
.slick-arrow {
   width: 40px;
   height: 100px;
   background: none !important;
   top: 150px;
};

 .img {
   max-width: 100px;
   height: auto;
 }

 .slick-prev {
   left: 20%;
 };

 .slick-next {
   right: 5%
 };

 .slick-slide div {
   width: 90%;
   display: flex !important;
   flex-direction: column;
   align-items: center;
 }

`;
