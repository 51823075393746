import { FlexColumn } from 'modules/ui/components';
import styled from 'styled-components';

export const Card = styled(FlexColumn)`
  width: 560px;
  height: 500px;
  border-radius: 54px;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (min-width: 450px) and (max-width: 1350px) {
    transform: scale(0.9);
    margin: 0;
  }

  @media screen and (min-width: 450px) and (max-width: 880px) {
    aspect-ratio: 1 / 1;
    transform: scale(0.9);
  }
`;

export const MobileCard = styled(Card)`
  width: 90%;
  height: auto;
  margin: 10px 0;
  min-height: 400px;
  height: auto;
`;
