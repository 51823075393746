import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { TeamSection } from 'modules/sections/components/TeamSection';
import {
  FlexColumn,
  FlexRow,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { wordMarker } from 'modules/utils/wordMarker';

export const MobileAboutUs = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" position="relative">
      <PageTitle title={t('aboutUs.title')} />
      <FlexColumn
        w="100%"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn w="80%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="35px 0 30px 0"
                  size="35px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                >
                  {t('aboutUs.cards.0.title')}
                </Text>
                <Text
                  w="100%"
                  size="28px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  textAlign="center"
                  letterSpacing="0.88px"
                  lineHeight="30px"
                >
                  {t('aboutUs.cards.0.texts.0')}
                </Text>
                <Text
                  w="100%"
                  size="25px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  textAlign="center"
                  letterSpacing="0.88px"
                  lineHeight="30px"
                >
                  {t('aboutUs.cards.0.texts.1')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexRow m="100px 0 20px 0">
            <img src="./assets/utils/settings-finger.png" alt="story" />
          </FlexRow>
          <FlexColumn w="100%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="35px 0 30px 0"
                  size={'35px'}
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                >
                  {t('aboutUs.cards.1.title')}
                </Text>
                <Text
                  w="80%"
                  size="16x"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.62px"
                  lineHeight="24px"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: wordMarker(
                        t('aboutUs.cards.1.texts.0'),
                        'Lab K19',
                      ),
                    }}
                  />
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexColumn
            w="100%"
            m="100px 0"
            alignItems="center"
            justifyContent="center"
          >
            <FlexColumn w="80%" alignItems="center" justifyContent="center">
              <FlexRow h="80px" alignItems="flex-end" m="50px 0px 0px 0">
                <img src="./assets/utils/heart-with-hand.png" alt="values" />
              </FlexRow>
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    m="35px 0 20px 0"
                    size="35px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('aboutUs.cards.2.title')}
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="23px"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: wordMarker(
                          t('aboutUs.cards.2.texts.0'),
                          'Lab K19',
                        ),
                      }}
                    />
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="24px"
                  >
                    {t('aboutUs.cards.2.texts.1')}
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="24px"
                  >
                    {t('aboutUs.cards.2.texts.2')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
            <FlexColumn w="80%" alignItems="center" justifyContent="center">
              <FlexRow h="80px" alignItems="flex-end" m="100px 0px 0px 0">
                <img src="./assets/utils/vision.png" alt="vision" />
              </FlexRow>
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    m="35px 0 20px 0"
                    size="35px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('aboutUs.cards.3.title')}
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="24px"
                  >
                    {t('aboutUs.cards.3.texts.0')}
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="23px"
                  >
                    {t('aboutUs.cards.3.texts.1')}
                  </Text>
                  <Text
                    w="100%"
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.45px"
                    lineHeight="23px"
                  >
                    {t('aboutUs.cards.3.texts.2')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`}
        >
          <TeamSection />
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
};
