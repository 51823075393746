export const cardsImages = [
  './',
  './assets/mobile/mobile-0.png',
  './assets/mobile/mobile-1.png',
  './assets/mobile/mobile-2.png',
];

export const subCardsImages = [
  './assets/mobile/subCard-1.png',
  './assets/mobile/subCard-1.png',
  './assets/mobile/subCard-2.png',
];

export const texts = new Array(3).fill(' ');

export const cardImgaePath = './assets/utils/message-sign.png';

export const sliderMobileStyles = `
transform: translateX(-6%);
.slick-arrow {
    width: 80px;
    height: 100%;
    top: 200px;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 40px;
  };

  .slick-next {
    right: -15px
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
`;
