import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopAI } from './AI.desktop';
import { MobileAI } from './AI.mobile';

export const AI = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileAI />;

  return <DesktopAI />;
};
