import React, { FC, useCallback, useEffect, useState } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  links: { title: string; path: string }[];
}

export const Dropdown: FC<Props> = ({ title, links }) => {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpened(false);
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  const toggleOpenedState = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened],
  );

  return (
    <FlexColumn w="100%" alignItems="center" style={{ cursor: 'pointer' }}>
      <FlexRow
        w="90%"
        alignItems="center"
        justifyContent="space-between"
        onClick={toggleOpenedState}
      >
        <Text
          m="7px 10px"
          type={TEXT_TYPES.text}
          color={COLORS.white}
          weight={TEXT_WEIGHTS.bolder}
          textTransform="uppercase"
          textAlign="start"
        >
          {title}
        </Text>
        {!isOpened ? (
          <HiOutlineChevronDown color={COLORS.white} />
        ) : (
          <HiOutlineChevronUp color={COLORS.white} />
        )}
      </FlexRow>
      {isOpened && (
        <FlexColumn w="100%">
          {links?.map(({ title, path }) => (
            <FlexColumn
              w="calc(100% - 20px)"
              p="3px"
              onClick={() => navigate(path, { replace: true })}
            >
              <Text
                m="0 0 0 30px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                className="link-effect"
              >
                {title}
              </Text>
            </FlexColumn>
          ))}
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
