export const footerLinks = {
  company: ['/about-us', '/how-we-work'],
  'product & solutions': ['/media-platform', 'video-streaming'],
  development: [
    '/web',
    '/mobile',
    '/tv-apps',
    '/ai',
    '/data-&-analytics',
    '/software-modernization',
    '/integration',
  ],
  'management & ui': [
    '/digital-product',
    '/project-management',
    '/video-creation',
    '/ux-&-ui-design',
  ],
  'help center': [
    '/faq',
    '/term&service',
    '/privacy-policy',
    '/cookies-policy',
  ],
};

export const socialFooterLinks = [
  {
    src: './assets/socials/linkedin.png',
    link: 'https://www.linkedin.com/company/77642618/admin/',
  },
  // {
  //   src: './assets/socials/facebook.png',
  //   link: 'https://www.facebook.com/profile.php?id=100076390279991',
  // },
  {
    src: './assets/socials/instagram.png',
    link: 'https://www.instagram.com/labk19/',
  },
  {
    src: './assets/socials/youtube.png',
    link: 'https://www.youtube.com/@labk19',
  },
  // { src: './assets/socials/twitter.png', link: 'https://twitter.com/SLabk19' },
];

export const linksSets = Object.values(footerLinks);
export const linksCategories = Object.keys(footerLinks);
