export const sliderMobileStyles = `
width: 120%;
transform: translateX(-5%);

.slick-arrow {
    width: 40px;
    background: none !important;
    top: 50%;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 13%
  };

  .slick-next {
    right: 3%
  };

  .slick-slide > div > div{
    width: 90%;
    display: flex !important;
    align-items:center;
    flex-direction: column;
    text-align: center;

    * {
        text-align: center;
    }
  }

`;
