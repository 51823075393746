import { FC } from 'react';
import styled from 'styled-components';
import { StyledMobileSlider } from './styled';
import { SliderProps, sliderSettings } from './utils';

export const MobileSlider: FC<SliderProps> = ({ slides, styles }) => {
  const Slider = styles
    ? styled(StyledMobileSlider)`
        ${styles}
      `
    : StyledMobileSlider;
  return <Slider {...sliderSettings}>{slides}</Slider>;
};
