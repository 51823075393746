import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopMobile } from './Mobile.desktop';
import { MobileMobile } from './Mobile.mobile';

export const Mobile = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileMobile />;

  return <DesktopMobile />;
};
