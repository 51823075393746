import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  AgileApproachSection,
  AgileNumbers,
} from 'modules/sections/components/AgileApproachSection';
import { TechnologyWeUseSection } from 'modules/sections/components/TechnologyWeUseSection';
import { cards } from 'modules/sections/components/WorkSistematicallySection/utils';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  FlotableElement,
  Square,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';

export const MobileHowWeWork = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('howWeWork.title')} />
      <FlexColumn
        w="100%"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
        position="relative"
      >
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn w="90%" alignItems="center">
            {/* <FlexRow w="100%" alignItems="center" justifyContent="center">
              <FlexColumn w="100%" h="80%" alignItems="center">
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    m="35px 0 35px 0"
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    lineHeight="35px"
                    letterSpacing="1.4px"
                    textAlign="center"
                  >
                    {t('howWeWork.organizeYourWorkSection.title')}
                  </Text>
                  <Text
                    size="28px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                    lineHeight="35px"
                    letterSpacing="0.75px"
                  >
                    {t('howWeWork.organizeYourWorkSection.text')}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            </FlexRow> */}
            <FlexColumn
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
              m="150px 0"
            >
              <FlexColumn w="80%" h="80%" alignItems="center">
                <img src="./assets/utils/folder-sign.png" alt="folders" />
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="35px 0 70px 0"
                      size="35px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                    >
                      {t('howWeWork.workSistematicalySection.title')}
                    </Text>
                  </FlexColumn>
                </Animatable>
                <FlexRow justifyContent="center" flexWrap="wrap" gap="40px">
                  {cards.map((_, i) => (
                    <FlexColumn w="100%" alignItems="center">
                      <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                        <FlexColumn w="100%" alignItems="center">
                          <Text
                            m="15px 0"
                            size="30px"
                            type={TEXT_TYPES.title}
                            color={COLORS.lightBlue}
                            weight={TEXT_WEIGHTS.bolder}
                            textAlign="center"
                          >
                            {t(
                              `howWeWork.workSistematicalySection.cards.${i}.title`,
                            )}
                          </Text>
                          <Text
                            m="20px 0"
                            size={TEXT_SIZES.s}
                            color={COLORS.white}
                            weight={TEXT_WEIGHTS.tiny}
                            textAlign="center"
                            letterSpacing="0.56px"
                            lineHeight="23px"
                          >
                            {t(
                              `howWeWork.workSistematicalySection.cards.${i}.text`,
                            )}
                          </Text>
                        </FlexColumn>
                      </Animatable>
                    </FlexColumn>
                  ))}
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
            <AgileApproachSection />
          </FlexColumn>
          <AgileNumbers />
          <FlexColumn
            w="100%"
            p="50px 0"
            alignItems="center"
            justifyContent="center"
            bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`}
          >
            <TechnologyWeUseSection />
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
      <FlotableElement left="-20%" top="325%">
        <Square zIndex="1" size="30vw" />
      </FlotableElement>
    </FlexColumn>
  );
};
