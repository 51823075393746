import { COLORS, FlexColumn } from 'modules/ui/components';
import styled from 'styled-components';
import Calendar from 'react-calendar';

export const StyledFormWrapper = styled(FlexColumn)`
  width: 420px;
  height: 420px;
  border-radius: 60px;
  background: #88b0b6;
  margin: 30px;
  padding: 30px;
  align-items: center;
  position: relative;
  z-index: 2;

  form {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;

export const StyledMobileWrapper = styled(StyledFormWrapper)`
  width: 100vw;
  height: auto;
  background: #88b0b6;
  margin: 30px 0;
  padding: 30px;
  align-items: center;
  align-self: center;
  position: relative;
  z-index: 2;
  border-radius: 0px;
`;

export const StyledCalendar = styled(Calendar)<{ h?: string }>`
  width: 100%;
  height: ${({ h }) => h || '330px'};
  border-radius: 22px;
  padding: 5px 40px;

  .react-calendar__navigation {
    display: none;
  }

  .react-calendar__tile {
    width: 44px;
    aspect-ratio: 1 / 1;
  }

  .react-calendar__tile--active {
    background: ${COLORS.yellow};
    border-radius: 50%;

    abbr {
      color: black;
      font-weight: 1000;
    }
  }
  .react-calendar__tile:enabled:hover {
    border-radius: 50%;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${COLORS.yellow};
  }

  .react-calendar__month-view__weekdays__weekday {
    margin-bottom: auto;
    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day {
    abbr {
      color: black;
      font-size: 19px;
    }
  }

  .react-calendar__tile--now {
    background: none;
  }
`;
