import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  TEXT_SIZES,
  FlexRow,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { lists, textCards } from './utils';

export const MobileWeb = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('web.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="35px 0 26px 0"
              size="36px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('web.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="28px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="40px"
            >
              {t('web.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="50px 0 20px 0">
            <img
              src="./assets/utils/rocket-sign.png"
              alt="story"
              width={153}
              height={111}
            />
          </FlexRow>
          <FlexColumn
            w="100%"
            m="120px 0 0 0"
            justifyContent="center"
            alignItems="center"
          >
            <FlexRow m="10px">
              <img
                src="./assets/utils/website-sign.png"
                alt="story"
                width={207}
                height={127}
              />
            </FlexRow>
            <FlexColumn w="100%" alignItems="center">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    m="35px 0 0 0"
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    letterSpacing="1.4px"
                    lineHeight="32px"
                    textAlign="center"
                  >
                    {t('web.lists.0.title')}
                  </Text>
                  <Text
                    m="20px 0"
                    size={TEXT_SIZES.s}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    textAlign="center"
                  >
                    {t('web.lists.0.text')}
                  </Text>
                </FlexColumn>
              </Animatable>
              <FlexColumn w="100%" m="30px 0">
                {lists[0].map((_, i) => (
                  <FlexRow w="100%" alignItems="center" m="10px 0">
                    <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                      <FlexRow w="100%">
                        <img src="./assets/utils/check-sign.png" alt="check" />
                        <Text
                          m="0 0 0 10px"
                          size={TEXT_SIZES.s}
                          color={COLORS.white}
                          weight={TEXT_WEIGHTS.tiny}
                          whiteSpace="pre-wrap"
                        >
                          {t(`web.lists.0.points.${i}`)}
                        </Text>
                      </FlexRow>
                    </Animatable>
                  </FlexRow>
                ))}
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn w="100%" m="50px 0" alignItems="center">
            <FlexColumn w="100%" alignItems="center">
              <FlexRow m="10px">
                <img
                  src="./assets/utils/development-sign.png"
                  alt="story"
                  width={270}
                  height={114}
                />
              </FlexRow>
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%">
                  <Text
                    m="35px 0 0 0"
                    size="30px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    letterSpacing="1.4px"
                    lineHeight="32px"
                    textAlign="center"
                  >
                    {t('web.lists.1.title')}
                  </Text>
                  <Text
                    m="20px 0"
                    size="20px"
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    textAlign="center"
                  >
                    {t('web.lists.1.text')}
                  </Text>
                </FlexColumn>
              </Animatable>
              <FlexColumn w="100%">
                {lists[0].map((_, i) => (
                  <FlexRow w="100%" alignItems="center" m="10px 0">
                    <img src="./assets/utils/check-sign.png" alt="check" />
                    <Text
                      m="0 0 0 10px"
                      size={TEXT_SIZES.s}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      whiteSpace="pre-wrap"
                    >
                      {t(`web.lists.1.points.${i}`)}
                    </Text>
                  </FlexRow>
                ))}
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <FlexColumn
            w="100%"
            h="450px"
            bg="rgba(138, 245, 245, 0.2)"
            alignItems="center"
            justifyContent="center"
          >
            <FlexRow m="30px">
              <img
                src="./assets/utils/hands-shake-sign.png"
                alt="story"
                width={121}
                height={80}
              />
            </FlexRow>
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="0"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                  letterSpacing="0.56px"
                >
                  {t('web.cards.1.title')}
                </Text>
                <Text
                  m="-5px 0 0 0"
                  size="20px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                  letterSpacing="0.56px"
                >
                  {t('web.cards.1.subTitle')}
                </Text>
                <Text
                  m="20px 0"
                  size="25px"
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                  letterSpacing="0.88px"
                  lineHeight="30px"
                >
                  {t('web.cards.1.text')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexRow
            w="80%"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            {textCards.map((_, i) => (
              <FlexColumn w="100%" h="200px" alignItems="center" m="20px 0">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      w="100%"
                      m="15px 0"
                      size={TEXT_SIZES.xl}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                      letterSpacing="1.2px"
                      lineHeight="32px"
                    >
                      {t(`web.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="100%"
                      m="15px 0"
                      size={TEXT_SIZES.s}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="25px"
                    >
                      {t(`web.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <GetInTouchSection />
        </FlexColumn>
        <FlotableElement left="87%" top="15%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
