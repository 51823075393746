export const cards = new Array(4).fill(' ');
export const subCards = new Array(3).fill(' ');
export const points = new Array(9).fill(' ');

export const sliderMobileStyles = `
width: 100%;
transform: translateX(-20px);

.slick-arrow {
    width: 40px;
    background: none !important;
    height: 100%;
    top: 300px;
  };

  .slide {
    transform: translateX(-150px);
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: -18px;
  };

  .slick-next {
    right: -48px
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .slick-slide {
    transform: translateX(-10px);
  }
`;

export const sliderTabsMobileStyles = `
width: 100%;
transform: translateX(-20px);

.slick-arrow {
    width: 40px;
    background: none !important;
    height: 100%;
    top: 140px;
  };

  .slide {
    transform: translateX(-150px);
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 20px;
  };

  .slick-next {
    right: -20px
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .slick-slide {
    transform: translateX(-10px);
  }
`;
