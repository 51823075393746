import {
  CompanySection,
  MainSection,
  Partners,
} from 'modules/sections/components';
import { GetInTouchSection } from 'modules/sections/components/GetInTouchSection/GetInTouchSection';
import { HowWeDoSection } from 'modules/sections/components/HowWeDoSection/HowWeDoSection';
import { TopServices } from 'modules/sections/components/TopServices/TopServices';
import { WhatWeDoSection } from 'modules/sections/components/WhatWeDoSection/WhatWeDoSection';
import { FlotableElement, Square, COLORS } from 'modules/ui/components';
import { AnimatedBackground } from 'modules/ui/components/AnimatedBackground/AnimatedBackground';
import { FlexColumn } from 'modules/ui/components/Flex';

export const DesktopLanding = () => {
  return (
    <FlexColumn w="100%" h="100%" bg={COLORS.darkBlue}>
      <AnimatedBackground w="100%" h="200vh">
        <FlexColumn w="100%" h="100%" bgSize="cover">
          <MainSection />
          <Partners />
          <CompanySection />
        </FlexColumn>
      </AnimatedBackground>
      <TopServices />
      <FlexColumn w="100%" position="relative">
        <WhatWeDoSection />
        <HowWeDoSection />
        <FlotableElement left="95%" top="7%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-25%" top="25%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-25%" top="70%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
      <GetInTouchSection />
    </FlexColumn>
  );
};
