export const cardsImages = [
  './assets/video-creation/card-0.png',
  './assets/video-creation/card-1.png',
  './assets/video-creation/card-2.png',
  './assets/video-creation/card-3.png',
];

export const mobileCardsImagesSizes = [
  { w: 111, h: 141 },
  { w: 169, h: 175 },
  { w: 198, h: 85 },
];

export const subCardsImages = [
  './assets/video-creation/subCard-0.png',
  './assets/video-creation/subCard-1.png',
  './assets/video-creation/subCard-2.png',
];

export const texts = new Array(1).fill(' ');

export const cardImgaePath = './assets/utils/message-sign.png';

export const sliderMobileStyles = `
width: 120%;
transform: translateX(-10%);

.slick-arrow {
    width: 40px;
    background: none !important;
    top: 250px;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 90px;
  };

  .slick-next {
    right: -10px
  };

  .slick-slide > div > div{
    width: 90%;
    display: flex !important;
    align-items:center;
    flex-direction: column;
    text-align: center;
  }

`;
