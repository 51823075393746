import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  TEXT_SIZES,
  TEXT_WEIGHTS,
  Text,
  TEXT_TYPES,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { technologies } from './utils';

export const TechnologyWeUseSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  return (
    <Section h="auto">
      <FlexColumn
        w="100%"
        h="100%"
        m="50px 0"
        alignItems="center"
        justifyContent="center"
      >
        <FlexColumn w="80%" alignItems="center">
          <img src="./assets/utils/cloud-sign.png" alt="technologies" />
          <Text
            m="50px 0 105px 0"
            size="35px"
            type={TEXT_TYPES.title}
            color={COLORS.lightBlue}
            weight={TEXT_WEIGHTS.bolder}
            textAlign="center"
          >
            {t('howWeWork.technologyWeUseSection.title')}
          </Text>
          <FlexRow
            w="130%"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            gap="20px"
          >
            {technologies.map(({ title, items, imgSrc }) => (
              <FlexColumn alignItems="center" w="15%" m="20px 40px" h="350px">
                <img src={imgSrc} alt={title} />
                <FlexRow
                  m="20px 0"
                  w="auto"
                  p="5px"
                  bg={!isMobile ? COLORS.lightBlue : 'none'}
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    borderRadius: !isMobile ? '20px' : '',
                    minWidth: '100px',
                  }}
                >
                  <Text
                    m="5px 0"
                    size={TEXT_SIZES.l}
                    color={!isMobile ? COLORS.darkBlue : COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bold}
                    textAlign="center"
                  >
                    {title}
                  </Text>
                </FlexRow>
                {items.map((item) => (
                  <Text
                    m="1px 0"
                    size={TEXT_SIZES.s}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                  >
                    {item}
                  </Text>
                ))}
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
