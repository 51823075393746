import styled from 'styled-components';
import { FlexColumn } from './Flex';

interface Props {
  top: string;
  left: string;
}

export const FlotableElement = styled(FlexColumn)<Props>`
  position: absolute;
  top: ${({ top }) => top || '0px'};
  left: ${({ left }) => left || '0px'};
`;
