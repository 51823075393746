import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopIntegration } from './Integration.desktop';
import { MobileIntegration } from './Integration.mobile';

export const Integration = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileIntegration />;

  return <DesktopIntegration />;
};
