import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import { Tab } from 'modules/sections/components/TabsSection/styled';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  TEXT_SIZES,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  subCard,
  tabs,
  sliderMobileStyles,
  tabsSliderMobileStyle,
} from './utils';

export const MobileTV = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('tv.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('tv.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('tv.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img
              src="./assets/tv/card-0.png"
              alt="story"
              width={159}
              height={141}
            />
          </FlexRow>
          <FlexColumn
            w="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            bg={COLORS.darkBlue}
          >
            <MobileSlider
              styles={sliderMobileStyles}
              slides={subCard.map((_, i) => (
                <FlexColumn w="100%" alignItems="center" m="0 0 60px 0">
                  <FlexColumn
                    w="100%"
                    h="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`tv.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="100%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`tv.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </FlexColumn>
              ))}
            />
          </FlexColumn>
          <FlexColumn
            w="100%"
            m="100px 0 0 0"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
          >
            <img
              src="./assets/tv/card-1.png"
              alt="story"
              width={223}
              height={158}
            />
            <FlexColumn w="100%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%">
                  <Text
                    m="20px 0"
                    size="30px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`tv.cards.1.title`)}
                  </Text>
                  <Text
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="23px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`tv.cards.1.text`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn m="50px 0" w="100%" alignItems="center">
            <Text
              m="20px 0"
              size="30px"
              letterSpacing="1.4px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`tv.cards.2.title`)}
            </Text>
            <Text
              w="100%"
              m="15px 0"
              size={TEXT_SIZES.s}
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              letterSpacing="0.56px"
              lineHeight="23px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`tv.cards.2.text`)}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <MobileSlider
            styles={tabsSliderMobileStyle}
            slides={tabs.map((_, i) => (
              <FlexColumn
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tab p="10px" w="100%" m="0px">
                  <Text
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.120px"
                    lineHeight="32px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`tv.tabs.${i}.title`)}
                  </Text>
                </Tab>
                <img
                  src={`./assets/tv/subCard-${i}.png`}
                  alt="sub-card"
                  className="img"
                />
                <FlexColumn
                  w="100"
                  h="auto"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    m="20px 0"
                    size="30px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`tv.tabs.${i}.subTitle`)}
                  </Text>
                  <Text
                    w="100%"
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`tv.subCards.${i}.text`)}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            ))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="15%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
