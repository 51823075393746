import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { sliderMobileStyles, subCards } from './utils';

export const MobileVideoStreaming = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('videoStreaming.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="35px 0 26px 0"
              size="36px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              lineHeight="32px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t('videoStreaming.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="28px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              lineHeight="40px"
            >
              {t('videoStreaming.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="50px 0">
            <img
              src="./assets/video-streaming/card-0.png"
              alt="platform"
              width={198}
              height={168}
            />
          </FlexRow>
          <FlexColumn w="100%" alignItems="center" justifyContent="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  size="30px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                >
                  {t('videoStreaming.cards.1.title')}
                </Text>
                <Text
                  w="100%"
                  size="20px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  letterSpacing="0.56px"
                  lineHeight="23px"
                  textAlign="center"
                >
                  {t('videoStreaming.cards.1.text')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexColumn w="100%" m="60px 0 80px 0" alignItems="center">
            <img src="./assets/video-streaming/card-1.png" alt="platform" />
          </FlexColumn>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                m="80px 0 0 0"
                textAlign="center"
              >
                {t('videoStreaming.subTitle')}
              </Text>
            </FlexColumn>
          </Animatable>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="0 0 50px 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <MobileSlider
            styles={sliderMobileStyles}
            slides={subCards.map((_, i) => (
              <FlexColumn
                w="100%"
                alignItems="center"
                justifyContent="flex-start"
                m="25px 25px"
              >
                <FlexColumn w="100%" alignItems="center">
                  <FlexColumn
                    m="50px 0 20px 0"
                    h="100px"
                    justifyContent="flex-end"
                  >
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`videoStreaming.subCards.${i}.title`)}
                    </Text>
                  </FlexColumn>
                  <Text
                    w="80%"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.56px"
                    lineHeight="30px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`videoStreaming.subCards.${i}.text`)}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            ))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="20%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
