import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Button,
  BUTTON_TYPES,
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  Text,
  TEXT_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileCard } from './styled';
import { images } from './utils';

interface Props {
  title?: string;
}

export const MobileGetInTouchSection: FC<Props> = ({ title }) => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();

  return (
    <Section h="auto">
      <FlexRow
        w="100%"
        h="100%"
        p={title ? '20px 0 100px 0' : '100px 0'}
        justifyContent="center"
        alignItems="center"
        bg={COLORS.lightBlue}
      >
        <FlexColumn w="100%" alignItems="center">
          {title && (
            <Text
              m="45px 0"
              textAlign="center"
              size="35px"
              weight={TEXT_WEIGHTS.bolder}
              color={COLORS.darkBlue}
              letterSpacing="1.5px"
            >
              {title}
            </Text>
          )}
          <FlexColumn
            w="80%"
            h="80%"
            justifyContent="center"
            alignItems="center"
          >
            {images.map((src, i) => (
              <MobileCard
                key={`card-${i}`}
                bg={COLORS.darkBlue}
                m="0 40px"
                p="20px 20px"
              >
                <img src={src} alt={`card-${i}`} />
                <Text
                  m="5px 0"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  size="40px"
                  letterSpacing="1.2px"
                  lineHeight="50px"
                  weight={TEXT_WEIGHTS.tiny}
                  textAlign="center"
                >
                  {t(`landing.getInTouchSection.cards.${i}.title`)}
                </Text>
                <FlexColumn w="90%" h="100px" m="20px" alignItems="center">
                  <Text
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    size="17px"
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                  >
                    {t(`landing.getInTouchSection.cards.${i}.text`)}
                  </Text>
                </FlexColumn>
                <Button
                  buttonType={BUTTON_TYPES.filled}
                  onClick={() => navigate('/contact')}
                >
                  <Text m="20px">
                    {t(`landing.getInTouchSection.cards.${i}.button`)}
                  </Text>
                </Button>
              </MobileCard>
            ))}
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
    </Section>
  );
};
