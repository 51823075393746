import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { points, subCards } from './utils';

export const DesktopDesign = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('design.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('design.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('design.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/design/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow m="120px 0" w="100%" justifyContent="center" flexWrap="wrap">
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '30%' : '100%'}
                alignItems="center"
                m="60px auto"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <img
                      src={`./assets/design/subCard-${i}.png`}
                      alt="sub-card"
                    />
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`design.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`design.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="100px 0"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/design/card-1.png" alt="story" />
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="auto">
                <Text
                  m="20px 0"
                  size="35px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                >
                  {t(`design.cards.1.title`)}
                </Text>
                <Text
                  m="15px 0"
                  size="22px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.56px"
                  lineHeight="25px"
                  whiteSpace="pre-wrap"
                >
                  {t(`design.cards.1.text`)}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          bg="rgba(138, 245, 245, 0.1)"
          p="110px 0 80px 0"
          m="0 0 100px 0"
        >
          <img src="./assets/design/card-2.png" alt="story" />
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="20px 0"
              size="35px"
              letterSpacing="1.4px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`design.cards.2.title`)}
            </Text>
            <Text
              m="15px 0"
              w="70%"
              size="22px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              letterSpacing="0.56px"
              lineHeight="25px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`design.cards.2.text`)}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn w="80%" alignItems="center">
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="20px 0"
                size="35px"
                letterSpacing="1.4px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                whiteSpace="pre-wrap"
                textAlign="center"
              >
                {t(`design.cards.3.title`)}
              </Text>
              <Text
                m="15px 0"
                size="22px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                letterSpacing="0.56px"
                lineHeight="25px"
                whiteSpace="pre-wrap"
                textAlign="center"
              >
                {t(`design.cards.3.text`)}
              </Text>
            </FlexColumn>
          </Animatable>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          bg={'rgba(79, 255, 255, 0.001)'}
        >
          <FlexRow
            w="80%"
            p="120px 0 200px 0"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {points.map((_, i) => (
              <FlexColumn
                w={isTablet ? '100%' : '30%'}
                alignItems="center"
                m="35px 0"
              >
                <img src={`./assets/design/points-${i}.png`} alt="story" />
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`design.points.${i}.title`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-2%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
