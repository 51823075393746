import { useInView } from 'framer-motion';
import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  TEXT_SIZES,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useRef } from 'react';
import CountUp from 'react-countup';
import {
  subCards,
  numbers,
  sliderMobileStyles,
  sliderMobileNumbersStyles,
} from './utils';

export const MobileSoftwareModernization = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const container = useRef(null);
  const isInView = useInView(container, { once: true });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('softwareModernization.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="35px 0 26px 0"
              size="36px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              lineHeight="40px"
              textAlign="center"
            >
              {t('softwareModernization.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="28px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('softwareModernization.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img
              src="./assets/software-modernization/card-0.png"
              alt="story"
              width={127}
              height={150}
            />
          </FlexRow>
          <FlexColumn
            m="120px 0"
            w="100%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <MobileSlider
              styles={sliderMobileStyles}
              slides={subCards.map((_, i) => (
                <FlexColumn w="100%" alignItems="center">
                  <img
                    src={`./assets/software-modernization/subCard-${i}.png`}
                    alt="sub-card"
                  />
                  <Text
                    m="30px 0 10px 0"
                    size="30px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`softwareModernization.subCards.${i}.title`)}
                  </Text>
                  <Text
                    w="90%"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.56px"
                    lineHeight="30px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`softwareModernization.subCards.${i}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            />
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="80%"
          alignItems="center"
          justifyContent="center"
          m="70px 0"
        >
          <img
            src="./assets/software-modernization/card-1.png"
            alt="story"
            width={154}
            height={155}
          />
          <FlexColumn w="100%" m="20px 0 0 0">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%">
                <Text
                  m="20px 0 26px 0"
                  size="30px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t('softwareModernization.cards.1.title')}
                </Text>
                <Text
                  size="20px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  letterSpacing="0.56px"
                  lineHeight="23px"
                  textAlign="center"
                >
                  {t('softwareModernization.cards.1.text')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          p="100px"
          bg="rgba(138, 245, 245, 0.1)"
        >
          <FlexColumn w="80%" alignItems="center">
            <MobileSlider
              styles={sliderMobileNumbersStyles}
              slides={numbers.map((_, i) => (
                <FlexColumn
                  w="auto"
                  h="180px"
                  alignItems="center"
                  gap="15px"
                  hidden={isInView}
                >
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.mega}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    <CountUp
                      duration={1}
                      start={0}
                      end={
                        +t(`softwareModernization.numbers.${i}.number`).slice(
                          0,
                          2,
                        )
                      }
                    />
                    {t(`softwareModernization.numbers.${i}.number`)[2]}
                  </Text>
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.l}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i}.title`)}
                  </Text>
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.s}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            />
          </FlexColumn>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="15%">
          <Square zIndex="10" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
