import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopTV } from './TV.desktop';
import { MobileTV } from './TV.mobile';

export const TV = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileTV />;

  return <DesktopTV />;
};
