import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  TEXT_SIZES,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  subCardsImages,
  cardsImages,
  texts,
  mobileCardsImagesSizes,
  sliderMobileStyles,
} from './utils';

export const MobileVideoCreation = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  return (
    <FlexColumn w="100%" position="relative">
      <PageTitle title={t('videoCreation.title')} />
      <FlexColumn w="100%" bg={COLORS.darkBlue} position="relative">
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn
            w="100%"
            alignItems="center"
            bgSize="cover"
            bg="url(./assets/backgrounds/background-4.png)"
          >
            <FlexColumn w="80%" alignItems="center">
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="35px 0 30px 0"
                  size="36px"
                  lineHeight="32px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  textAlign="center"
                >
                  {t('videoCreation.cards.0.title')}
                </Text>
                <Text
                  w="100%"
                  size="28px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  textAlign="center"
                  lineHeight="40px"
                >
                  {t('videoCreation.cards.0.texts.0')}
                </Text>
              </FlexColumn>
              <FlexColumn w="100%" m="70px 0 130px 0" alignItems="center">
                <img
                  src="./assets/video-creation/card-0.png"
                  alt="mobile"
                  width={234}
                  height={162}
                />
              </FlexColumn>
            </FlexColumn>
            <FlexColumn
              w="100%"
              alignItems="center"
              p="20px 0"
              bg={COLORS.darkBlue}
            >
              <MobileSlider
                styles={sliderMobileStyles}
                slides={subCardsImages.map((path, i) => (
                  <FlexColumn
                    key={`sub-card-${i}`}
                    w="100%"
                    alignItems="center"
                  >
                    <img src={path} alt="mobile" />
                    <FlexColumn w="100%" alignItems="center">
                      <Text
                        m="30px 0 10px 0"
                        size="35px"
                        w="80%"
                        letterSpacing="1.4px"
                        type={TEXT_TYPES.title}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        textAlign="center"
                      >
                        {t(`videoCreation.subCards.${i}.title`)}
                      </Text>
                      <Text
                        w="80%"
                        size={TEXT_SIZES.s}
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        textAlign="center"
                        letterSpacing="0.56px"
                        lineHeight="30px"
                      >
                        {t(`videoCreation.subCards.${i}.text`)}
                      </Text>
                    </FlexColumn>
                  </FlexColumn>
                ))}
              />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn
            w="100%"
            alignItems="center"
            justifyContent="center"
            bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`}
          >
            <FlexColumn w="80%" alignItems="center" m="70px 0">
              {cardsImages.slice(1).map((path, i) => (
                <FlexColumn key={`card-${i}`} w="100%" alignItems="center">
                  <FlexColumn w="100%">
                    <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                      <FlexColumn w="100%" alignItems="center">
                        <FlexColumn w="100%" alignItems="center" m="20px 0">
                          <img
                            src={path}
                            alt="mobile"
                            width={mobileCardsImagesSizes[i].w}
                            height={mobileCardsImagesSizes[i].h}
                          />
                        </FlexColumn>
                        <Text
                          m="20px 0"
                          size="30px"
                          letterSpacing="1.4px"
                          type={TEXT_TYPES.title}
                          color={COLORS.lightBlue}
                          weight={TEXT_WEIGHTS.bolder}
                          whiteSpace="pre-wrap"
                          textAlign="center"
                        >
                          {t(`videoCreation.cards.${i + 1}.title`)}
                        </Text>
                        {texts.map((_, textIndex) => (
                          <Text
                            w="100%"
                            m="15px 0"
                            size="20px"
                            type={TEXT_TYPES.text}
                            color={COLORS.white}
                            weight={TEXT_WEIGHTS.tiny}
                            letterSpacing="0.56px"
                            lineHeight="23px"
                            whiteSpace="pre-wrap"
                            textAlign="center"
                          >
                            {t(
                              `videoCreation.cards.${i + 1}.texts.${textIndex}`,
                            )}
                          </Text>
                        ))}
                      </FlexColumn>
                    </Animatable>
                  </FlexColumn>
                </FlexColumn>
              ))}
            </FlexColumn>
            <GetInTouchSection />
          </FlexColumn>
        </FlexColumn>
        <FlotableElement left="87%" top="13%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
