import { FlexColumn } from 'modules/ui/components';
import styled from 'styled-components';

export const Card = styled(FlexColumn)`
  width: 560px;
  height: 500px;
  border-radius: 54px;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
