import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import { Tab } from 'modules/sections/components/TabsSection/styled';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  sliderMobileStyles,
  sliderTabsMobileStyles,
  subCards,
  tabs,
} from './utils';

export const MobileProjectManagement = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('projectManagment.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="35px 0 26px 0"
              size="36px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              lineHeight="32px"
              textAlign="center"
            >
              {t('projectManagment.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="28px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('projectManagment.cards.0.texts.0')}
            </Text>
          </FlexColumn>
          <FlexRow m="34px 0 100px 0">
            <img
              src="./assets/project-managment/card-0.png"
              alt="story"
              width={181}
              height={133}
            />
          </FlexRow>
          <FlexColumn w="100%" justifyContent="center" flexWrap="wrap">
            <MobileSlider
              styles={sliderMobileStyles}
              slides={subCards.map((_, i) => (
                <FlexColumn w="100%" alignItems="center" m="100px 20px">
                  <FlexColumn w="100%" alignItems="center">
                    <img
                      src={`./assets/project-managment/subCard-${i}.png`}
                      alt="sub-card"
                    />
                  </FlexColumn>
                  <FlexColumn m="20px 0" justifyContent="flex-end">
                    <Text
                      m="30px 0 10px 0"
                      size="35px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`projectManagment.subCards.${i}.title`)}
                    </Text>
                  </FlexColumn>
                  <Text
                    w="100%"
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.56px"
                    lineHeight="30px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`projectManagment.subCards.${i}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            />
          </FlexColumn>
          <FlexColumn m="35px 0 0 0" w="100%" h="auto" alignItems="center">
            <img
              src="./assets/project-managment/card-1.png"
              alt="story"
              width={148}
              height={126}
            />
            <FlexColumn w="auto">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    textAlign="center"
                  >
                    {t('projectManagment.cards.1.title')}
                  </Text>
                  <Text
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                    textAlign="center"
                  >
                    {t('projectManagment.cards.1.texts.0')}
                  </Text>
                  <Text
                    m="20px 0 0 0"
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                    textAlign="center"
                  >
                    {t('projectManagment.cards.1.texts.1')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn m="35px 0 0 0" w="100%" h="auto" alignItems="center">
            <FlexColumn w="100%" alignItems="center">
              <img
                src="./assets/project-managment/card-2.png"
                alt="story"
                width={155}
                height={159}
              />
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    textAlign="center"
                  >
                    {t('projectManagment.cards.2.title')}
                  </Text>
                  <Text
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                    textAlign="center"
                  >
                    {t('projectManagment.cards.2.texts.0')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
          <FlexColumn w="100%" alignItems="center">
            <FlexColumn
              alignItems="center"
              w="100%"
              m="25px 0 0 0"
              h="auto"
              p="60px 0 70px 0"
              bg="rgba(138, 245, 245, 0.1)"
            >
              <Text
                w="80%"
                m="40px 0"
                size="20px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                textAlign="center"
              >
                {t('projectManagment.cards.2.title')}
              </Text>
              <Text
                w="80%"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
                textAlign="center"
              >
                {t('projectManagment.cards.3.texts.0')}
              </Text>
              <Text
                w="80%"
                m="40px 0 0 0"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
                textAlign="center"
              >
                {t('projectManagment.cards.3.texts.1')}
              </Text>
            </FlexColumn>
          </FlexColumn>
        </Animatable>
        <FlexColumn
          w="100%"
          p="53px 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <MobileSlider
            styles={sliderTabsMobileStyles}
            slides={tabs.map((_, i) => (
              <FlexColumn w="80%" alignItems="center" gap="43px">
                <Tab
                  w="auto !important"
                  h="80px"
                  p="0px 5px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    size="28px"
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bold}
                    letterSpacing="0.78px"
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`projectManagment.tabs.${i}.title`)}
                  </Text>
                </Tab>
                <img
                  src={`./assets/project-managment/tab-${i}.png`}
                  alt="sub-card"
                  className="img"
                  style={{ marginTop: '-10px' }}
                />
                <FlexColumn w="100%">
                  <Text
                    size="28px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bold}
                    letterSpacing="0.78px"
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`projectManagment.tabs.${i}.subTitle`)}
                  </Text>
                  <Text
                    size="28px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.78px"
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`projectManagment.tabs.${i}.text`)}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            ))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="9%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="22%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
