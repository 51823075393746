import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Button,
  BUTTON_TYPES,
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { textsQuantities } from './utils';

export const WhatWeDoSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: `(max-width: ${1200}px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn
        w="100%"
        h="100%"
        p="20px 0"
        bg={COLORS.lightBlue}
        alignItems="center"
        justifyContent="center"
      >
        <FlexRow
          w="80%"
          alignItems="center"
          justifyContent="space-between"
          m="70px 0"
        >
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <Text
              m="0 0 0 30px"
              size={TEXT_SIZES.mega}
              color={COLORS.darkBlue}
              weight={TEXT_WEIGHTS.bolder}
              type={TEXT_TYPES.title}
            >
              {t('landing.whatWeDoSection.title')}
            </Text>
          </Animatable>
          <Button
            buttonType={BUTTON_TYPES.filled}
            onClick={() => navigate('/about-us')}
          >
            {t('landing.whatWeDoSection.button')}
          </Button>
        </FlexRow>
        <FlexRow
          w="80%"
          h="100%"
          m="0 0 40px 0"
          style={isTablet ? { transform: 'scale(0.8) translateX(-80px)' } : {}}
        >
          <FlexRow
            w="50%"
            h="100%"
            justifyContent="center"
            alignItems="flex-start"
          >
            <FlexRow w="90%" gap="60px">
              <FlexColumn>
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img
                    style={isTablet ? { transform: 'scale(0.7)' } : {}}
                    src="./assets/utils/dev-sign.png"
                    alt="development"
                  />
                </Animatable>
              </FlexColumn>
              <FlexColumn w="auto">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <Text
                    m="0px 0px 15px 0"
                    size="40px"
                    type={TEXT_TYPES.title}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('landing.whatWeDoSection.cards.0.title')}
                  </Text>
                  {textsQuantities[0].map((_, i) => (
                    <Text
                      size="28px"
                      color={COLORS.darkBlue}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.9px"
                      lineHeight="35px"
                    >
                      {t(`landing.whatWeDoSection.cards.0.texts.${i}`)}
                    </Text>
                  ))}
                </Animatable>
              </FlexColumn>
            </FlexRow>
          </FlexRow>
          <FlexRow
            w="50%"
            h="100%"
            m="100px 0 0 0"
            justifyContent="center"
            alignItems="flex-end"
          >
            <FlexRow w="90%" gap="60px">
              <FlexColumn>
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img
                    style={isTablet ? { transform: 'scale(0.7)' } : {}}
                    src="./assets/utils/puzzle-sign.png"
                    alt="strategy"
                  />
                </Animatable>
              </FlexColumn>
              <FlexColumn w="320px">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <Text
                    m="0px 0px 15px 0"
                    size="40px"
                    type={TEXT_TYPES.title}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('landing.whatWeDoSection.cards.1.title')}
                  </Text>
                  {textsQuantities[1].map((_, i) => (
                    <Text
                      size="28px"
                      color={COLORS.darkBlue}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.9px"
                      lineHeight="35px"
                    >
                      {t(`landing.whatWeDoSection.cards.1.texts.${i}`)}
                    </Text>
                  ))}
                </Animatable>
              </FlexColumn>
            </FlexRow>
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </Section>
  );
};
