import { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Button,
  BUTTON_TYPES,
  FlexColumn,
  FlexRow,
  Text,
  TEXT_SIZES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { FieldWrapper, StyledFormikField } from '../styled';
import { initialValues } from './utils';

const FORM_STATUS = {
  SENT: 'Message sent successfully',
  FAILED: 'Failed to send message',
};

export const GetInTouchForm = () => {
  const [messageSentStatus, setMessageSentStatus] = useState<
    keyof typeof FORM_STATUS | null
  >(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslationNameSpace(NAME_SPACES.FORMS);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  const handleSubmit = () => {
    const form = formRef.current;
    if (!form) return;

    const formData = new FormData(form);
    setMessageSentStatus(null);

    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setMessageSentStatus('SENT');
        form.reset();

        // Clear message field
        const messageElement =
          form.querySelector<HTMLTextAreaElement>('[name="message"]');
        if (!messageElement) return;

        messageElement.value = '';
      })
      .catch((error) => {
        console.error(error);
        setMessageSentStatus('FAILED');
      });
  };

  return (
    <FlexColumn w="100%" alignItems="center">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form ref={formRef}>
          <input
            type="hidden"
            name="access_key"
            value="26741f3a-f6c0-4b22-8d1c-04a3ec3bafeb"
          />
          <FlexColumn w="90%" alignItems="center">
            <FlexRow
              as={isMobile ? FlexColumn : FlexRow}
              w="100%"
              justifyContent="space-between"
            >
              <FieldWrapper w={isMobile ? '100%' : 'calc(50% - 7px)'} m="0 7px">
                <StyledFormikField
                  required
                  name="name"
                  placeholder={t('getInTouchForm.placeholders.name') + '*'}
                />
              </FieldWrapper>
              <FieldWrapper w={isMobile ? '100%' : 'calc(50% - 7px)'} m="0 7px">
                <StyledFormikField
                  name="email"
                  type="email"
                  placeholder={t('getInTouchForm.placeholders.email') + '*'}
                />
              </FieldWrapper>
            </FlexRow>
            <FieldWrapper w="100%">
              <StyledFormikField
                name="subject"
                type=""
                placeholder={t('getInTouchForm.placeholders.subject') + '*'}
              />
            </FieldWrapper>
            <FieldWrapper w="100%">
              <StyledFormikField
                as={'textarea'}
                name="message"
                placeholder={t('getInTouchForm.placeholders.message') + '*'}
              />
            </FieldWrapper>
            <FlexRow m="10px 0 0 0">
              <Button buttonType={BUTTON_TYPES.filled} type="submit">
                <Text
                  weight={TEXT_WEIGHTS.bolder}
                  size={TEXT_SIZES.xs}
                  className="button"
                >
                  {t('getInTouchForm.button')}
                </Text>
              </Button>
            </FlexRow>
          </FlexColumn>
        </Form>
      </Formik>
      {messageSentStatus && (
        <Text m="5px 0 0 0" color="white">
          {FORM_STATUS[messageSentStatus]}
        </Text>
      )}
    </FlexColumn>
  );
};
