import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FlexRow } from './Flex';
import { StyledContactLink } from './Links';

interface Props {
  to: string;
  title: string;
}

export const ContactLink: FC<Props> = ({ to, title }) => {
  const isTablet = useMediaQuery({
    query: `(max-width: 1200px)`,
  });

  return !isTablet ? (
    <FlexRow
      m="auto"
      as={motion.div}
      animate={{ scale: [1, 1, 1, 1.15, 1] }}
      transition={{ duration: 2 }}
    >
      <StyledContactLink to={to}>{title}</StyledContactLink>
    </FlexRow>
  ) : null;
};
