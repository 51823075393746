import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { TabsSection } from 'modules/sections/components/TabsSection/TabsSection';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { subCards, tabs } from './utils';

export const DesktopProjectManagement = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('projectManagment.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
            >
              {t('projectManagment.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('projectManagment.cards.0.texts.0')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/project-managment/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow m="50px 0" w="100%" justifyContent="center" flexWrap="wrap">
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '30%' : '100%'}
                alignItems="center"
                m="100px 20px"
              >
                <FlexColumn w="100%" alignItems="center" h="100px">
                  <img
                    src={`./assets/project-managment/subCard-${i}.png`}
                    alt="sub-card"
                  />
                </FlexColumn>
                <FlexColumn m="20px 0" h="100px" justifyContent="flex-end">
                  <Text
                    m="30px 0 10px 0"
                    size="30px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`projectManagment.subCards.${i}.title`)}
                  </Text>
                </FlexColumn>
                <Text
                  w="80%"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  textAlign="center"
                  letterSpacing="0.56px"
                  lineHeight="30px"
                  whiteSpace="pre-wrap"
                >
                  {t(`projectManagment.subCards.${i}.text`)}
                </Text>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="70px 0"
            w="80%"
            h="auto"
            gap="100px"
            alignItems="center"
            flexDirection={isTablet ? 'column !important' : 'row'}
            justifyContent="space-between"
          >
            <img src="./assets/project-managment/card-1.png" alt="story" />
            <FlexColumn w="auto">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    w="100%"
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t('projectManagment.cards.1.title')}
                  </Text>
                  <Text
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                  >
                    {t('projectManagment.cards.1.texts.0')}
                  </Text>
                  <Text
                    m="20px 0 0 0"
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                  >
                    {t('projectManagment.cards.1.texts.1')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexRow>
          <FlexRow
            m="70px 0 70px 0"
            w="80%"
            h="auto"
            gap="100px"
            alignItems="center"
            flexDirection={isTablet ? 'column-reverse !important' : 'row'}
            justifyContent="space-between"
          >
            <FlexColumn w="auto">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    w="100%"
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t('projectManagment.cards.2.title')}
                  </Text>
                  <Text
                    size="25px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                  >
                    {t('projectManagment.cards.2.texts.0')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
            <img src="./assets/project-managment/card-2.png" alt="story" />
          </FlexRow>
        </FlexColumn>
        <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
          <FlexColumn w="100%" alignItems="center">
            <FlexColumn
              alignItems="center"
              w="100%"
              m="140px 0"
              h="auto"
              p="60px 0 70px 0"
              bg="rgba(138, 245, 245, 0.1)"
            >
              <Text
                w="60%"
                m="40px 0"
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                textAlign="center"
              >
                {t('projectManagment.cards.2.title')}
              </Text>
              <Text
                w="70%"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
                textAlign="center"
              >
                {t('projectManagment.cards.3.texts.0')}
              </Text>
              <Text
                m="40px 0 0 0"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
              >
                {t('projectManagment.cards.3.texts.1')}
              </Text>
            </FlexColumn>
          </FlexColumn>
        </Animatable>
        <FlexColumn
          w="100%"
          p="120px 0 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <TabsSection
            tabsFontSize="28px"
            tabsHeight="70px"
            tabs={tabs.map((_, i) => ({
              title: t(`projectManagment.tabs.${i}.title`),
              content: (
                <FlexRow
                  w="80%"
                  alignItems="center"
                  gap="70px"
                  justifyContent="center"
                  flexDirection={isTablet ? 'column !important' : 'row'}
                >
                  <img
                    src={`./assets/project-managment/tab-${i}.png`}
                    alt="sub-card"
                  />
                  <FlexColumn
                    w="70%"
                    h={isTablet ? '600px' : 'auto'}
                    justifyContent={!isTablet ? 'center' : 'start'}
                  >
                    <Text
                      size="28px"
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.bold}
                      letterSpacing="0.78px"
                      lineHeight="35px"
                      whiteSpace="pre-wrap"
                      w="100%"
                      textAlign={isTablet ? 'center' : 'start'}
                    >
                      {t(`projectManagment.tabs.${i}.subTitle`)}
                    </Text>
                    <Text
                      size="28px"
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.78px"
                      lineHeight="35px"
                      whiteSpace="pre-wrap"
                      textAlign={isTablet ? 'center' : 'start'}
                    >
                      {t(`projectManagment.tabs.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </FlexRow>
              ),
            }))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-2%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
