import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopDesign } from './Design.desktop';
import { MobileDesign } from './Design.mobile';

export const Design = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileDesign />;

  return <DesktopDesign />;
};
