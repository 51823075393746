import React, { FC, ReactNode } from 'react';
import { FlexColumn } from './Flex';

interface Props {
  h?: string;
  children: ReactNode;
}

export const Section: FC<Props> = ({ h = '600px', children }) => {
  return (
    <FlexColumn as="section" w="100%" h={h} bg="transparent">
      {/* {false && (
        <FitToViewport
          className="view"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignSelf: 'center',
          }}
          as={FlexColumn}
          width={window.innerWidth}
          height={0}
          minZoom={0.75}
          maxZoom={1}
        >
          {children}
        </FitToViewport>
      )} */}
      {children}
    </FlexColumn>
  );
};
