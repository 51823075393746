import { COLORS } from 'modules/ui/components';
import styled from 'styled-components';

export const StyledMemberImage = styled.img<{ size?: string }>`
  width: ${({ size }) => size || '200px'};
  height: ${({ size }) => size || '200px'};
  border-radius: 50%;
  border: 10px solid #69ffff;
  background: ${COLORS.white};
  filter: sepia(50%);
`;
