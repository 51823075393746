import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  sliderMobileStyles,
  sliderTabsMobileStyles,
  subCards,
  tabs,
} from './utils';
import { Animatable } from 'modules/ui/components/Animatable';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import { FitToViewport } from 'react-fit-to-viewport';
import { Tab } from 'modules/sections/components/TabsSection/styled';

export const MobileMediaPlatform = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('mediaPlatform.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="50px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t('mediaPlatform.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('mediaPlatform.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/media-platform/card-0.png" alt="platform" />
          </FlexRow>
          <FlexColumn w="100%" alignItems="center" justifyContent="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  size="35px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                  textAlign="center"
                >
                  {t('mediaPlatform.cards.1.title')}
                </Text>
                <Text
                  size="22px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  letterSpacing="0.62px"
                  lineHeight="30px"
                  textAlign="center"
                >
                  {t('mediaPlatform.cards.1.text')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexRow w="100%" m="50px 0" alignItems="center">
            <MobileSlider
              styles={sliderTabsMobileStyles}
              slides={subCards.map((_, i) => (
                <FlexColumn
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FlexColumn w="100%" alignItems="center" h="100px">
                    <img
                      src={`./assets/media-platform/subCard-${i}.png`}
                      alt="sub-card"
                    />
                  </FlexColumn>
                  <FlexColumn m="20px 0" h="100px" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`mediaPlatform.subCards.${i}.title`)}
                    </Text>
                  </FlexColumn>
                  <Text
                    w="100%"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.56px"
                    lineHeight="30px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`mediaPlatform.subCards.${i}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            />
          </FlexRow>
          <FlexColumn
            w="80%"
            gap="100px"
            alignItems="center"
            justifyContent="space-between"
          >
            <img src="./assets/media-platform/card-1.png" alt="platform" />
            <FlexColumn w="100%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    textAlign="center"
                  >
                    {t('mediaPlatform.cards.1.title')}
                  </Text>
                  <Text
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.88px"
                    lineHeight="30px"
                    textAlign="center"
                  >
                    {t('mediaPlatform.cards.1.text')}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="120px 0 50px 0"
          alignItems="center"
          justifyContent="center"
        >
          <MobileSlider
            styles={sliderMobileStyles}
            slides={tabs.map((_, i) => (
              <FlexColumn
                w="100%"
                alignItems="center"
                justifyContent="center"
                gap="70px !important"
              >
                <Tab
                  p="10px"
                  w="70%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.120px"
                    lineHeight="32px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`mediaPlatform.tabs.${i}.title`)}
                  </Text>
                </Tab>
                <FitToViewport
                  className="view"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    alignSelf: 'center',
                  }}
                  as={FlexColumn}
                  width={window.innerWidth}
                  height={0}
                  minZoom={0.5}
                  maxZoom={1}
                >
                  <img
                    src={`./assets/media-platform/tab-${i}.png`}
                    alt="title"
                    className="img"
                  />
                </FitToViewport>
                <FlexColumn
                  w="80%"
                  m="0 auto"
                  h="auto"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    m="20px 0"
                    size="32px"
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.120px"
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`mediaPlatform.tabs.${i}.subTitle`)}
                  </Text>
                  <Text
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.78px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`mediaPlatform.tabs.${i}.text`)}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            ))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
      </FlexColumn>
    </FlexColumn>
  );
};
