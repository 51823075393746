import { motion } from 'framer-motion';
import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Section,
  FlexColumn,
  COLORS,
  FlexRow,
  TEXT_SIZES,
  TEXT_WEIGHTS,
  TEXT_TYPES,
  Button,
  BUTTON_TYPES,
  Text,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Video, VideoButton } from './styled';
import { textsQuantities } from './utils';

const videoAnimationDuration = 0.5;

export const HowWeDoSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const onTogglePlayVideo = () => {
    setIsVideoPlaying(!isVideoPlaying);
  };

  const isTablet = useMediaQuery({
    query: `(max-width: ${1200}px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn
        w="100%"
        h="100%"
        p="20px 0"
        bg={COLORS.darkBlue}
        alignItems="center"
        justifyContent="center"
      >
        <FlexRow
          w="80%"
          alignItems="center"
          justifyContent="space-between"
          m="70px 0"
        >
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <Text
              m="0 0 0 30px"
              size={TEXT_SIZES.mega}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              type={TEXT_TYPES.title}
            >
              {t('landing.howWeDoSection.title')}
            </Text>
          </Animatable>
          <Button
            buttonType={BUTTON_TYPES.filled}
            onClick={() => navigate('/how-we-work')}
          >
            {t('landing.howWeDoSection.button')}
          </Button>
        </FlexRow>
        <FlexRow
          w="80%"
          h="100%"
          style={isTablet ? { transform: 'scale(0.8) translateX(-80px)' } : {}}
        >
          <FlexRow
            w="50%"
            h="100%"
            justifyContent="center"
            alignItems="flex-start"
          >
            <FlexRow w="90%" gap="60px">
              <FlexColumn>
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img
                    style={isTablet ? { transform: 'scale(0.7)' } : {}}
                    src="./assets/utils/agile-sign.png"
                    alt="development"
                  />
                </Animatable>
              </FlexColumn>
              <FlexColumn w="350px">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <Text
                    m="0px 0px 15px 0"
                    size="40px"
                    whiteSpace="pre-wrap"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('landing.howWeDoSection.cards.0.title')}
                  </Text>
                  {textsQuantities[0].map((_, i) => (
                    <Text
                      size={TEXT_SIZES.xl}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.9px"
                      lineHeight="35px"
                    >
                      {t(`landing.howWeDoSection.cards.0.texts.${i}`)}
                    </Text>
                  ))}
                </Animatable>
              </FlexColumn>
            </FlexRow>
          </FlexRow>
          <FlexRow
            w="50%"
            h="100%"
            m="100px 0 0 0"
            justifyContent="center"
            alignItems="flex-end"
          >
            <FlexRow w="90%" gap="60px" m="50px 0 100px 0">
              <FlexColumn>
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img
                    style={isTablet ? { transform: 'scale(0.7)' } : {}}
                    src="./assets/utils/folder-sign.png"
                    alt="strategy"
                  />
                </Animatable>
              </FlexColumn>
              <FlexColumn w="350px">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <Text
                    m="0px 0px 15px 0"
                    size="40px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                  >
                    {t('landing.howWeDoSection.cards.1.title')}
                  </Text>
                  {textsQuantities[1].map((_, i) => (
                    <Text
                      size={TEXT_SIZES.xl}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.9px"
                      lineHeight="35px"
                    >
                      {t(`landing.howWeDoSection.cards.1.texts.${i}`)}
                    </Text>
                  ))}
                </Animatable>
              </FlexColumn>
            </FlexRow>
          </FlexRow>
        </FlexRow>
        <FlexRow w="100%" h="580px" justifyContent="center" alignItems="center">
          <FlexRow
            w="100%"
            h="95.5%"
            bg="url(./assets/backgrounds/background-1.png)"
            bgSize="cover"
            justifyContent="center"
            alignItems="center !important"
            position="relative"
          >
            <Square
              size="500px"
              m="auto"
              as={motion.div}
              animate={
                isVideoPlaying
                  ? {
                      rotate: [-45, 0],
                      width: '100vw',
                      height: '100vh',
                      position: 'fixed',
                      left: ['50%', '0%'],
                      top: ['50%', '0%'],
                      zIndex: 10,
                    }
                  : {
                      rotate: [0, 45],
                    }
              }
              transition={{ duration: videoAnimationDuration }}
            >
              {isVideoPlaying ? (
                <FlexRow
                  w="100%"
                  h="100%"
                  as={motion.div}
                  animate={{ opacity: [0, 1] }}
                  style={{ zIndex: 20 }}
                  transition={{ duration: videoAnimationDuration }}
                  onClick={onTogglePlayVideo}
                >
                  <Video
                    style={{
                      background: 'black',
                      pointerEvents: isVideoPlaying ? 'none' : 'all',
                    }}
                    src="https://www.youtube.com/embed/uVUfUOd1-fQ?autoplay=1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  />
                </FlexRow>
              ) : (
                <VideoButton
                  as={motion.button}
                  animate={{
                    rotate: [-45, 315],
                    scale: [1, 1.5, 1],
                  }}
                  transition={{
                    duration: 0.5,
                    repeat: Infinity,
                    repeatDelay: 2,
                  }}
                  whileHover={{
                    scale: 1.2,
                    transition: { duration: 0.3 },
                  }}
                  className="rotatable"
                  onClick={onTogglePlayVideo}
                >
                  <img alt="play" src="./assets/utils/video-button.png" />
                </VideoButton>
              )}
            </Square>
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </Section>
  );
};
