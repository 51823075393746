import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Button,
  BUTTON_TYPES,
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  Text,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Card } from './styled';
import { images } from './utils';

interface Props {
  title?: string;
}

export const DesktopGetInTouchSection: FC<Props> = ({ title }) => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: `(max-width: 1150px)`,
  });

  return (
    <Section h="auto">
      <FlexRow
        w="100%"
        h="100%"
        p={title ? '50px 0 100px 0' : '100px 0'}
        justifyContent="center"
        alignItems="center"
        bg={COLORS.lightBlue}
      >
        <FlexColumn w="100%" alignItems="center">
          {title && (
            <Text
              m="45px 0"
              textAlign="center"
              size="50px"
              weight={TEXT_WEIGHTS.bolder}
              color={COLORS.darkBlue}
              letterSpacing="1.5px"
            >
              {title}
            </Text>
          )}
          <FlexRow
            w="80%"
            h="80%"
            justifyContent="center"
            alignItems="center"
            gap="20px"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            {images.map((src, i) => (
              <FlexColumn alignItems="center">
                <Animatable
                  animationType={
                    i > 0
                      ? ANIMATIONS_TYPES.slideRight
                      : ANIMATIONS_TYPES.slideLeft
                  }
                >
                  <Card
                    w="100%"
                    key={`card-${i}`}
                    bg={COLORS.darkBlue}
                    m="0 40px"
                    p="20px 20px"
                  >
                    <img src={src} alt={`card-${i}`} />
                    <Text
                      m="5px 0"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      size={TEXT_SIZES.xxl}
                      weight={TEXT_WEIGHTS.tiny}
                    >
                      {t(`landing.getInTouchSection.cards.${i}.title`)}
                    </Text>
                    <FlexColumn w="90%" h="100px" m="20px" alignItems="center">
                      <Text
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        size={TEXT_SIZES.s}
                        weight={TEXT_WEIGHTS.tiny}
                        textAlign="center"
                        whiteSpace="pre"
                      >
                        {t(`landing.getInTouchSection.cards.${i}.text`)}
                      </Text>
                    </FlexColumn>
                    <Button
                      buttonType={BUTTON_TYPES.filled}
                      onClick={() => navigate('/contact')}
                    >
                      <Text m="20px">
                        {t(`landing.getInTouchSection.cards.${i}.button`)}
                      </Text>
                    </Button>
                  </Card>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Section>
  );
};
