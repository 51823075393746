import ISO639 from 'iso-639-1';

import { Language } from '../types';

export const languages = Object.freeze<Language[]>([
  {
    displayName: ISO639.getNativeName('it'),
    languageCode: ISO639.getCode('italian'),
    iconSrc: './assets/language-icons/it.svg',
  },
  {
    displayName: ISO639.getNativeName('de'),
    languageCode: ISO639.getCode('german'),
    iconSrc: './assets/language-icons/de.svg',
  },
  {
    displayName: ISO639.getNativeName('fr'),
    languageCode: ISO639.getCode('french'),
    iconSrc: './assets/language-icons/fr.svg',
  },
  {
    displayName: ISO639.getNativeName('en'),
    languageCode: ISO639.getCode('english'),
    iconSrc: './assets/language-icons/en.svg',
  },
]);
