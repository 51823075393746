import Slider from 'react-slick';
import styled from 'styled-components';
import { COLORS } from 'modules/ui/components';

export const StyledSlider = styled(Slider)`
  width: 90%;

  .slick-list {
    height: 100%;
    width: 100%;
  }

  .slick-list > div {
    width: 100%;
  }

  .slick-track {
    height: 100%;
    padding: 0;
  }

  .slick-slide::before {
    width: 100%;
  }

  .slick-slide {
    width: 100%;
  }

  .slick-slide > div {
    width: 100%;
  }

  .slick-arrow {
    width: 10%;
    height: 110%;
    background: ${COLORS.darkBlue};
    color: #9a9a9a;
    content: '';
  }

  .slick-arrow::before {
    content: '';
  }

  .slick-arrow > svg {
    width: 20px;
    right: 25px;
    height: 34px;
    color: #9a9a9a;
  }

  .slick-next {
    right: -13%;
  }

  .slick-prev {
    left: -5%;
    z-index: 5;
  }

  .slide-wrapper {
    display: flex !important;
    height: 100%;
  }
`;

export const StyledMobileSlider = styled(StyledSlider)`
  width: 120%;
  transform: translateX(-13%);

  .slick-arrow {
    width: 40px;
    background: none !important;
    top: 250px;
  }

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-next {
    right: -3%;
    z-index: 5;
  }

  .slick-prev {
    left: 7%;
    z-index: 5;
  }

  .slick-slide > div {
    width: 100%;
  }

  .slick-slide {
    padding: 0 30px 10px 30px;
    box-sizing: border-box;
  }
`;
