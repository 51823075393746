import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { COLORS, FlexColumn, FlexRow } from 'modules/ui/components';
import { useCallback, useEffect, useState } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from './Dropdown';
import { headerLinks } from './utils';

export const MobileHeader = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.COMPONENTS);
  const [isBurgerExpanded, setIsBurgerExpanded] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsBurgerExpanded(false);
  }, [location]);

  const onToggleExpandState = useCallback(
    () => setIsBurgerExpanded(!isBurgerExpanded),
    [isBurgerExpanded],
  );

  return (
    <FlexColumn w="100%" zIndex="5">
      <FlexRow
        p="17px"
        w="calc(100% - 34px)"
        justifyContent="space-between"
        alignItems="center"
        bg={COLORS.darkBlue}
      >
        <RxHamburgerMenu
          color={COLORS.white}
          onClick={onToggleExpandState}
          size={25}
          cursor="pointer"
        />
        <img
          src="./assets/utils/logo-small.png"
          alt="logo"
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        <img
          src="./assets/utils/contact.png"
          alt="contact"
          onClick={() => navigate('/contact')}
          style={{ cursor: 'pointer' }}
        />
      </FlexRow>

      {isBurgerExpanded && (
        <FlexColumn w="100%" bg={COLORS.darkBlue}>
          {headerLinks
            .slice(0, headerLinks.length - 1)
            .map(({ path, title, subLinks }, i) => (
              <Dropdown
                key={path}
                title={t(`header.links.${title}`)}
                links={subLinks!}
              />
            ))}
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
