import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import {
  FlexColumn,
  Section,
  TEXT_SIZES,
  Text,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { useMediaQuery } from 'react-responsive';
import { cards } from './utils';

export const WorkSistematicallySection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${800}px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        m="150px 0"
      >
        <FlexColumn w="80%" h="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <img src="./assets/utils/folder-sign.png" alt="folders" />
          </FlexColumn>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0 70px 0"
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
              >
                {t('howWeWork.workSistematicalySection.title')}
              </Text>
            </FlexColumn>
          </Animatable>
          <FlexRow justifyContent="center" flexWrap="wrap" gap="40px">
            {cards.map((_, i) => (
              <FlexColumn w={isTablet ? '80%' : '30%'} alignItems="center">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="15px 0"
                      size="30px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                    >
                      {t(`howWeWork.workSistematicalySection.cards.${i}.title`)}
                    </Text>
                    <Text
                      m="20px 0"
                      size={TEXT_SIZES.s}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="23px"
                    >
                      {t(`howWeWork.workSistematicalySection.cards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
