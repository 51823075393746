import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from './Flex';

export const StyledHeaderLink = styled(Link)<LinkProps>`
  font: normal normal bold 15px/32px Source Sans Pro;
  color: ${COLORS.white};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  outline: none;

  &:hover {
    color: ${COLORS.lightBlue};
  }
`;

export const StyledContactLink = styled(StyledHeaderLink)`
  background: ${COLORS.white};
  border-radius: 10px;
  color: ${COLORS.darkBlue};
  width: 120px;
  height: 37px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: url('./assets/utils/corner.svg');
    position: absolute;
    left: 10%;
    top: 80%;
  }
`;
