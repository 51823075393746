import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  Section,
  TEXT_SIZES,
  TEXT_WEIGHTS,
  Text,
  FlexColumn,
  COLORS,
  FlexRow,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { StyledOfficeWrapper } from './styled';
import { offices } from './utils';

export const OurOficesSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  return (
    <Section h="auto">
      <FlexColumn w="100%" h="100%" alignItems="center" justifyContent="center">
        <FlexColumn
          w="100%"
          h="100%"
          p="0 0 80px 0"
          alignItems="center"
          justifyContent="center"
          bg={
            !isMobile
              ? `linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`
              : 'transparent'
          }
        >
          <FlexColumn w="80%" h="80%" alignItems="center" p="30px 0">
            <img
              width={130}
              height={120}
              src="./assets/utils/office-sign.png"
              alt="office"
            />
            <Text
              m="30px 0"
              size={TEXT_SIZES.xl}
              weight={TEXT_WEIGHTS.bolder}
              color={COLORS.lightBlue}
              animatable
            >
              {t(`contact.ourOficesSection.title`)}
            </Text>
            {offices.map(({ phone, email, address }, i) => (
              <StyledOfficeWrapper w="100%" p="25px">
                <FlexRow w="100%">
                  <Text
                    m={isMobile ? '10px 0' : '15px 0px'}
                    size={TEXT_SIZES.xxl}
                    weight={TEXT_WEIGHTS.tiny}
                    color={COLORS.lightBlue}
                    animatable
                  >
                    {t(`contact.ourOficesSection.offices.${i}.city`)}
                  </Text>
                </FlexRow>
                <FlexRow w="100%" justifyContent="space-between">
                  {isMobile}
                  <FlexRow
                    as={FlexColumn}
                    w={isMobile ? '100%' : '45%'}
                    justifyContent="space-between"
                  >
                    <FlexColumn>
                      {i !== 2 && (
                        <Text
                          size={TEXT_SIZES.s}
                          weight={TEXT_WEIGHTS.bolder}
                          color={COLORS.white}
                          lineHeight="23px"
                          letterSpacing="0.56px"
                          animatable
                        >
                          {t(`contact.ourOficesSection.adress`)}
                        </Text>
                      )}
                      <Text
                        size={TEXT_SIZES.s}
                        weight={TEXT_WEIGHTS.tiny}
                        color={COLORS.white}
                        lineHeight="23px"
                        letterSpacing="0.56px"
                        animatable
                      >
                        {t(`contact.ourOficesSection.offices.${i}.adress.0`)}
                      </Text>
                      <Text
                        size={TEXT_SIZES.s}
                        weight={TEXT_WEIGHTS.tiny}
                        color={COLORS.white}
                        lineHeight="23px"
                        letterSpacing="0.56px"
                        animatable
                      >
                        {t(`contact.ourOficesSection.offices.${i}.adress.1`)}
                      </Text>
                    </FlexColumn>
                    {i !== 2 && (
                      <FlexColumn>
                        <Text
                          size={TEXT_SIZES.s}
                          weight={TEXT_WEIGHTS.bolder}
                          color={COLORS.white}
                          lineHeight="23px"
                          letterSpacing="0.56px"
                          animatable
                        >
                          {t(`contact.ourOficesSection.contact`)}
                        </Text>
                        <Text
                          size={TEXT_SIZES.s}
                          weight={TEXT_WEIGHTS.tiny}
                          color={COLORS.white}
                          lineHeight="23px"
                          letterSpacing="0.56px"
                          animatable
                        >
                          {phone}
                        </Text>
                        <Text
                          size={TEXT_SIZES.s}
                          weight={TEXT_WEIGHTS.tiny}
                          color={COLORS.white}
                          lineHeight="23px"
                          letterSpacing="0.56px"
                          animatable
                        >
                          {email}
                        </Text>
                      </FlexColumn>
                    )}
                  </FlexRow>
                  {i !== 2 && (
                    <FlexColumn
                      alignItems="center"
                      onClick={() =>
                        (window.location.href = address! as string)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        width={30}
                        height={37}
                        alt="map"
                        src="./assets/utils/map-sign.png"
                      />
                      <Text
                        m="10px 0"
                        size={TEXT_SIZES.s}
                        weight={TEXT_WEIGHTS.bold}
                        color={COLORS.lightBlue}
                        lineHeight="23px"
                        letterSpacing="0.56px"
                        textAlign="center"
                        animatable
                      >
                        {t(`contact.ourOficesSection.seeMap`)}
                      </Text>
                    </FlexColumn>
                  )}
                </FlexRow>
              </StyledOfficeWrapper>
            ))}
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
