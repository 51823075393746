import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { Tab } from 'modules/sections/components/TabsSection/styled';
import {
  FlexColumn,
  COLORS,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  FlexRow,
  Text,
} from 'modules/ui/components';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { QuestionTitle } from './styled';
import { tabs } from './utils';

export const MobileFAQ = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('faq.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn
          w="100%"
          p="70px 0 140px 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <FlexColumn w="100%" alignItems="center">
            {tabs.map((questions, i) => (
              <FlexColumn
                w="80%"
                alignItems="center"
                gap="20px"
                justifyContent="center"
              >
                <Tab w="100%" bg={COLORS.lightBlue + '!important'} p="20px">
                  <Text
                    size="32px"
                    type={TEXT_TYPES.text}
                    color={COLORS.darkBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`faq.tabs.${i}.title`)}
                  </Text>
                </Tab>
                {questions.map((_, questionIndex) => (
                  <FlexColumn
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    m="30px  0 0 0"
                  >
                    <QuestionTitle w="85%" p="5px 40px">
                      <Text
                        m="20px 0"
                        size="32px"
                        type={TEXT_TYPES.text}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        lineHeight="35px"
                        whiteSpace="pre-wrap"
                      >
                        {t(`faq.tabs.${i}.elements.${questionIndex}.title`)}
                      </Text>
                    </QuestionTitle>
                    <FlexRow w="90%" justifyContent="center">
                      <Text
                        size="20px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        letterSpacing="0.56px"
                        lineHeight="25px"
                        whiteSpace="pre-wrap"
                        m="30px 0"
                      >
                        {t(`faq.tabs.${i}.elements.${questionIndex}.text`)}
                      </Text>
                    </FlexRow>
                  </FlexColumn>
                ))}
              </FlexColumn>
            ))}
          </FlexColumn>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
      </FlexColumn>
    </FlexColumn>
  );
};
