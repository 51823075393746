import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { Settings } from 'react-slick';
import {
  StlyedSliderCard,
  StyledMobileSlider,
  StyledSlider,
  StyledTabletSlider,
} from './styled';
import {
  sliderCards,
  sliderLinks,
  sliderLinksMobile,
  sliderLinksTablet,
  sliderMobileCards,
  sliderTabletCards,
  sliderToTextMap,
} from './utils';
import { FlexRow, Text, TEXT_WEIGHTS, FlexColumn } from 'modules/ui/components';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
    data-testid="slider-arrow-left"
  >
    <img src="./assets/utils/arrow-l.svg" alt="left" />
  </button>
);

export const SlickArrowRight = ({
  currentSlide,
  slideCount,
  ...props
}: any) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
    data-testid="slider-arrow-right"
  >
    <img src="./assets/utils/arrow-r.svg" alt="right" />
  </button>
);

const sliderSettings: Settings = {
  dots: false,
  infinite: false,
  draggable: true,
  touchMove: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  centerMode: true,
};

export const CustomSlider = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isMobile = useMediaQuery({
    query: `(max-width: ${550}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${1000}px)`,
  });

  const navigate = useNavigate();

  if (isMobile)
    return (
      <StyledMobileSlider {...sliderSettings} infinite={true}>
        {sliderMobileCards.map((image, i) => (
          <FlexRow>
            <StlyedSliderCard
              w="90%"
              h="100%"
              style={{ transform: 'translateX(-40px)' }}
            >
              <FlexColumn
                w="100%"
                className="wrapper"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img
                  src={image}
                  alt={`service-${i}`}
                  width={210}
                  height={116}
                />
                <Text size="27px" weight={TEXT_WEIGHTS.tiny} m="35px 0">
                  {t(`landing.topServicesSection.slidesText.${i}`)}
                </Text>
              </FlexColumn>
              <button onClick={() => navigate(sliderLinksMobile[i])}>
                {t('landing.topServicesSection.button')}
              </button>
            </StlyedSliderCard>
          </FlexRow>
        ))}
      </StyledMobileSlider>
    );

  if (isTablet)
    return (
      <StyledTabletSlider {...sliderSettings}>
        {sliderTabletCards.map((slide, slideIndex) => (
          <FlexRow
            w="calc(100% - 150px)"
            h="100%"
            m="auto"
            className="slide-wrapper"
            justifyContent="center"
          >
            {slide.map((image, i) => (
              <StlyedSliderCard w="210px" h="210px">
                <FlexColumn
                  w="100%"
                  className="wrapper"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <img src={image} alt={`service-${i}`} />
                  <Text size="20px" weight={TEXT_WEIGHTS.tiny} m="35px 0">
                    {t(
                      `landing.topServicesSection.slidesText.${sliderToTextMap[image]}`,
                    )}
                  </Text>
                </FlexColumn>
                <button
                  onClick={() => navigate(sliderLinksTablet[slideIndex][i])}
                >
                  {t('landing.topServicesSection.button')}
                </button>
              </StlyedSliderCard>
            ))}
          </FlexRow>
        ))}
      </StyledTabletSlider>
    );

  return (
    <StyledSlider {...sliderSettings}>
      {sliderCards.map((slide, slideIndex) => (
        <FlexRow w="calc(100% - 150px)" h="100%" className="slide-wrapper">
          {slide.map((image, i) => (
            <StlyedSliderCard h="22vw">
              <FlexColumn
                w="100%"
                className="wrapper"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img src={image} alt={`service-${i}`} />
                <Text size="27px" weight={TEXT_WEIGHTS.tiny} m="35px 0">
                  {t(
                    `landing.topServicesSection.slidesText.${
                      slideIndex === 1 ? 3 + i : i
                    }`,
                  )}
                </Text>
              </FlexColumn>
              <button onClick={() => navigate(sliderLinks[slideIndex][i])}>
                {t('landing.topServicesSection.button')}
              </button>
            </StlyedSliderCard>
          ))}
        </FlexRow>
      ))}
    </StyledSlider>
  );
};
