import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTop = () => {
  const location = useLocation();

  const scrollTop = useCallback(
    () => window.scrollTo({ behavior: 'auto', top: 0 }),
    [],
  );

  useEffect(() => {
    scrollTop();
  }, [location, scrollTop]);

  return scrollTop;
};
