import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopTermsOfServices } from './TermOfServices.desktop';
import { MobileTermsOfService } from './TermsOfService.mobile';

export const TermsOfService = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileTermsOfService />;

  return <DesktopTermsOfServices />;
};
