import Router from 'router';
import { FlexColumn } from 'modules/ui/components';
import { Helmet } from 'react-helmet';
import { useTabTitle } from 'modules/hooks/useTabTitle';
import './App.css';

function App() {
  const title = useTabTitle();
  return (
    <FlexColumn w="100%" h="100%">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Router />
    </FlexColumn>
  );
}

export default App;
