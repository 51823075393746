import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopPrivacyPolicy } from './PrivacyPolicy.desktop';
import { MobilePrivacyPolicy } from './PrivacyPolicy.mobile';

export const PrivacyPolicy = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobilePrivacyPolicy />;

  return <DesktopPrivacyPolicy />;
};
