import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { points, subCards } from './utils';

export const DesktopDigitalProduct = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('digitalProduct.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              {t('digitalProduct.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('digitalProduct.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/digital-product/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow m="120px 0" w="100%" justifyContent="center" flexWrap="wrap">
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '30%' : '100%'}
                alignItems="center"
                m="60px auto"
              >
                <img
                  src={`./assets/digital-product/subCard-${i}.png`}
                  alt={`sub-card-${i}`}
                />
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`digitalProduct.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`digitalProduct.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="100px 0"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/digital-product/card-1.png" alt="story" />
            <FlexColumn w="60%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%">
                  <Text
                    m="20px 0"
                    size="35px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t(`digitalProduct.cards.1.title`)}
                  </Text>
                  <Text
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t(`digitalProduct.cards.1.text`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          m="100px 0"
          w="100%"
          alignItems="center"
          justifyContent="center"
          p="80px"
          bg="rgba(138, 245, 245, 0.1)"
        >
          <img src="./assets/digital-product/card-2.png" alt="card-2" />
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="30px 0"
              size="35px"
              letterSpacing="1.4px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`digitalProduct.cards.2.title`)}
            </Text>
            <Text
              w="50%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              letterSpacing="0.56px"
              lineHeight="25px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`digitalProduct.cards.2.text`)}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          p="0 0 70px 0"
          bg={'rgba(79, 255, 255, 0.001)'}
        >
          <FlexRow
            gap="20px"
            w="80%"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            {points.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '40%' : '100%'}
                alignItems="center"
                m="0 auto"
              >
                <img
                  src={`./assets/digital-product/points-${i}.png`}
                  alt="card-2"
                />
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0"
                      size="35px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`digitalProduct.points.${i}.title`)}
                    </Text>
                    <Text
                      w="70%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.56px"
                      lineHeight="25px"
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`digitalProduct.points.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
