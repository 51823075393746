export const cards = new Array(2).fill(' ');
export const subCards = new Array(6).fill(' ');

export const sliderMobileStyles = `
width: 110%;
transform: translateX(-17%);

.slick-arrow {
    width: 40px;
    background: none !important;
    top: 250px;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 100px;
  };

  .slick-next {
    right: -50px
  };

  .slick-slide > div > div{
    width: 90%;
    display: flex !important;
    align-items:center;
    flex-direction: column;
    text-align: center;
  }

`;
