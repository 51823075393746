import { Field } from 'formik';
import styled from 'styled-components';
import { FlexColumn } from '../Flex';

export const StyledFormikField = styled(Field)`
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 20px;
  color: #262938;
  background: white;
  padding: 10px;
  border: none;
  outline: none;
`;

export const FieldWrapper = styled(FlexColumn)`
  height: auto;
  margin: 5px 0px;
  font: normal normal normal 16px/32px Source Sans Pro;
  color: #262938;

  & ::placeholder {
    color: #262938;
    font-size: 16px;
  }

  span {
    color: darkred;
  }

  textarea {
    height: 230px;
    resize: none;
  }
`;
