import { COLORS, FlexRow } from 'modules/ui/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledFooterLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.white};

  &:hover {
    color: ${COLORS.lightBlue};
  }
`;

export const Divider = styled(FlexRow)`
  width: 100%;
  height: 2px;
  background: ${COLORS.white};
`;
