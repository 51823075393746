import { COLORS } from 'modules/ui/components';

export const terms = [
  { items: new Array(12).fill(' '), lists: [] },
  {
    items: new Array(26).fill(' '),
    lists: [{ list: new Array(4).fill(' '), position: 25 }],
  },
  {
    items: new Array(9).fill(' '),
    lists: [{ list: new Array(7).fill(' '), position: 6 }],
  },
  { items: new Array(11).fill(' '), lists: [] },
];

export const isUrl = (passedUrl: string, isMobile?: boolean) => {
  let url;
  try {
    url = new URL(passedUrl);
  } catch (_) {
    return false;
  }

  if (isMobile) return false;

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const detectUrlInText = (text: string, isMobile?: boolean) => {
  return text
    .split(' ')
    .map((t) =>
      isUrl(t, isMobile)
        ? `<span style="color:${COLORS.yellow}; display: flex">${t}</span>`
        : t,
    )
    .join(' ');
};
