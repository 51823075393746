import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Text,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Button,
  BUTTON_TYPES,
  Section,
} from 'modules/ui/components';
import { useNavigate } from 'react-router-dom';

export const CompanySection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();

  return (
    <Section h="600px">
      <FlexColumn w="100%" h="100%" alignItems="center" justifyContent="center">
        <FlexColumn w="80%" h="80%">
          <FlexRow w="100%" alignItems="center" justifyContent="space-between">
            <Text
              size={TEXT_SIZES.mega}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              type={TEXT_TYPES.title}
              animatable
            >
              {t('landing.companySection.title')}
            </Text>
            <Button
              buttonType={BUTTON_TYPES.filled}
              onClick={() => navigate('/about-us')}
            >
              {t('landing.companySection.button')}
            </Button>
          </FlexRow>
          <FlexColumn w="80%">
            <Text
              size={'21px'}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              m={'15px 0'}
              animatable
              lineHeight="35px"
            >
              <strong>Lab K19</strong> {t('landing.companySection.text.0')}
            </Text>
            <Text
              size={'21px'}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              animatable
              m="20px 0"
              lineHeight="35px"
            >
              {t('landing.companySection.text.1')}
            </Text>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
