import { useState, useCallback, useEffect } from 'react';
import { COLORS, FlexRow } from 'modules/ui/components/Flex';
import { Logo, ContactLink, StyledHeaderLink } from 'modules/ui/components';
import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { headerLinks } from './utils';
import { StyledDropDown, StyledDropdownWrapper, StyledHeader } from './styled';
import { useScrollTop } from 'modules/hooks/useScrollTop';

export const DesktopHeader = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.COMPONENTS);
  useScrollTop();

  const [currentActiveList, setCurrentActiveList] = useState<null | number>(
    null,
  );

  const onListClick = useCallback((listIndex: number) => {
    setCurrentActiveList(listIndex);
  }, []);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      setCurrentActiveList(null);
    };

    window.addEventListener('click', listener);

    return () => window.removeEventListener('click', listener);
  }, []);

  return (
    <StyledHeader as="header" bg={COLORS.darkBlue}>
      <FlexRow
        w="calc(100% - 70px)"
        h="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <FlexRow gap="20px" position="relative">
          <Logo isClickable />
        </FlexRow>
        <FlexRow w="auto" p="15px">
          {headerLinks.map(({ path, title, subLinks }, i) => {
            if (title === 'contact')
              return (
                <ContactLink
                  key={title}
                  to={path}
                  title={t(`header.links.${title}`)}
                />
              );
            return (
              <StyledHeaderLink
                as="p"
                className="header-link"
                key={title}
                to="#"
                onClick={(e) => {
                  e.stopPropagation();
                  onListClick(i);
                }}
              >
                <StyledDropdownWrapper>
                  {t(`header.links.${title}`)}
                  {subLinks && (
                    <StyledDropDown
                      className={`dropdown ${
                        i === currentActiveList ? 'active' : ''
                      }`}
                      alignItems="center"
                    >
                      {subLinks.map(({ path }) => (
                        <StyledHeaderLink
                          key={path}
                          to={path}
                          className="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentActiveList(null);
                          }}
                        >
                          {t(`header.links.${path}`)}
                        </StyledHeaderLink>
                      ))}
                    </StyledDropDown>
                  )}
                </StyledDropdownWrapper>
              </StyledHeaderLink>
            );
          })}
        </FlexRow>
      </FlexRow>
    </StyledHeader>
  );
};
