import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { points, subCards } from './utils';

export const DesktopDataAndAnalytics = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('dataAndAnalytics.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('dataAndAnalytics.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('dataAndAnalytics.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/data&analytics/card-0.png" alt="story" />
          </FlexRow>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0 26px 0"
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                textAlign="center"
              >
                {t('dataAndAnalytics.cards.1.title')}
              </Text>
              <Text
                w="70%"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
              >
                {t('dataAndAnalytics.cards.1.text')}
              </Text>
            </FlexColumn>
          </Animatable>
          <FlexRow
            m="120px 0"
            w="100%"
            justifyContent="center"
            flexWrap="wrap"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '40%' : '100%'}
                alignItems="center"
                m="60px auto"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <img
                      src={`./assets/data&analytics/subCard-${i}.png`}
                      alt="sub-card"
                    />
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`dataAndAnalytics.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`dataAndAnalytics.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="100px 0"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/data&analytics/card-1.png" alt="story" />

            <FlexColumn w="60%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <Text
                  m="20px 0"
                  size="35px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`dataAndAnalytics.cards.2.title`)}
                </Text>
                <Text
                  m="15px 0"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.56px"
                  lineHeight="25px"
                  whiteSpace="pre-wrap"
                  textAlign={isTablet ? 'center' : 'start'}
                >
                  {t(`dataAndAnalytics.cards.2.text`)}
                </Text>
              </Animatable>
            </FlexColumn>
          </FlexRow>
          <FlexColumn w="100%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="20px 0"
                  size="35px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`dataAndAnalytics.cards.3.title`)}
                </Text>
                <Text
                  w="80%"
                  m="15px 0"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.56px"
                  lineHeight="25px"
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`dataAndAnalytics.cards.3.text`)}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          bg={'rgba(79, 255, 255, 0.001)'}
        >
          <FlexRow
            w="80%"
            p="120px 0 200px 0"
            justifyContent="space-between"
            flexDirection={isTablet ? 'column !important' : 'row'}
            alignItems="center"
          >
            <img src="./assets/data&analytics/card-2.png" alt="card-2" />
            <FlexColumn w={isTablet ? '60%' : 'auto'} alignItems="center">
              {points.slice(0, 5).map((_, i) => (
                <FlexRow w="100%" h="auto" alignItems="center" gap="12px">
                  <img src="./assets/utils/check-sign.png" alt="check" />
                  <Text
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    size={TEXT_SIZES.s}
                    lineHeight="37px"
                    letterSpacing="0.56px"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t(`dataAndAnalytics.points.${i}`)}
                  </Text>
                </FlexRow>
              ))}
            </FlexColumn>
            <FlexColumn w={isTablet ? '60%' : 'auto'} alignItems="center">
              {points.slice(5, points.length).map((_, i) => (
                <FlexRow w="100%" h="auto" alignItems="center" gap="12px">
                  <img src="./assets/utils/check-sign.png" alt="check" />
                  <Text
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    size={TEXT_SIZES.s}
                    lineHeight="37px"
                    letterSpacing="0.56px"
                    textAlign={isTablet ? 'center' : 'start'}
                  >
                    {t(`dataAndAnalytics.points.${i + 5}`)}
                  </Text>
                </FlexRow>
              ))}
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-2%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
