import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopCookiesPolicy } from './CookiesPolicy.desktop';
import { MobileCookiesPolicy } from './CookiesPolicy.mobile';

export const CookiesPolicy = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileCookiesPolicy />;

  return <DesktopCookiesPolicy />;
};
