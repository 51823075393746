import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { partnersImages } from './utils';
import { COLORS, FlexRow, Section } from 'modules/ui/components';
import { useMediaQuery } from 'react-responsive';

export const DesktopPartners = () => {
  const container = useRef(null);
  const isInView = useInView(container, { once: true });

  const isIconsShouldBeDescaled = useMediaQuery({
    query: `(max-width: ${1100}px)`,
  });

  return (
    <Section h="230px">
      <FlexRow
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        bg={COLORS.lightBlue}
        gap="30px"
        ref={container}
        zIndex="3"
      >
        {partnersImages.map((src, i) => (
          <FlexRow
            key={`partner${i}`}
            as={isInView ? motion.div : FlexRow}
            initial={{ y: `${-20 * (i + 1)}px`, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0 + i * 0.3 }}
            m="0 5%"
          >
            <img
              height={!isIconsShouldBeDescaled ? 120 : 90}
              src={src}
              alt={`partner-${i}`}
            />
          </FlexRow>
        ))}
      </FlexRow>
    </Section>
  );
};
