import { COLORS, FlexColumn, FlexRow } from 'modules/ui/components';

import { FC, useRef, useState } from 'react';
import { VideoBackDrop } from './styled';
import { AiOutlineClose } from 'react-icons/ai';

interface Props {
  w?: string;
  h?: string;
  src: string;
}

export const MobileVideo: FC<Props> = ({ src, w, h }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const videoRef = useRef<HTMLIFrameElement | null>(null);

  const onToggleVideoPlay = () => {
    setIsVideoPlaying(!isVideoPlaying);
  };

  return (
    <FlexRow w="100%" position="relative">
      <VideoBackDrop visibility={isVideoPlaying ? 'hidden' : 'visible'} h={h}>
        <img
          alt="play"
          src="./assets/utils/video-button-light.png"
          onClick={(e) => {
            e.stopPropagation();
            onToggleVideoPlay();
          }}
        />
      </VideoBackDrop>

      {isVideoPlaying ? (
        <FlexColumn
          w="100wv"
          h="100vh"
          position="fixed"
          zIndex="100"
          style={{ top: 0, left: 0 }}
        >
          <FlexRow
            w="100%"
            position="relative"
            justifyContent="flex-end"
            bg={COLORS.darkBlue}
          >
            <AiOutlineClose
              color="white"
              size="25px"
              style={{
                margin: '5px',
              }}
              onClick={onToggleVideoPlay}
            />
          </FlexRow>
          <iframe
            className="mobile-frame"
            width={w || '100%'}
            height={h || 'auto'}
            ref={videoRef}
            src={src}
            title="video"
            style={{
              border: 'none',
              width: '100vw',
              height: '100vh',
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; full-screen"
            allowFullScreen
          />
        </FlexColumn>
      ) : (
        <FlexColumn w="100%" h={h} />
      )}
    </FlexRow>
  );
};
