import styled from 'styled-components';
import { COLORS } from './Flex';
import { TEXT_SIZES } from './Text';

export const enum BUTTON_TYPES {
  filled = 'filled',
}

export interface ButtonProps {
  buttonType: BUTTON_TYPES;
}

export const Button = styled.button<ButtonProps>`
  outline: none;
  min-width: 150px;
  padding: 10px;
  height: 45px;
  border-radius: 30px;
  border: 2px solid ${COLORS.yellow};
  cursor: pointer;
  display: flex;
  font-size: ${TEXT_SIZES.s} !important;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  * {
    font-weight: 900;
  }

  ${({ buttonType }) =>
    buttonType === BUTTON_TYPES.filled &&
    `
        background: ${COLORS.yellow};
        color: ${COLORS.black};
    `}
`;
