import { FC } from 'react';

export const enum SPACINGS {
  s = '30px',
  l = '37px',
  xl = '55px',
  xxl = '72px',
}

interface Props {
  spacing: SPACINGS;
}

export const Spacer: FC<Props> = ({ spacing }) => {
  return <div style={{ margin: spacing }} />;
};
