import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { COLORS, FlexRow } from './Flex';
import { Text, TEXT_SIZES, TEXT_TYPES, TEXT_WEIGHTS } from './Text';

interface Props {
  title: string;
}

export const PageTitle: FC<Props> = ({ title }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  return (
    <FlexRow
      w="100%"
      mh={!isMobile ? '110px' : '70px'}
      bg={COLORS.lightBlue}
      alignItems="center"
      justifyContent="center"
    >
      <FlexRow w={!isMobile ? '80%' : ' 90%'} h="50%" alignItems="center">
        <Text
          m="0"
          size={!isMobile ? TEXT_SIZES.xxl : '36px'}
          type={TEXT_TYPES.title}
          color={COLORS.darkBlue}
          weight={TEXT_WEIGHTS.bolder}
          textAlign={'start !important'}
        >
          {title}
        </Text>
      </FlexRow>
    </FlexRow>
  );
};
