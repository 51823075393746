export const cards = new Array(4).fill(' ');
export const subCards = new Array(4).fill(' ');
export const points = new Array(10).fill(' ');

export const sliderMobileStyles = `
transform: translateX(-16%);
 .slick-arrow {
    width: 40px;
    height: 100px;
    background: none !important;
    top: 190px;
 };

  .img {
    max-width: 100px;
    height: auto;
  }

  .slick-prev {
    left: 10%;
  };

  .slick-next {
    right: -8%
  };

  .slick-slide div {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

`;
