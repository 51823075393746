import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopHowWeWork } from './HowWeWork.desktop';
import { MobileHowWeWork } from './HowWeWork.mobile';

export const HowWeWork = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileHowWeWork />;

  return <DesktopHowWeWork />;
};
