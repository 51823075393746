import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  Button,
  BUTTON_TYPES,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useCallback } from 'react';
import { terms, settings } from './utils';

export const MobileCookiesPolicy = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const onPrint = useCallback(() => window.print(), []);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('cookiesPolicy.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" m="20px 0" alignItems="center">
          {terms.map((items, i) => (
            <FlexColumn w="100%" gap="10px" m="40px 0">
              <Text
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.bolder}
                lineHeight="40px"
                letterSpacing="1.12px"
                whiteSpace="pre-wrap"
              >
                {t(`cookiesPolicy.terms.${i}.title`)}
              </Text>
              {items.map((text, textIndex) => {
                return (
                  <Text
                    m="10px 0"
                    size="20px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.92px"
                    lineHeight="40px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`cookiesPolicy.terms.${i}.texts.${textIndex}`)}
                  </Text>
                );
              })}
            </FlexColumn>
          ))}
          <FlexColumn w="100%" m="40px 0">
            {settings.map((text, i) => (
              <Text
                m="7px"
                size="20px"
                type={TEXT_TYPES.text}
                color={COLORS.yellow}
                weight={TEXT_WEIGHTS.bolder}
                lineHeight="28px"
                whiteSpace="pre-wrap"
              >
                {t(`cookiesPolicy.settings.${i}`)}
              </Text>
            ))}
          </FlexColumn>
          <FlexColumn
            w="100%"
            gap="40px"
            p="20px 0 100px 0"
            alignItems="center"
          >
            <img src="./assets/terms/print.png" alt="print" />
            <Button
              style={{ width: '70%' }}
              buttonType={BUTTON_TYPES.filled}
              onClick={onPrint}
            >
              {t(`cookiesPolicy.button`)}
            </Button>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
      <FlotableElement left="-27%" top="170%">
        <Square zIndex="1" size="30vw" />
      </FlotableElement>
    </FlexColumn>
  );
};
