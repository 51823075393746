import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { TabsSection } from 'modules/sections/components/TabsSection/TabsSection';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { subCard, tabs } from './utils';

export const DesktopTV = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isBigTablet = useMediaQuery({
    query: `(max-width: ${1130}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('tv.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('tv.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('tv.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/tv/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow w="100%" justifyContent="space-between" flexWrap="wrap">
            {subCard.map((_, i) => (
              <FlexColumn
                w={isBigTablet ? '100%' : '30%'}
                alignItems="center"
                m="0 0 60px 0"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`tv.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`tv.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="150px 0"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
            flexDirection={isBigTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/tv/card-1.png" alt="story" />
            <FlexColumn w="60%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%">
                  <Text
                    m="20px 0"
                    size="35px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign={!isBigTablet ? 'start' : 'center'}
                  >
                    {t(`tv.cards.1.title`)}
                  </Text>
                  <Text
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                    textAlign={!isBigTablet ? 'start' : 'center'}
                  >
                    {t(`tv.cards.1.text`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexRow>
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="20px 0"
              size="35px"
              letterSpacing="1.4px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`tv.cards.2.title`)}
            </Text>
            <Text
              w="80%"
              m="15px 0"
              size={TEXT_SIZES.s}
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              letterSpacing="0.56px"
              lineHeight="25px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t(`tv.cards.2.text`)}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="120px 0 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <TabsSection
            tabs={tabs.map((_, i) => ({
              title: t(`tv.tabs.${i}.title`),
              content: (
                <FlexRow
                  w="100%"
                  flexDirection={isBigTablet ? 'column !important' : 'row'}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FlexColumn
                    w="70%"
                    h={!isBigTablet ? '600px' : '300px'}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      m="20px 0"
                      size="35px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                    >
                      {'> ' + t(`tv.tabs.${i}.subTitle`)}
                    </Text>
                    <Text
                      w="80%"
                      m="15px 0"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.56px"
                      lineHeight="25px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`tv.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                  <img src={`./assets/tv/subCard-${i}.png`} alt="sub-card" />
                </FlexRow>
              ),
            }))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
