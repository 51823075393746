import { ReactNode } from 'react';
import { Settings } from 'react-slick';

export interface SliderProps {
  slides: ReactNode[];
  styles?: string;
}

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
    data-testid="slider-arrow-left"
  >
    <img src="./assets/utils/arrow-l.svg" alt="left" />
  </button>
);

export const SlickArrowRight = ({
  currentSlide,
  slideCount,
  ...props
}: any) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
    data-testid="slider-arrow-right"
  >
    <img src="./assets/utils/arrow-r.svg" alt="right" />
  </button>
);

export const sliderSettings: Settings = {
  dots: false,
  infinite: true,
  draggable: true,
  touchMove: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  centerMode: true,
};
