import styled from 'styled-components';
import { FlexRow } from '../Flex';

export const StyledLight = styled(FlexRow)`
  width: 10px;
  height: 10px;
  box-shadow: 100px -10px 50px 40px white;

  &:before {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    content: '';
    box-shadow: 100px -5px 40px 40px #107ce9;
  }

  &:after {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    content: '';
    box-shadow: 100px -10px 30px 40px #14e0ab;
  }
`;

export const StyledWideLIght = styled(FlexRow)`
  width: 30px;
  height: 20px;
  box-shadow: 0px -10px 30px 40px #14e0ab;
`;
