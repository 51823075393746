import { useMediaQuery } from 'react-responsive';
import { DesktopHeader } from './Header.desktop';
import { MobileHeader } from './Header.mobile';
import { TabletHeader } from './Header.tablet';

export const Header = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 880px)`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: 1100px)`,
  });

  if (isMobile) return <MobileHeader />;
  if (isTablet) return <TabletHeader />;

  return <DesktopHeader />;
};
