import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { subCards } from './utils';

export const DesktopVideoStreaming = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('videoStreaming.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              whiteSpace="pre-wrap"
              textAlign="center"
            >
              {t('videoStreaming.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('videoStreaming.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/video-streaming/card-0.png" alt="platform" />
          </FlexRow>
          <FlexColumn
            w="100%"
            m="40px 0"
            alignItems="center"
            justifyContent="center"
          >
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  size="35px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                >
                  {t('videoStreaming.cards.1.title')}
                </Text>
                <Text
                  w="70%"
                  size="22px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  letterSpacing="0.62px"
                  lineHeight="30px"
                  textAlign="center"
                >
                  {t('videoStreaming.cards.1.text')}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
          <FlexColumn w="100%" m="150px 0 0 0" alignItems="center">
            <img src="./assets/video-streaming/card-1.png" alt="platform" />
          </FlexColumn>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                m="80px 0 0 0"
              >
                {t('videoStreaming.subTitle')}
              </Text>
            </FlexColumn>
          </Animatable>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="0 0 50px 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <FlexRow
            m="10px 0"
            w="80%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '25%' : '100%'}
                alignItems="center"
                justifyContent="flex-start"
                m="25px 25px"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <FlexColumn
                      m="100px 0 20px 0"
                      h="100px"
                      justifyContent="flex-end"
                    >
                      <Text
                        m="30px 0 10px 0"
                        size="30px"
                        letterSpacing="1.4px"
                        type={TEXT_TYPES.title}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        textAlign="center"
                      >
                        {t(`videoStreaming.subCards.${i}.title`)}
                      </Text>
                    </FlexColumn>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`videoStreaming.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-6%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
