export const cards = new Array(3).fill(' ');
export const subCards = new Array(6).fill(' ');
export const tabs = new Array(5).fill(' ');

export const sliderMobileStyles = `
width: 120%;
transform: translateX(-10%);

.slick-arrow {
    width: 40px;
    background: none !important;
    top: 250px;
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 90px;
  };

  .slick-next {
    right: -10px
  };

  .slick-slide > div > div{
    width: 90%;
    display: flex !important;
    align-items:center;
    flex-direction: column;
    text-align: center;
  }

`;

export const sliderTabsMobileStyles = `
  transform: translateX(-20%);

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-arrow {
    top: 200px
  }

  .slick-next {
    right: -13%;
  }

  .slick-prev {
    left: 10%;
  }

  .slick-slide > div {
    text-align: center
  }

 
`;
