import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import { Tab } from 'modules/sections/components/TabsSection/styled';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  TEXT_SIZES,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  sliderMobileStyles,
  subCards,
  tabs,
  sliderTabsMobileStyles,
} from './utils';

export const MobileAI = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('ai.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="43px 0 47px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('ai.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="20px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('ai.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="50px 0">
            <img
              src="./assets/ai/ai.png"
              alt="story"
              width={110}
              height={113}
            />
          </FlexRow>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0 26px 0"
                size="30px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                textAlign="center"
              >
                {t('ai.cards.1.title')}
              </Text>
              <Text
                w="100%"
                size="20px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
              >
                {t('ai.cards.1.text')}
              </Text>
            </FlexColumn>
          </Animatable>
          <FlexRow m="120px 0" w="100%" justifyContent="center" flexWrap="wrap">
            <MobileSlider
              styles={sliderMobileStyles}
              slides={subCards.map((_, i) => (
                <FlexColumn w="100%" alignItems="center">
                  <img src={`./assets/ai/subCard-${i}.png`} alt="sub-card" />
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`ai.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`ai.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </FlexColumn>
              ))}
            />
          </FlexRow>
          <FlexColumn
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            m="0 0 88px 0"
          >
            <img
              src="./assets/ai/technologies.png"
              alt="story"
              width={150}
              height={156}
            />
            <FlexColumn w="100%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    m="20px 0"
                    size="30px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`ai.cards.1.title`)}
                  </Text>
                  <Text
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                    textAlign="center"
                  >
                    {t(`ai.cards.1.text`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn w="100%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="20px 0"
                  size="30px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`ai.cards.2.title`)}
                </Text>
                <Text
                  w="80%"
                  m="15px 0"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.56px"
                  lineHeight="25px"
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`ai.cards.2.text`)}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="120px 0 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <MobileSlider
            styles={sliderTabsMobileStyles}
            slides={tabs.map((points, i) => (
              <FlexRow w="100%" alignItems="center" gap="70px">
                <Tab w="175px !important" p="10px">
                  <Text
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`ai.tiles.${i}.title`)}
                  </Text>
                </Tab>
                <img
                  src={`./assets/ai/tile-${i}.png`}
                  alt="sub-card"
                  className="img"
                />
                <FlexColumn
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  {points.map((_: string, pointIndex: number) => (
                    <FlexRow
                      w="100%"
                      m="10px 0"
                      gap="14px"
                      flexDirection="row !important"
                    >
                      <img
                        style={{ margin: '6px 0' }}
                        src={`./assets/utils/check-sign.png`}
                        alt="sub-card"
                      />
                      <Text
                        size={TEXT_SIZES.s}
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        letterSpacing="0.56px"
                        lineHeight="25px"
                        whiteSpace="pre-wrap"
                      >
                        {t(`ai.tiles.${i}.points.${pointIndex}`)}
                      </Text>
                    </FlexRow>
                  ))}
                </FlexColumn>
              </FlexRow>
            ))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="10%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
