import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { benefitsPoints, subCards, whenToUsePoints } from './utils';

export const DesktopIntegration = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('integration.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('integration.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('integration.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/integration/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow
            m="80px 0"
            w="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isTablet ? '40%' : '100%'}
                alignItems="center"
                m="60px auto"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`integration.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`integration.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
        <FlexRow
          w="80%"
          alignItems="center"
          justifyContent="center"
          gap="140px"
          m="60px 0 0 0"
          flexDirection={isTablet ? 'column !important' : 'row'}
        >
          <img src="./assets/integration/benefits.png" alt="story" />
          <FlexColumn w={!isTablet ? '50%' : '100%'}>
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="20px 0 30px 30px"
                  size="35px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                  whiteSpace="pre-wrap"
                >
                  {t('integration.benefits.title')}
                </Text>
              </FlexColumn>
            </Animatable>
            {benefitsPoints.map((_, i) => (
              <FlexRow gap="10px" m="0 0 25px 0">
                <img
                  src="./assets/utils/check-sign.png"
                  alt="checkbox"
                  style={{ margin: '3px 0 0 0' }}
                />
                <FlexColumn>
                  <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                    <FlexColumn w="100%">
                      <Text
                        size="22px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        letterSpacing="0.62px"
                        lineHeight="25px"
                      >
                        {t(`integration.benefits.points.${i}.title`)}
                      </Text>
                      <Text
                        size="22px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        whiteSpace="pre-wrap"
                        letterSpacing="0.62px"
                        lineHeight="25px"
                      >
                        {t(`integration.benefits.points.${i}.text`)}
                      </Text>
                    </FlexColumn>
                  </Animatable>
                </FlexColumn>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexRow>
        <FlexColumn
          w="100%"
          p="80px 0 150px 0"
          m="100px 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <FlexRow
            w="80%"
            alignItems="center"
            justifyContent="center"
            gap="140px"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <FlexColumn w={!isTablet ? '50%' : '100%'}>
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                    <FlexColumn w="100%" alignItems="center">
                      <Text
                        m="20px 0 10px 30px"
                        size="35px"
                        type={TEXT_TYPES.title}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        letterSpacing="1.4px"
                        lineHeight="35px"
                        whiteSpace="pre-wrap"
                      >
                        {t('integration.whenToUse.title')}
                      </Text>
                      <Text
                        m="30px 0 25px 30px"
                        size="22px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        letterSpacing="0.62px"
                        lineHeight="25px"
                      >
                        {t(`integration.whenToUse.subTitle`)}
                      </Text>
                    </FlexColumn>
                  </Animatable>
                </FlexColumn>
              </Animatable>
              {whenToUsePoints.map((_, i) => (
                <FlexRow gap="15px" m="0 0 25px 0">
                  <img
                    src="./assets/utils/check-sign.png"
                    alt="checkbox"
                    style={{ margin: '3px 0 0 0' }}
                  />
                  <FlexColumn>
                    <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                      <FlexColumn w="100%" alignItems="center">
                        <Animatable
                          animationType={ANIMATIONS_TYPES.appendBottom}
                        >
                          <FlexColumn w="100%">
                            <Text
                              size="22px"
                              type={TEXT_TYPES.text}
                              color={COLORS.white}
                              weight={TEXT_WEIGHTS.tiny}
                              whiteSpace="pre-wrap"
                              letterSpacing="0.62px"
                              lineHeight="25px"
                            >
                              {t(`integration.whenToUse.points.${i}`)}
                            </Text>
                          </FlexColumn>
                        </Animatable>
                      </FlexColumn>
                    </Animatable>
                  </FlexColumn>
                </FlexRow>
              ))}
            </FlexColumn>
            <img src="./assets/integration/whenToUse.png" alt="story" />
          </FlexRow>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-2%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
