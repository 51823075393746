export const offices = [
  {
    phone: '+49 (0) 30 700 140 296',
    email: 'support@labk19.com',
    address: 'https://goo.gl/maps/gF38axWUe9cFo2nC8',
  },
  {
    phone: '+39 (0) 06 574 8353',
    email: 'support@labk19.com',
    address: 'https://goo.gl/maps/xtiH5yworPaoRcwL7',
  },
  { phone: '', email: '', address: '' },
];
