import { useInView } from 'framer-motion';
import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useRef } from 'react';
import CountUp from 'react-countup';
import { useMediaQuery } from 'react-responsive';
import { numbers, subCards } from './utils';

export const DesktopSoftwareModernization = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${1010}px)`,
  });

  const container = useRef(null);
  const isInView = useInView(container, { once: true });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('softwareModernization.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('softwareModernization.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('softwareModernization.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/software-modernization/card-0.png" alt="story" />
          </FlexRow>
          <FlexRow
            m="120px 0"
            w="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            {subCards.map((_, i) => (
              <FlexColumn
                w={isTablet ? '100%' : '30%'}
                alignItems="center"
                m="60px auto"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <img
                      src={`./assets/software-modernization/subCard-${i}.png`}
                      alt="sub-card"
                    />
                    <Text
                      m="30px 0 10px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`softwareModernization.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="80%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      lineHeight="30px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`softwareModernization.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          w="100%"
          alignItems="center"
          justifyContent="center"
          p="100px"
          bg="rgba(138, 245, 245, 0.1)"
        >
          <FlexRow
            w="80%"
            alignItems="center"
            justifyContent="center"
            gap={!isTablet ? '140px' : '30px'}
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/software-modernization/card-1.png" alt="story" />
            <FlexColumn w="50%">
              <FlexColumn w="100%">
                <Text
                  m="20px 0 26px 0"
                  size="35px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.4px"
                  lineHeight="35px"
                  whiteSpace="pre-wrap"
                  textAlign={isTablet ? 'center' : 'start'}
                >
                  {t('softwareModernization.cards.1.title')}
                </Text>
                <Text
                  size="22px"
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  whiteSpace="pre-wrap"
                  letterSpacing="0.62px"
                  lineHeight="25px"
                  textAlign={isTablet ? 'center' : 'start'}
                >
                  {t('softwareModernization.cards.1.text')}
                </Text>
              </FlexColumn>
            </FlexColumn>
          </FlexRow>
          <FlexColumn w="80%" alignItems="center" ref={container}>
            <FlexRow
              w="100%"
              m="100px 0 0 0"
              justifyContent="space-around"
              alignItems="center"
              flexWrap="wrap"
              flexDirection={isTablet ? 'column !important' : 'row'}
            >
              {numbers.slice(0, 3).map((_, i) => (
                <FlexColumn
                  w="auto"
                  h="200px"
                  alignItems="center"
                  gap="15px"
                  hidden={isInView}
                >
                  {isInView && (
                    <Text
                      m="0px 0"
                      size={TEXT_SIZES.mega}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                    >
                      <CountUp
                        duration={1}
                        start={0}
                        end={
                          +t(`softwareModernization.numbers.${i}.number`).slice(
                            0,
                            2,
                          )
                        }
                      />
                      {t(`softwareModernization.numbers.${i}.number`)[2]}
                    </Text>
                  )}
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.l}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i}.title`)}
                  </Text>
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.s}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            </FlexRow>
            <FlexRow
              w="auto"
              m="40px 0 0 0"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gap={!isTablet ? '150px' : '20px'}
              flexDirection={isTablet ? 'column !important' : 'row'}
            >
              {numbers.slice(3, numbers.length).map((_, i) => (
                <FlexColumn w="auto" h="200px" alignItems="center" gap="15px">
                  {isInView && (
                    <Text
                      m="0px 0"
                      size={TEXT_SIZES.mega}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                    >
                      <CountUp
                        duration={1}
                        start={0}
                        end={
                          +t(
                            `softwareModernization.numbers.${i + 3}.number`,
                          ).slice(0, 2)
                        }
                      />
                      {t(`softwareModernization.numbers.${i + 3}.number`)[2]}
                    </Text>
                  )}
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.l}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i + 3}.title`)}
                  </Text>
                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.s}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                  >
                    {t(`softwareModernization.numbers.${i + 3}.text`)}
                  </Text>
                </FlexColumn>
              ))}
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
