import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  AgileApproachSection,
  AgileNumbers,
} from 'modules/sections/components/AgileApproachSection';
// import { OrganizeYourWorkSection } from 'modules/sections/components/OrganizeYourWorkSection';
import { TechnologyWeUseSection } from 'modules/sections/components/TechnologyWeUseSection';
import { WorkSistematicallySection } from 'modules/sections/components/WorkSistematicallySection';
import {
  COLORS,
  FlexColumn,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { PageTitle } from 'modules/ui/components/PageTitle';

export const DesktopHowWeWork = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('howWeWork.title')} />
      <FlexColumn
        w="100%"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
        position="relative"
      >
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn w="80%" alignItems="center">
            {/* <OrganizeYourWorkSection /> */}
            <WorkSistematicallySection />
            <AgileApproachSection />
          </FlexColumn>
          <AgileNumbers />
          <FlexColumn
            w="100%"
            p="80px 0"
            alignItems="center"
            justifyContent="center"
            bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`}
          >
            <TechnologyWeUseSection />
          </FlexColumn>
        </FlexColumn>
        <FlotableElement left="85%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="50%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
