import styled from 'styled-components';
import { FlexRow } from './Flex';

interface Props {
  size?: string;
  zIndex?: string;
  bg?: string;
}

export const Square = styled(FlexRow)<Props>`
  width: ${({ size }) => size || '530px'};
  height: ${({ size }) => size || '530px'};
  z-index: ${({ zIndex }) => zIndex || 'auto'};
  background: ${({ bg }) => bg || 'white'};
  border-radius: 10%;
  transform: rotate(45deg);
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;

  .rotatable {
    transform: rotate(-45deg);
  }
`;
