import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
  Button,
  BUTTON_TYPES,
} from 'modules/ui/components';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useCallback } from 'react';
import { Marker } from './styled';
import { detectUrlInText, terms } from './utils';

export const DesktopPrivacyPolicy = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const onPrint = useCallback(() => window.print(), []);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('privacyPolicy.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" m="150px 0" alignItems="center">
          {terms.map(({ items, lists }, i) => (
            <FlexColumn w="100%" gap="20px" m="40px 0">
              <Text
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                lineHeight="32px"
                whiteSpace="pre-wrap"
              >
                {t(`privacyPolicy.terms.${i}.title`)}
              </Text>
              {items.map((text, textIndex) => {
                const list = lists.find(
                  (l: { position: number; list: string[] }) =>
                    l.position === textIndex,
                )!;
                const listIndex = lists.findIndex(
                  (l: { position: number; list: string[] }) =>
                    l.position === textIndex,
                );

                return (
                  <>
                    {list?.list.map((text, listItemIndex) => (
                      <FlexRow w="100%" gap="15px">
                        <Marker />
                        <Text
                          size="20px"
                          type={TEXT_TYPES.text}
                          color={COLORS.white}
                          weight={TEXT_WEIGHTS.tiny}
                          letterSpacing="0.56px"
                          lineHeight="25px"
                        >
                          {t(
                            `privacyPolicy.terms.${i}.lists.${listIndex}.${listItemIndex}`,
                          )}
                        </Text>
                      </FlexRow>
                    ))}
                    <Text
                      size="20px"
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      letterSpacing="0.56px"
                      lineHeight="25px"
                      whiteSpace="pre-wrap"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: detectUrlInText(
                            t(`privacyPolicy.terms.${i}.texts.${textIndex}`),
                          ),
                        }}
                      />
                    </Text>
                  </>
                );
              })}
            </FlexColumn>
          ))}
          <FlexColumn w="100%" gap="40px" alignItems="center" m="100px 0 0 0">
            <img src="./assets/terms/print.png" alt="print" />
            <Button buttonType={BUTTON_TYPES.filled} onClick={onPrint}>
              {t(`privacyPolicy.button`)}
            </Button>
          </FlexColumn>
        </FlexColumn>
        <FlotableElement left="87%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-27%" top="30%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="99%" top="80%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
