import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopFAQ } from './FAQ.desktop';
import { MobileFAQ } from './FAQ.mobile';

export const FAQ = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileFAQ />;

  return <DesktopFAQ />;
};
