import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { useMediaQuery } from 'react-responsive';
import { StyledMemberImage } from './styled';
import { teamMembers } from './utils';

export const TeamSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isMobile = useMediaQuery({
    query: `(max-width: ${550}px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn w="100%" h="100%" alignItems="center" justifyContent="center">
        <FlexColumn
          w="100%"
          h="80%"
          m="0 0 30px 0"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            m="50px 0 80px 0"
            size="35px"
            letterSpacing="1.4px"
            type={TEXT_TYPES.title}
            color={COLORS.lightBlue}
            weight={TEXT_WEIGHTS.bolder}
            textAlign="center"
          >
            {t('howWeWork.teamSection.title')}
          </Text>
          <FlexRow w="100%" gap="20px" flexWrap="wrap" justifyContent="center">
            {teamMembers.slice(0, 2).map(({ image, role, name }, i) => (
              <>
                <FlexColumn
                  w={isMobile ? '40%' : '20%'}
                  alignItems="center"
                  m={'0 10px 65px 10px'}
                >
                  <StyledMemberImage
                    size={isMobile ? '128px' : '200px'}
                    alt=""
                    src={image}
                  />
                  <Text
                    m="30px 0 5px 0"
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                    letterSpacing="0.62px"
                  >
                    {name}
                  </Text>
                  <Text
                    w="100%"
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    letterSpacing="0.62px"
                  >
                    {role}
                  </Text>
                </FlexColumn>
              </>
            ))}
          </FlexRow>

          <FlexRow w="100%" gap="20px" flexWrap="wrap" justifyContent="center">
            {teamMembers
              .slice(2, teamMembers.length - 2)
              .map(({ image, role, name }, i) => (
                <>
                  <FlexColumn
                    w={isMobile ? '40%' : '20%'}
                    alignItems="center"
                    m={'0 10px 65px 10px'}
                  >
                    <StyledMemberImage
                      size={isMobile ? '128px' : '200px'}
                      alt=""
                      src={image}
                    />
                    <Text
                      m="30px 0 5px 0"
                      size="22px"
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                      letterSpacing="0.62px"
                    >
                      {name}
                    </Text>
                    <Text
                      w="100%"
                      size="22px"
                      type={TEXT_TYPES.text}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.62px"
                    >
                      {role}
                    </Text>
                  </FlexColumn>
                </>
              ))}
          </FlexRow>

          <FlexRow w="100%" gap="20px" flexWrap="wrap" justifyContent="center">
            {teamMembers
              .slice(teamMembers.length - 2)
              .map(({ image, role, name }, i) => (
                <>
                  <FlexColumn
                    w={isMobile ? '40%' : '20%'}
                    alignItems="center"
                    m={'0 10px 65px 10px'}
                  >
                    <StyledMemberImage
                      size={isMobile ? '128px' : '200px'}
                      alt=""
                      src={image}
                    />
                    <Text
                      m="30px 0 5px 0"
                      size="22px"
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.bolder}
                      textAlign="center"
                      letterSpacing="0.62px"
                    >
                      {name}
                    </Text>
                    <Text
                      w="100%"
                      size="22px"
                      type={TEXT_TYPES.text}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.62px"
                    >
                      {role}
                    </Text>
                  </FlexColumn>
                </>
              ))}
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
