import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { FC, useState, useCallback } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  links: { title: string; path: string }[];
}

export const Dropdown: FC<Props> = ({ title, links }) => {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);

  const toggleOpenedState = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened],
  );

  return (
    <FlexColumn w="100%">
      <FlexRow
        w="calc(100% - 15px)"
        justifyContent="space-between"
        alignItems="center"
        onClick={toggleOpenedState}
        style={{ cursor: 'pointer' }}
      >
        <Text
          m="17px 10px"
          type={TEXT_TYPES.text}
          color={!isOpened ? COLORS.white : COLORS.lightBlue}
          weight={TEXT_WEIGHTS.bolder}
          textTransform="uppercase"
        >
          {title}
        </Text>
        {!isOpened ? (
          <HiOutlineChevronDown color={COLORS.white} />
        ) : (
          <HiOutlineChevronUp color={COLORS.white} />
        )}
      </FlexRow>
      {isOpened && (
        <FlexColumn w="100%">
          {links?.map(({ title, path }) => (
            <FlexColumn
              w="calc(100% - 20px)"
              bg={COLORS.lightBlue}
              p="10px"
              onClick={() => navigate(path, { replace: true })}
              alignItems="flex-start"
              style={{ cursor: 'pointer' }}
            >
              <FlexColumn w="100%" alignItems="flex-start">
                <Text
                  w="100%"
                  type={TEXT_TYPES.text}
                  color={COLORS.darkBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  textTransform="uppercase"
                >
                  {title}
                </Text>
              </FlexColumn>
            </FlexColumn>
          ))}
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
