import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  Section,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlexRow,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { GetInTouchForm } from 'modules/ui/components/Forms/GetInTouchForm/GetInTouchForm';
import { wordMarker } from 'modules/utils/wordMarker';
import { useMediaQuery } from 'react-responsive';
import { CustomCalendar } from './Calendar';
import { StyledFormWrapper } from './styled';

export const LetsTalkSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: 1100px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn w="100%" h="100%" alignItems="center" justifyContent="center">
        <FlexColumn w="80%" h="80%" alignItems="center" m="40px 0">
          <Text
            m="30px 0"
            size={TEXT_SIZES.xl}
            type={TEXT_TYPES.title}
            color={COLORS.lightBlue}
            weight={TEXT_WEIGHTS.bolder}
            letterSpacing="1.05px"
          >
            {t('contact.letsTalkSection.title')}
          </Text>
          <Text
            size="25px"
            color={COLORS.white}
            weight={TEXT_WEIGHTS.tiny}
            letterSpacing="0.75px"
            lineHeight="35px"
            textAlign="center"
            whiteSpace="pre-wrap"
          >
            {t('contact.letsTalkSection.text')}
          </Text>
          <FlexRow
            w="100%"
            justifyContent="center"
            m="80px 0"
            alignItems="center"
            flexDirection={isTablet ? 'column !important' : 'row'}
          >
            <FlexColumn w="45%" alignItems="center" m="0 75px">
              <FlexColumn h="150px" justifyContent="center">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img
                    alt="get-in-touch"
                    src="./assets/utils/message-sign.png"
                  />
                </Animatable>
              </FlexColumn>
              <Text
                m="20px 0"
                size="40px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.tiny}
                letterSpacing="1.2px"
                textAlign="center"
              >
                {t('contact.letsTalkSection.cards.0.title')}
              </Text>
              <Text
                size={'17px'}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
                lineHeight="19px"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: wordMarker(
                      t('contact.letsTalkSection.cards.0.text'),
                      'Lab K19',
                    ),
                  }}
                />
              </Text>
              <StyledFormWrapper w="100%" m="30px 0">
                <GetInTouchForm />
              </StyledFormWrapper>
            </FlexColumn>
            <FlexColumn w="40%" alignItems="center" m="0 75px">
              <FlexColumn h="150px" justifyContent="center">
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <img alt="book-a-call" src="./assets/utils/call-sign.png" />
                </Animatable>
              </FlexColumn>
              <Text
                m="20px 0"
                size="40px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.tiny}
                letterSpacing="1.2px"
                textAlign="center"
              >
                {t('contact.letsTalkSection.cards.1.title')}
              </Text>
              <Text
                size={'17px'}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
                lineHeight="19px"
              >
                {t('contact.letsTalkSection.cards.1.text')}
              </Text>
              <StyledFormWrapper m="30px 0">
                <CustomCalendar />
              </StyledFormWrapper>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
