import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  Logo,
  TEXT_WEIGHTS,
  Text,
  FlexRow,
} from 'modules/ui/components';
import { Dropdown } from './Dropdown';
import { Divider } from './styled';
import { linksCategories, linksSets, socialFooterLinks } from './utils';

export const MobileFooter = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.COMPONENTS);

  return (
    <FlexColumn
      w="100%"
      alignItems="center"
      bg={COLORS.darkBlue}
      p="40px  0 100px 0"
    >
      <FlexColumn w="80%" alignItems="center">
        <Logo w={177} h={51} />
        <FlexColumn m="40px 0" gap="8px" alignItems="center">
          <Text size="17px" color={COLORS.white} weight={TEXT_WEIGHTS.tiny}>
            Unter den Linden 10
          </Text>
          <Text size="17px" color={COLORS.white} weight={TEXT_WEIGHTS.tiny}>
            10117, Berlin
          </Text>
          <Text size="17px" color={COLORS.white} weight={TEXT_WEIGHTS.tiny}>
            +49 (0) 30 700 140 296
          </Text>
          <Text size="17px" color={COLORS.white} weight={TEXT_WEIGHTS.tiny}>
            support@labk19.com
          </Text>
          <Text size="17px" color={COLORS.white} weight={TEXT_WEIGHTS.bolder}>
            labk19.com
          </Text>
        </FlexColumn>
        <Divider m="0 0 50px 0" />
        {linksCategories.map((_, i) => (
          <Dropdown
            title={t(`footer.links.${i}.title`)}
            links={linksSets[i].map((path, index) => ({
              path,
              title: t(`footer.links.${i}.items.${index}.title`),
            }))}
          />
        ))}
        <Divider m="30px 0" />
        <FlexRow gap="30px">
          {socialFooterLinks.map(({ src, link }, i) => (
            <img
              style={{ cursor: 'pointer' }}
              src={src}
              alt={`footer-social-${i}`}
              onClick={() => window.open(link)}
            />
          ))}
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};
