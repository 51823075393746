import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { FC, useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MobileSlider } from '../Slider/Slider.mobile';
import { Tab } from './styled';
import { sliderMobileStyles } from './utils';

interface Props {
  tabs: { title: string; content: React.ReactNode }[];
  tabsFontSize?: string;
  tabsHeight?: string;
  tabsPadding?: string;
}

export const TabsSection: FC<Props> = ({
  tabs,
  tabsFontSize = '30px',
  tabsPadding = '15px',
  tabsHeight = 'auto',
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const isTablet = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.tablet})`,
  });

  const onActionHover = useCallback(
    (indx: number) => setCurrentTabIndex(+indx),
    [],
  );

  if (isTablet) {
    return (
      <MobileSlider
        styles={sliderMobileStyles}
        slides={tabs.map(({ title }, i) => (
          <FlexColumn
            w="100%"
            alignItems="center"
            justifyContent="center"
            gap="70px !important"
          >
            <Tab p="10px">
              <Text
                size={tabsFontSize}
                type={TEXT_TYPES.text}
                color={COLORS.lightBlue}
                textAlign="center"
                weight={TEXT_WEIGHTS.bolder}
              >
                {title}
              </Text>
            </Tab>
            {tabs[i].content}
          </FlexColumn>
        ))}
      />
    );
  }

  return (
    <FlexColumn w="80%" alignItems="center">
      <FlexRow
        w={!isTablet ? '100%' : 'auto'}
        alignItems="center"
        justifyContent="center"
        gap="16px"
        flexWrap={!isTablet ? 'none' : 'wrap'}
      >
        {tabs.map(({ title }, i) => (
          <Tab
            w="auto"
            m="20px 0"
            justifyContent="center"
            p={tabsPadding}
            h={tabsHeight}
            bg={
              currentTabIndex === i
                ? `${COLORS.lightBlue} !important`
                : ' rgba(140, 255, 255, 0.1)'
            }
            onMouseOver={() => onActionHover(i)}
          >
            <Text
              size={tabsFontSize}
              type={TEXT_TYPES.text}
              color={
                currentTabIndex === i
                  ? `${COLORS.darkBlue} !important`
                  : COLORS.lightBlue
              }
              textAlign="center"
              weight={TEXT_WEIGHTS.bolder}
            >
              {title}
            </Text>
          </Tab>
        ))}
      </FlexRow>
      {tabs[currentTabIndex].content}
    </FlexColumn>
  );
};
