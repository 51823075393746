import { motion, useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import { FlexColumn } from './Flex';

export const enum ANIMATIONS_TYPES {
  slideLeft = 'slide-left',
  slideRight = 'slide-right',
  appendTop = 'append-top',
  appendBottom = 'append-bottom',
}

export const animations = {
  [ANIMATIONS_TYPES.slideLeft]: {
    transition: { duration: 1 },
    animate: { x: 0 },
    initial: { x: '-70%' },
  },
  [ANIMATIONS_TYPES.slideRight]: {
    transition: { duration: 1 },
    animate: { x: 0 },
    initial: { x: '70%' },
  },
  [ANIMATIONS_TYPES.appendTop]: {
    transition: { duration: 1 },
    animate: { y: 0, opacity: 1 },
    initial: { y: '-40%', opacity: 0 },
  },
  [ANIMATIONS_TYPES.appendBottom]: {
    transition: { duration: 1 },
    animate: { y: 0, opacity: 1 },
    initial: { y: '40%', opacity: 0 },
  },
};

interface Props {
  animationType: ANIMATIONS_TYPES;
  children: React.ReactNode;
}

export const Animatable: FC<Props> = ({ animationType, children }) => {
  const visibilityRef = useRef(null);
  const isInView = useInView(visibilityRef, { once: true });

  return (
    <FlexColumn
      w="100%"
      as={isInView ? motion.div : ''}
      ref={visibilityRef}
      {...animations[animationType]}
    >
      {children}
    </FlexColumn>
  );
};
