import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  w?: number;
  h?: number;
  isClickable?: boolean;
  className?: string;
}

export const Logo: FC<Props> = ({
  w = 147,
  h = 48,
  isClickable,
  className,
}) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    () => isClickable && navigate('/', { replace: true }),
    [isClickable, navigate],
  );

  return (
    <img
      style={{ cursor: isClickable ? 'pointer' : 'auto' }}
      width={w}
      height={h}
      alt="logo"
      src="./assets/logos/logo.svg"
      onClick={onClick}
      className={className || ''}
    />
  );
};
