export const technologies = [
  {
    title: 'Front-end',
    items: ['Typescript', 'React', 'Angular', 'Vue', 'Next.js'],
    imgSrc: './assets/technologies/frontend.png',
  },
  {
    title: 'Back-end',
    items: ['Python', 'Nest.js', 'Node.js', 'Deno', 'Rust'],
    imgSrc: './assets/technologies/backend.png',
  },
  {
    title: 'Mobile',
    items: ['Android', 'iOS', 'Ionic', 'React Native'],
    imgSrc: './assets/technologies/mobile.png',
  },
  {
    title: 'TV',
    items: ['Tizen', 'Roku TV', 'webOS', 'Android', 'iOS'],
    imgSrc: './assets/technologies/tv.png',
  },
  {
    title: 'ML & AI',
    items: ['Tensorflow', 'PyTorch', 'OpenCV', 'Kubeflow'],
    imgSrc: './assets/technologies/ai.png',
  },
  {
    title: 'Infrastructure',
    items: ['Aws', 'Azure', 'GCP', 'Digital Ocean', 'Kubernetes', 'Bare Metal'],
    imgSrc: './assets/technologies/infrastructure.png',
  },
  {
    title: 'Database',
    items: ['PostgreSQL', 'MySQL', 'MongoDB', 'Firestore', 'BigQuery'],
    imgSrc: './assets/technologies/database.png',
  },
];
