import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopAboutUs } from './AboutUs.desktop';
import { MobileAboutUs } from './AboutUs.mobile';

export const AboutUs = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileAboutUs />;

  return <DesktopAboutUs />;
};
