export const teamMembers = [
  {
    name: 'Gianmarco Ortolani',
    role: 'CEO',
    image: './assets/team/member-0.png',
  },
  { name: 'Abdul Masood', role: 'CTO', image: './assets/team/member-1.jpg' },
  {
    name: 'Stefano Longo',
    role: 'HEAD OF UX/UI',
    image: './assets/team/member-2.png',
  },
  {
    name: 'Miriam Tognella',
    role: 'HEAD OF BRANDING',
    image: './assets/team/member-3.jpg',
  },
  {
    name: 'Ginevra Berti',
    role: 'HEAD OF VIDEO PRODUCTION',
    image: './assets/team/member-4.jpg',
  },
  {
    name: 'Adeel Masood',
    role: 'HEAD OF DEVELOPMENT',
    image: './assets/team/member-5.jpg',
  },
  {
    name: 'Adal Muller',
    role: 'HEAD OF DELIVERY',
    image: './assets/team/member-6.jpg',
  },
  {
    name: 'Peter Maywald',
    role: 'HEAD OF BUSINESS',
    image: './assets/team/member-11.png',
  },
  {
    name: 'Fabrizzio Minarelli',
    role: 'BUSINESS DEVELOPMENT',
    image: './assets/team/member-7.jpg',
  },
  {
    name: 'Harikrushna Dhanani',
    role: 'HEAD OF MOBILE',
    image: './assets/team/member-8.jpg',
  },
  {
    name: 'Anna Crucitti',
    role: 'LEGAL COUNSEL (Italy)',
    image: './assets/team/member-9.jpg',
  },
  {
    name: 'Daniel Streiff',
    role: 'LEGAL COUNSEL (Germany)',
    image: './assets/team/member-10.jpg',
  },
];
