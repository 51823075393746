import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { MobileSlider } from 'modules/sections/components/Slider/Slider.mobile';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  TEXT_SIZES,
  FlotableElement,
  Square,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import {
  subCards,
  benefitsPoints,
  whenToUsePoints,
  sliderMobileStyles,
} from './utils';

export const MobileIntegration = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('integration.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="35px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('integration.cards.0.title')}
            </Text>
            <Text
              w="100%"
              size="28px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="40px"
            >
              {t('integration.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0 170px 0">
            <img
              src="./assets/integration/card-0.png"
              alt="story"
              width={141}
              height={143}
            />
          </FlexRow>
          <FlexColumn w="100%" alignItems="center">
            <MobileSlider
              styles={sliderMobileStyles}
              slides={subCards.map((_, i) => (
                <FlexColumn w="100%" alignItems="center">
                  <FlexColumn w="100%" alignItems="center">
                    <Text
                      m="0px 0 15px 0"
                      size="30px"
                      letterSpacing="1.4px"
                      type={TEXT_TYPES.title}
                      color={COLORS.lightBlue}
                      weight={TEXT_WEIGHTS.bolder}
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {t(`integration.subCards.${i}.title`)}
                    </Text>
                    <Text
                      w="100%"
                      size={TEXT_SIZES.s}
                      type={TEXT_TYPES.text}
                      color={COLORS.white}
                      weight={TEXT_WEIGHTS.tiny}
                      textAlign="center"
                      letterSpacing="0.56px"
                      whiteSpace="pre-wrap"
                    >
                      {t(`integration.subCards.${i}.text`)}
                    </Text>
                  </FlexColumn>
                </FlexColumn>
              ))}
            />
          </FlexColumn>
        </FlexColumn>
        <FlexColumn w="80%" alignItems="center" m="90px 0 0 0">
          <img
            src="./assets/integration/benefits.png"
            alt="benefits"
            width={106}
            height={135}
          />
          <FlexColumn w="100%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center" m="0 0 37px 0">
                <Text
                  w="100%"
                  size="30px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  letterSpacing="1.2px"
                  lineHeight="32px"
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t('integration.benefits.title')}
                </Text>
              </FlexColumn>
            </Animatable>
            {benefitsPoints.map((_, i) => (
              <FlexRow gap="10px" m="0 0 25px 0">
                <img
                  src="./assets/utils/check-sign.png"
                  alt="checkbox"
                  style={{ margin: '3px 0 0 0' }}
                />
                <FlexColumn>
                  <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                    <FlexColumn w="100%">
                      <Text
                        size="22px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        lineHeight="26px"
                      >
                        {t(`integration.benefits.points.${i}.title`)}
                      </Text>
                      <Text
                        size="22px"
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        whiteSpace="pre-wrap"
                        letterSpacing="0.62px"
                        lineHeight="25px"
                      >
                        {t(`integration.benefits.points.${i}.text`)}
                      </Text>
                    </FlexColumn>
                  </Animatable>
                </FlexColumn>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="0px 0 100px 0"
          m="100px 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <FlexColumn w="80%" alignItems="center">
            <FlexColumn w="100%" alignItems="center">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <img
                    src="./assets/integration/whenToUse.png"
                    alt="story"
                    width={104}
                    height={140}
                  />
                  <Text
                    m="20px 0 10px 30px"
                    size="35px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    letterSpacing="1.4px"
                    lineHeight="35px"
                    whiteSpace="pre-wrap"
                  >
                    {t('integration.whenToUse.title')}
                  </Text>
                  <Text
                    m="30px 0 25px 30px"
                    size="22px"
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                    letterSpacing="0.62px"
                    lineHeight="25px"
                    textAlign="center"
                  >
                    {t(`integration.whenToUse.subTitle`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
            {whenToUsePoints.map((_, i) => (
              <FlexRow gap="15px" m="0 0 25px 0">
                <img
                  src="./assets/utils/check-sign.png"
                  alt="checkbox"
                  style={{ margin: '3px 0 0 0' }}
                />
                <FlexColumn>
                  <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                    <FlexColumn w="100%" alignItems="center">
                      <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                        <FlexColumn w="100%">
                          <Text
                            size="22px"
                            type={TEXT_TYPES.text}
                            color={COLORS.white}
                            weight={TEXT_WEIGHTS.tiny}
                            whiteSpace="pre-wrap"
                            letterSpacing="0.62px"
                            lineHeight="25px"
                          >
                            {t(`integration.whenToUse.points.${i}`)}
                          </Text>
                        </FlexColumn>
                      </Animatable>
                    </FlexColumn>
                  </Animatable>
                </FlexColumn>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="17%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
