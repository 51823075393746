import styled from 'styled-components';

export const enum COLORS {
  lightBlue = '#aeedee',
  darkBlue = '#272937',
  darkTeal = '#8CFFFF27',
  white = '#ffff',
  yellow = '#f9ee50',
  black = '#00000',
}

interface DefaultElementProps {
  w?: string;
  h?: string;
  m?: string;
  mh?: string;
  p?: string;
  bg?: string;
  bgSize?: string;
  bgPosition?: string;
  position?: string;
  zIndex?: string;
}

interface FlexProps {
  alignItems?: string;
  justifyContent?: string;
  alignSelf?: string;
  justifySelf?: string;
  flexWrap?: string;
  gap?: string;
  flexDirection?: string;
}

const Flex = styled.div<FlexProps & DefaultElementProps>`
  display: flex;
  width: ${(props) => props.w || 'auto'};
  height: ${(props) => props.h || 'auto'};
  margin: ${(props) => props.m || '0'};
  min-height: ${(props) => props.mh || 'auto'};
  padding: ${(props) => props.p || '0'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-self: ${(props) => props.alignSelf || 'none'};
  justify-self: ${(props) => props.justifySelf || 'none'};
  flex-wrap: ${(props) => props.flexWrap || 'none'};
  ${({ gap }) => gap && `gap: ${gap};`};
  ${({ position }) => position && `position: ${position}`};
  background: ${({ bg }) => bg || 'none'};
  background-size: ${({ bgSize }) => bgSize || 'auto'};
  z-index: ${({ zIndex }) => zIndex || 'auto'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;
