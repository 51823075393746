import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { GetInTouchSection } from 'modules/sections/components';
import { TabsSection } from 'modules/sections/components/TabsSection/TabsSection';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlotableElement,
  Square,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useMediaQuery } from 'react-responsive';
import { subCards, tabs } from './utils';

export const DesktopAI = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isBigTablet = useMediaQuery({
    query: `(max-width: ${1130}px)`,
  });

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('ai.title')} />
      <FlexColumn
        w="100%"
        alignItems="center"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="80%" alignItems="center">
          <FlexColumn w="100%" alignItems="center">
            <Text
              m="70px 0 26px 0"
              size="35px"
              type={TEXT_TYPES.title}
              color={COLORS.lightBlue}
              weight={TEXT_WEIGHTS.bolder}
              letterSpacing="1.4px"
              lineHeight="35px"
              textAlign="center"
            >
              {t('ai.cards.0.title')}
            </Text>
            <Text
              w="70%"
              size="25px"
              type={TEXT_TYPES.text}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              textAlign="center"
              whiteSpace="pre-wrap"
              letterSpacing="0.88px"
              lineHeight="30px"
            >
              {t('ai.cards.0.text')}
            </Text>
          </FlexColumn>
          <FlexRow m="100px 0">
            <img src="./assets/ai/ai.png" alt="story" />
          </FlexRow>
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0 26px 0"
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.4px"
                lineHeight="35px"
                textAlign="center"
              >
                {t('ai.cards.1.title')}
              </Text>
              <Text
                w="70%"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
                letterSpacing="0.88px"
                lineHeight="30px"
              >
                {t('ai.cards.1.text')}
              </Text>
            </FlexColumn>
          </Animatable>
          <FlexRow m="120px 0" w="100%" justifyContent="center" flexWrap="wrap">
            {subCards.map((_, i) => (
              <FlexColumn
                w={!isBigTablet ? '30%' : '100%'}
                alignItems="center"
                m="100px 20px"
              >
                <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                  <FlexColumn w="100%" alignItems="center">
                    <img src={`./assets/ai/subCard-${i}.png`} alt="sub-card" />
                    <FlexColumn w="100%" alignItems="center">
                      <Text
                        m="30px 0 10px 0"
                        size="30px"
                        letterSpacing="1.4px"
                        type={TEXT_TYPES.title}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        textAlign="center"
                      >
                        {t(`ai.subCards.${i}.title`)}
                      </Text>
                      <Text
                        w="80%"
                        size={TEXT_SIZES.s}
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        textAlign="center"
                        letterSpacing="0.56px"
                        lineHeight="30px"
                        whiteSpace="pre-wrap"
                      >
                        {t(`ai.subCards.${i}.text`)}
                      </Text>
                    </FlexColumn>
                  </FlexColumn>
                </Animatable>
              </FlexColumn>
            ))}
          </FlexRow>
          <FlexRow
            m="150px 0"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
            gap="100px"
            flexDirection={isBigTablet ? 'column !important' : 'row'}
          >
            <img src="./assets/ai/technologies.png" alt="story" />
            <FlexColumn w="60%">
              <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                <FlexColumn w="100%" alignItems="center">
                  <Text
                    w="100%"
                    m="20px 0"
                    size="35px"
                    letterSpacing="1.4px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    whiteSpace="pre-wrap"
                  >
                    {t(`ai.cards.1.title`)}
                  </Text>
                  <Text
                    m="15px 0"
                    size={TEXT_SIZES.s}
                    type={TEXT_TYPES.text}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="0.56px"
                    lineHeight="25px"
                    whiteSpace="pre-wrap"
                  >
                    {t(`ai.cards.1.text`)}
                  </Text>
                </FlexColumn>
              </Animatable>
            </FlexColumn>
          </FlexRow>
          <FlexColumn w="100%" alignItems="center">
            <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
              <FlexColumn w="100%" alignItems="center">
                <Text
                  m="20px 0"
                  size="35px"
                  letterSpacing="1.4px"
                  type={TEXT_TYPES.title}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`ai.cards.2.title`)}
                </Text>
                <Text
                  w="80%"
                  m="15px 0"
                  size={TEXT_SIZES.s}
                  type={TEXT_TYPES.text}
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.56px"
                  lineHeight="25px"
                  whiteSpace="pre-wrap"
                  textAlign="center"
                >
                  {t(`ai.cards.2.text`)}
                </Text>
              </FlexColumn>
            </Animatable>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="120px 0 0 0"
          alignItems="center"
          justifyContent="center"
          bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.1))`}
        >
          <TabsSection
            tabsPadding="10px"
            tabsFontSize="20px"
            tabs={tabs.map((points, i) => ({
              title: t(`ai.tiles.${i}.title`),
              content: (
                <FlexRow
                  w="80%"
                  flexDirection={isBigTablet ? 'column !important' : 'row'}
                  alignItems="center"
                  gap="70px"
                >
                  <img src={`./assets/ai/tile-${i}.png`} alt="sub-card" />
                  <FlexColumn
                    w="70%"
                    h="600px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {points.map((_: string, pointIndex: number) => (
                      <FlexRow w="80%" m="10px 0" gap="14px">
                        <img
                          style={{ margin: '6px 0' }}
                          src={`./assets/utils/check-sign.png`}
                          alt="sub-card"
                        />
                        <Text
                          size={TEXT_SIZES.s}
                          type={TEXT_TYPES.text}
                          color={COLORS.white}
                          weight={TEXT_WEIGHTS.tiny}
                          letterSpacing="0.56px"
                          lineHeight="25px"
                          whiteSpace="pre-wrap"
                        >
                          {t(`ai.tiles.${i}.points.${pointIndex}`)}
                        </Text>
                      </FlexRow>
                    ))}
                  </FlexColumn>
                </FlexRow>
              ),
            }))}
          />
        </FlexColumn>
        <GetInTouchSection title={t(`tv.contactSection.title`) as string} />
        <FlotableElement left="87%" top="-8%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
        <FlotableElement left="-20%" top="5%">
          <Square zIndex="1" size="30vw" />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
