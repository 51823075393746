export const sliderCards = [
  [
    './assets/services/service-3.svg',
    './assets/services/service-1.svg',
    './assets/services/service-2.svg',
  ],
  [
    './assets/services/service-4.svg',
    './assets/services/service-5.svg',
    './assets/services/service-6.svg',
  ],
];

export const sliderMobileCards = [
  './assets/services/service-3.svg',
  './assets/services/service-2.svg',
  './assets/services/service-1.svg',
  './assets/services/service-4.svg',
  './assets/services/service-5.svg',
  './assets/services/service-6.svg',
];

export const sliderLinksMobile = [
  '/media-platform',
  '/video-streaming',
  '/digital-product',
  '/ai',
  '/web',
  '/ux-&-ui-design',
];

export const sliderLinks = [
  ['/media-platform', '/video-streaming', '/digital-product'],
  ['/ai', '/web', '/ux-&-ui-design'],
];

export const sliderLinksTablet = [
  ['/media-platform', '/video-streaming'],
  ['/ai', '/web'],
  ['/digital-product', '/ux-&-ui-design'],
];

export const sliderTabletCards = [
  ['./assets/services/service-3.svg', './assets/services/service-1.svg'],
  ['./assets/services/service-4.svg', './assets/services/service-5.svg'],
  ['./assets/services/service-2.svg', './assets/services/service-6.svg'],
];

export const sliderToTextMap: Record<string, string> = {
  './assets/services/service-3.svg': '0',
  './assets/services/service-2.svg': '2',
  './assets/services/service-1.svg': '1',
  './assets/services/service-4.svg': '3',
  './assets/services/service-5.svg': '4',
  './assets/services/service-6.svg': '5',
};
