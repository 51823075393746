import React, { FC } from 'react';
import { FlexColumn } from '../Flex';
import { StyledLight } from './styled';
import { spinningAnimation } from './utils';

interface Props {
  h: string;
  direction?: 'left' | 'right';
}

export const SpinningLight: FC<Props> = ({ direction = 'left' }) => {
  return (
    <FlexColumn
      w="10px"
      h="100%"
      alignItems="center"
      justifyContent="flex-start"
      position="absolute"
      zIndex="1"
      {...spinningAnimation[direction]}
    >
      <StyledLight />
    </FlexColumn>
  );
};
