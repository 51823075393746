import { FlexRow } from 'modules/ui/components';
import styled from 'styled-components';

export const VideoBackDrop = styled(FlexRow)<{
  visibility: 'hidden' | 'visible';
}>`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(38, 41, 56, 0.7);
  align-items: center;
  justify-content: center;
  width: 100%;

  transition: 0.3s all;
  visibility: ${({ visibility }) => visibility || 'visible'};
  z-index: 5;
`;
