import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { CustomCalendar } from 'modules/sections/components/LetsTalkSection/Calendar';
import { StyledMobileWrapper } from 'modules/sections/components/LetsTalkSection/styled';
import { OurOficesSection } from 'modules/sections/components/OurOficesSection/OurOficesSection';
import {
  COLORS,
  FlexColumn,
  Section,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
} from 'modules/ui/components';
import { GetInTouchForm } from 'modules/ui/components/Forms/GetInTouchForm/GetInTouchForm';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { wordMarker } from 'modules/utils/wordMarker';

export const MobileContact = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('contact.title')} />
      <FlexColumn
        w="100%"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <Section h="auto">
          <FlexColumn
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
          >
            <FlexColumn w="80%" h="80%" alignItems="center" m="40px 0">
              <Text
                m="10px 0"
                size="58px"
                w="100%"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                letterSpacing="1.05px"
                textAlign="center"
              >
                {t('contact.letsTalkSection.title')}
              </Text>
              <Text
                size="30px"
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                letterSpacing="0.75px"
                lineHeight="35px"
                textAlign="center"
                whiteSpace="pre-wrap"
              >
                {t('contact.letsTalkSection.text')}
              </Text>
              <FlexColumn
                w="100%"
                alignItems="center"
                justifyContent="center"
                m="80px 0"
              >
                <FlexColumn w="80%" alignItems="center">
                  <FlexColumn
                    h="150px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      alt="get-in-touch"
                      src="./assets/utils/message-sign.png"
                    />
                  </FlexColumn>
                  <Text
                    m="20px 0"
                    size="40px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="1.2px"
                    textAlign="center"
                  >
                    {t('contact.letsTalkSection.cards.0.title')}
                  </Text>
                  <Text
                    size="17px"
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                    lineHeight="19px"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: wordMarker(
                          t('contact.letsTalkSection.cards.0.text'),
                          'Lab K19',
                        ),
                      }}
                    />
                  </Text>
                  <StyledMobileWrapper w="100%" m="30px 0">
                    <GetInTouchForm />
                  </StyledMobileWrapper>
                </FlexColumn>
                <FlexColumn w="100%" alignItems="center">
                  <FlexColumn
                    h="150px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img alt="book-a-call" src="./assets/utils/call-sign.png" />
                  </FlexColumn>
                  <Text
                    m="20px 0"
                    size="40px"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="1.2px"
                    textAlign="center"
                  >
                    {t('contact.letsTalkSection.cards.1.title')}
                  </Text>
                  <Text
                    size={'17px'}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.tiny}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                    lineHeight="19px"
                  >
                    {t('contact.letsTalkSection.cards.1.text')}
                  </Text>
                  <StyledMobileWrapper m="30px 0" p="30px">
                    <CustomCalendar />
                  </StyledMobileWrapper>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </Section>
        <OurOficesSection />
      </FlexColumn>
    </FlexColumn>
  );
};
