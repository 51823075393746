import { useInView } from 'framer-motion';
import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import {
  FlexColumn,
  TEXT_SIZES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  Text,
} from 'modules/ui/components';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useRef } from 'react';
import CountUp from 'react-countup';
import { useMediaQuery } from 'react-responsive';
import { Settings } from 'react-slick';
import { SlickArrowRight, SlickArrowLeft } from '../TopServices/Slider';
import { StyledSlider } from './styled';

import { agileInNumbersCards } from './utils';

const sliderSettings: Settings = {
  dots: false,
  infinite: true,
  draggable: true,
  touchMove: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  centerMode: true,
};

export const AgileNumbers = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  const container = useRef(null);
  const isInView = useInView(container, { once: true });

  return (
    <FlexColumn
      w="100%"
      h="auto"
      m={isMobile ? '0' : '50px'}
      alignItems="center"
      p="20px"
      bg="rgba(138, 245, 245, 0.15)"
      position="static "
    >
      <Text
        m="30px 0"
        size={TEXT_SIZES.l}
        color={COLORS.white}
        weight={TEXT_WEIGHTS.bolder}
        textAlign="center"
      >
        {t('howWeWork.agileApproachSection.numbersBlock.title')}
      </Text>
      {!isMobile && (
        <FlexRow
          w="80%"
          m="20px"
          p="0 30px"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={isTablet ? 'wrap' : 'nowrap'}
          ref={container}
        >
          {agileInNumbersCards.map((title, i) => (
            <FlexColumn
              w={isTablet ? '50%' : '20%'}
              h="200px"
              alignItems="center"
            >
              {isInView && (
                <Text
                  m="0px 0"
                  size={TEXT_SIZES.mega}
                  color={COLORS.lightBlue}
                  weight={TEXT_WEIGHTS.bolder}
                  textAlign="center"
                >
                  <CountUp
                    duration={1}
                    start={0}
                    end={+title.replace('%', '')}
                  />
                  {title[title.length - 1]}
                </Text>
              )}
              <Text
                m="0px 0"
                size={TEXT_SIZES.xl}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
              >
                {t(`howWeWork.agileApproachSection.numbersBlock.texts.${i}`)}
              </Text>
            </FlexColumn>
          ))}
        </FlexRow>
      )}

      {isMobile && (
        <StyledSlider {...sliderSettings}>
          {agileInNumbersCards.map((title, i) => (
            <FlexRow w="100%" h="100%" className="slide-wrapper">
              <FlexColumn w="100%" h="100%">
                <FlexColumn w="100%" h="200px" alignItems="center">
                  <Text
                    m="0px 0"
                    size="70px"
                    color={COLORS.lightBlue}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    <CountUp
                      duration={1}
                      start={0}
                      end={+title.replace('%', '')}
                    />
                    {title[title.length - 1]}
                  </Text>

                  <Text
                    m="0px 0"
                    size={TEXT_SIZES.xl}
                    color={COLORS.white}
                    weight={TEXT_WEIGHTS.bolder}
                    textAlign="center"
                  >
                    {t(
                      `howWeWork.agileApproachSection.numbersBlock.texts.${i}`,
                    )}
                  </Text>
                </FlexColumn>
              </FlexColumn>
            </FlexRow>
          ))}
        </StyledSlider>
      )}
    </FlexColumn>
  );
};
