import React, { FC } from 'react';
import { COLORS, FlexColumn, FlexRow } from '../Flex';
import { FlotableElement } from '../FlotableElement';
import { Square } from '../Square';
import { SpinningLight } from './SpinningLight';
import { StyledLight } from './styled';
import { lightsAnimations } from './utils';

interface Props {
  w: string;
  h: string;
  children?: React.ReactNode;
}

export const AnimatedBackground: FC<Props> = ({ w, h, children = [] }) => {
  return (
    <FlexRow
      w={w}
      h={h}
      bg={COLORS.lightBlue}
      position="relative"
      style={{ overflow: 'hidden' }}
    >
      <FlotableElement top="0%" left="0" w="100%" h="100%">
        <FlexRow
          w="100%"
          h="100%"
          bg="linear-gradient(180deg, rgba(39, 41, 55, 0.7) 40%, rgba(39, 41, 55, 1))"
          style={{ zIndex: '1' }}
        ></FlexRow>
      </FlotableElement>
      <FlotableElement
        top="0%"
        left="-25%"
        w={`${window.innerWidth / 2}px`}
        h={`${window.innerWidth / 2}px`}
        style={{ zIndex: 'initial' }}
      >
        <Square
          size="100%"
          bg={COLORS.darkBlue}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <StyledLight {...lightsAnimations[0]} />
        </Square>
      </FlotableElement>
      <FlotableElement
        top="-25%"
        left="50%"
        w={`${window.innerWidth / 1.5}px`}
        h={`${window.innerWidth / 1.5}px`}
      >
        <Square size="100%" bg={COLORS.white}>
          <StyledLight {...lightsAnimations[1]} />
        </Square>
      </FlotableElement>
      <FlotableElement
        top="80%"
        left="60%"
        w={`${window.innerWidth / 3}px`}
        h={`${window.innerWidth / 3}px`}
      >
        <Square size="100%" bg={COLORS.darkBlue} />
      </FlotableElement>
      <FlotableElement
        top="65%"
        left="50%"
        w={`${window.innerWidth / 2.5}px`}
        h={`${window.innerWidth / 2.5}px`}
      >
        <Square size="100%" bg={COLORS.white}>
          <SpinningLight h={`${window.innerWidth / 2.5}px`} />
          <SpinningLight h={`${window.innerWidth / 2.5}px`} direction="right" />
        </Square>
      </FlotableElement>
      <FlexColumn w="100%" h="100%" position="absolute" zIndex="5">
        {children}
      </FlexColumn>
    </FlexRow>
  );
};
