import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopGetInTouchSection } from './GetInTouchSection.desktop';
import { MobileGetInTouchSection } from './GetInTouchSection.mobile';

interface Props {
  title?: string;
}

export const GetInTouchSection: FC<Props> = ({ title }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${550}px)`,
  });

  if (isMobile) return <MobileGetInTouchSection title={title} />;

  return <DesktopGetInTouchSection title={title} />;
};
