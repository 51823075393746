import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  COLORS,
  FlexColumn,
  Section,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  FlexRow,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';

export const AgileApproachSection = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${900}px)`,
  });

  return (
    <Section h="auto">
      <FlexColumn w="100%" h="100%" alignItems="center" justifyContent="center">
        <FlexColumn w="80%" h="80%" alignItems="center" justifyContent="center">
          <img src="./assets/utils/agile-sign.png" alt="agile" />
          <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0"
                size="35px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
              >
                {t('howWeWork.agileApproachSection.title')}
              </Text>
              <Text
                m="5px 0"
                size={TEXT_SIZES.l}
                type={TEXT_TYPES.title}
                color={COLORS.white}
                textAlign={isMobile || isTablet ? 'center' : 'start'}
                weight={TEXT_WEIGHTS.bolder}
              >
                {t('howWeWork.agileApproachSection.subTitle')}
              </Text>
              <Text
                m="30px 0"
                size={TEXT_SIZES.s}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
              >
                {t('howWeWork.agileApproachSection.texts.0')}
              </Text>
              <Text
                m="30px 0"
                size={TEXT_SIZES.s}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                whiteSpace="pre-wrap"
              >
                {t('howWeWork.agileApproachSection.texts.1')}
              </Text>
            </FlexColumn>
          </Animatable>
          <FlexRow m={`100px 0 ${isMobile ? '100px' : '20px'} 0`}>
            <img src="./assets/utils/settings-finger.png" alt="story" />
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Section>
  );
};
