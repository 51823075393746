import { motion } from 'framer-motion';

export const lightsAnimations = [
  {
    as: motion.div,
    animate: {
      x: [
        -window.innerWidth / 6,
        window.innerWidth / 6,
        window.innerWidth / 4.5,
      ],
      y: [
        -window.innerWidth / 4.5,
        -window.innerWidth / 4,
        window.innerWidth / 3.5,
      ],
      z: [-10, -10],
    },
    transition: { duration: 15, repeat: Infinity },
  },
  {
    as: motion.div,
    animate: {
      x: [
        window.innerWidth / 4.5,
        window.innerWidth / 4.5,
        -window.innerWidth / 2.5,
        -window.innerWidth / 2.5,
      ],
      y: [
        window.innerWidth / 3.4,
        window.innerWidth / 3.4,
        window.innerWidth / 3.4,
        -window.innerWidth / 2,
      ],
      z: [-10, -10],
      opacity: [0, 1, 0],
    },
    transition: { duration: 15, repeat: Infinity },
  },
  {
    as: motion.div,
    animate: {
      x: [
        window.innerWidth / 4,
        window.innerWidth / 4,
        -window.innerWidth / 3,
        -window.innerWidth / 10,
      ],
      y: [
        window.innerWidth / 4,
        window.innerWidth / 4,
        -window.innerWidth / 10,
        -window.innerWidth / 10,
      ],
      z: [-10, -10],
      opacity: [0, 1, 0],
      rotate: [-45, -45],
      width: [
        window.innerWidth / 4.5,
        window.innerWidth / 2.5,
        window.innerWidth / 4.5,
      ],
    },
    transition: { duration: 15, repeat: Infinity },
  },
];

export const spinningAnimation = {
  right: {
    as: motion.div,
    animate: {
      rotate: [-45, 180, 360, 180, -45],
      z: [-10, -10],
    },
    transition: { duration: 15, repeat: Infinity },
  },
  left: {
    as: motion.div,
    animate: {
      rotate: [-45, -180, -360, -180, -45],
      z: [-10, -10],
    },
    transition: { duration: 15, repeat: Infinity },
  },
};

export const squareAnimationProps = {
  as: motion.div,
  animate: {
    x: ['30%', '32%', '30%'],
    y: ['30%', '32%', '30%'],
    rotateX: 45,
  },
  transition: { duration: 5, repeat: Infinity },
};
