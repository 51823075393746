import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopContact } from './Contact.desktop';
import { MobileContact } from './Contact.mobile';

export const Contact = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileContact />;

  return <DesktopContact />;
};
