export const terms = [
  { items: new Array(3).fill(' '), lists: [] },
  {
    items: new Array(6).fill(' '),
    lists: [{ list: new Array(4).fill(' '), position: 3 }],
  },
  {
    items: new Array(12).fill(' '),
    lists: [
      { list: new Array(3).fill(' '), position: 4 },
      { list: new Array(6).fill(' '), position: 5 },
      { list: new Array(4).fill(' '), position: 11 },
      { list: new Array(4).fill(' '), position: 15 },
    ],
  },
  { items: new Array(1).fill(' '), lists: [] },
  { items: new Array(1).fill(' '), lists: [] },
  { items: new Array(1).fill(' '), lists: [] },
  { items: new Array(2).fill(' '), lists: [] },
  {
    items: new Array(2).fill(' '),
    lists: [{ list: new Array(7).fill(' '), position: 1 }],
  },
  { items: new Array(1).fill(' '), lists: [] },
  {
    items: new Array(4).fill(' '),
    lists: [{ list: new Array(2).fill(' '), position: 1 }],
  },
];
