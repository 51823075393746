import { COLORS, FlexColumn, TEXT_WEIGHTS } from 'modules/ui/components';
import Slider from 'react-slick';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)`
  width: 110%;
  transform: translateX(0%);

  .slick-list {
    height: 100%;
    width: 100%;
  }

  .slick-track {
    height: 100%;
    padding: 0;
  }

  .slick-arrow {
    width: 10%;
    color: #9a9a9a;
    content: '';
  }

  .slick-arrow::before {
    content: '';
  }

  .slick-arrow > svg {
    width: 20px;
    right: 25px;
    height: 34px;
    color: #9a9a9a;
  }

  .slick-next {
    right: 6%;
  }

  .slick-prev {
    left: -5%;
    z-index: 5;
  }

  .slide-wrapper {
    display: flex !important;
    height: 100%;
    max-width: 350px !important;
  }

  @media screen and (max-width: 1400px) {
    .slick-next {
      right: 2%;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    transform: translateX(-5%);

    .slick-next {
      right: -10%;
    }

    .slick-prev {
      left: -2%;
    }

    .wrapper {
      top: 20% !important;
    }
  }
`;

export const StlyedSliderCard = styled(FlexColumn)`
  border-radius: 20px;
  margin: 0 25px 25px 25px;
  align-items: center;
  justify-content: flex-end;
  background: ${COLORS.darkTeal};
  color: ${COLORS.lightBlue};
  position: relative;
  padding: 20px;
  aspect-ratio: 1 / 1;

  & > img {
    margin: 0 !important;
  }
  .wrapper {
    position: absolute;
    top: 30%;
    height: 200px;
  }

  & > button {
    width: 100%;
    display: flex;
    justify-self: flex-end;
    align-items: center;
    justify-content: center;
    background: ${COLORS.yellow};
    font-size: 25px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: ${TEXT_WEIGHTS.bolder};
    padding: 15px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 86%;
    left: 0;
  }
`;

export const StyledMobileSlider = styled(StyledSlider)`
  width: 110%;
  transform: translateX(-13%);

  .slick-arrow {
    top: 170px;
  }

  .slick-list {
    height: 100%;
    width: 100%;
  }

  .slick-next {
    right: -12%;
    z-index: 5;
  }

  .slick-prev {
    left: 5%;
    z-index: 5;
  }

  .slick-slide {
    padding: 0 30px 10px 30px;
    box-sizing: border-box;
  }

  .slick-slide > div {
    width: 100%;
  }

  .slick-arrow {
    width: 10%;
    height: 110%;
    color: #9a9a9a;
    content: '';
  }

  @media screen and (max-width: 550px) and (min-width: 470px) {
    .slick-slide {
      transform: translateX(4%);
    }

    .wrapper {
      transform: scale(1.5) translateY(25px);
    }

    button {
      top: 87%;
    }

    .slick-arrow {
      top: 45%;
    }
  }

  @media screen and (max-width: 480px) and (min-width: 380px) {
    width: 100%;
    transform: translateX(-14%);
    .slick-slide {
      transform: translateX(4%);
    }

    .wrapper {
      transform: scale(1) translateY(25px);
    }

    button {
      top: 87%;
    }

    .slick-arrow {
      top: 45%;
    }

    .slick-next {
      right: -20%;
      z-index: 5;
    }

    .slick-prev {
      left: 8%;
      z-index: 5;
    }
  }

  @media screen and (max-width: 380px) and (min-width: 305px) {
    width: 100%;
    transform: translateX(-13%);

    .slick-arrow {
      top: 45%;
    }

    .slick-next {
      right: -20%;
      z-index: 5;
    }

    .slick-prev {
      left: 5%;
      z-index: 5;
    }

    .wrapper {
      transform: scale(0.8);
    }
  }

  @media screen and (max-width: 305px) {
    transform: translateX(-17%);

    .slick-arrow {
      top: 45%;
    }

    .slick-next {
      right: -16%;
      z-index: 5;
    }

    .slick-prev {
      left: 12%;
      z-index: 5;
    }

    .wrapper {
      transform: scale(0.8);
    }
  }
`;

export const StyledTabletSlider = styled(StyledSlider)`
  .wrapper {
    top: 15% !important;
  }

  @media screen and (max-width: 700px) {
    .slide-wrapper {
      transform: scale(0.8) translateX(-10%);
    }
  }
`;
