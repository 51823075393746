import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { useScrollTop } from 'modules/hooks/useScrollTop';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Logo,
  Text,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { useMediaQuery } from 'react-responsive';
import { StyledFooterLink } from './styled';
import { linksCategories, linksSets, socialFooterLinks } from './utils';

export const DesktopFooter = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.COMPONENTS);

  const scrollTop = useScrollTop();

  const isTablet = useMediaQuery({
    query: `(max-width: 1100px)`,
  });

  const isSmallTablet = useMediaQuery({ query: `(max-width: 560px)` });

  return (
    <FlexRow
      w="100%"
      h="auto"
      as="footer"
      alignItems="center"
      justifyContent="center"
      p="30px 0"
      bg={COLORS.darkBlue}
    >
      <FlexColumn w="100%" h="100%" p="0 5%" justifyContent="center">
        <FlexRow
          as={isSmallTablet ? FlexColumn : FlexRow}
          w={isTablet ? '100%' : '100%'}
          h="auto"
          flexWrap="wrap"
          gap={!isTablet ? '5%' : '40px'}
          alignItems="flex-start"
          justifyContent={!isTablet ? 'space-between' : 'start'}
        >
          <FlexColumn w="auto">
            <Logo w={177} h={51} className="no-descale" />
            <FlexColumn m="40px 0" gap="8px" alignItems="flex-start">
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
              >
                Unter den Linden 10
              </Text>
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
              >
                10117
              </Text>
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
              >
                Berlin
              </Text>
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
              >
                +49 (0) 30 700 140 296
              </Text>
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
              >
                support@labk19.com
              </Text>
              <Text
                w="100%"
                size={TEXT_SIZES.xs}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.bold}
              >
                labk19.com
              </Text>
            </FlexColumn>
          </FlexColumn>
          {linksCategories.map((_, i) => (
            <FlexColumn w="auto" key={`link-category-${i}`} flexWrap="wrap">
              <Text
                w="100%"
                type={TEXT_TYPES.title}
                m="10px 0"
                color={COLORS.white}
                size="16px"
                weight={TEXT_WEIGHTS.bolder}
                textTransform="uppercase"
                textAlign="start !important"
              >
                {t(`footer.links.${i}.title`)}
              </Text>
              {linksSets[i].map((link, index) => (
                <Text
                  w="100%"
                  m="5px 0"
                  color={COLORS.white}
                  size={TEXT_SIZES.xxs}
                >
                  <StyledFooterLink
                    key={link}
                    to={link}
                    onClick={() => scrollTop()}
                  >
                    <Text
                      textAlign="center"
                      size="15px"
                      whiteSpace="pre-wrap"
                      weight={TEXT_WEIGHTS.tiny}
                    >
                      {t(`footer.links.${i}.items.${index}.title`)}
                    </Text>
                  </StyledFooterLink>
                </Text>
              ))}
            </FlexColumn>
          ))}
        </FlexRow>
        <FlexRow
          w="calc(80% - 10px)"
          justifyContent="space-between"
          alignItems="center"
          m="40px 0"
        >
          <FlexRow gap="30px">
            {socialFooterLinks.map(({ src, link }, i) => (
              <img
                style={{ cursor: 'pointer' }}
                src={src}
                alt={`footer-social-${i}`}
                onClick={() => window.open(link)}
                className="no-descale"
              />
            ))}
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
};
