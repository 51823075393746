import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import {
  FlexColumn,
  TEXT_TYPES,
  COLORS,
  TEXT_WEIGHTS,
  FlexRow,
  TEXT_SIZES,
  Button,
  BUTTON_TYPES,
  Text,
} from 'modules/ui/components';
import { Animatable, ANIMATIONS_TYPES } from 'modules/ui/components/Animatable';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { Card } from 'pages/Mobile';
import { useMediaQuery } from 'react-responsive';
import { subCardsImages, cardsImages, texts, cardImgaePath } from './utils';

export const DesktopVideoCreation = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  const isTablet = useMediaQuery({
    query: `(max-width: ${1200}px)`,
  });

  return (
    <FlexColumn w="100%" position="relative">
      <PageTitle title={t('videoCreation.title')} />
      <FlexColumn
        w="100%"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <FlexColumn w="100%" alignItems="center">
          <FlexColumn w="80%" alignItems="center">
            <FlexColumn w="100%" alignItems="center">
              <Text
                m="70px 0 30px 0"
                size="35px"
                letterSpacing="1.4px"
                type={TEXT_TYPES.title}
                color={COLORS.lightBlue}
                weight={TEXT_WEIGHTS.bolder}
              >
                {t('videoCreation.cards.0.title')}
              </Text>
              <Text
                w="80%"
                size="25px"
                type={TEXT_TYPES.text}
                color={COLORS.white}
                weight={TEXT_WEIGHTS.tiny}
                textAlign="center"
                letterSpacing="0.88px"
                lineHeight="30px"
              >
                {t('videoCreation.cards.0.texts.0')}
              </Text>
            </FlexColumn>
            <FlexColumn w="100%" m="120px 0" alignItems="center">
              <FlexColumn w="100%" alignItems="center">
                <img src="./assets/mobile/mobile-touch.png" alt="mobile" />
              </FlexColumn>
            </FlexColumn>
            <FlexRow
              m="50px 0"
              w="100%"
              justifyContent="space-between"
              flexDirection={isTablet ? 'column !important' : 'row'}
            >
              {subCardsImages.map((path, i) => (
                <FlexColumn
                  key={`sub-card-${i}`}
                  w={!isTablet ? '30%' : '100%'}
                  alignItems="center"
                  gap={isTablet ? '50px' : ''}
                  m={isTablet ? '20px' : ''}
                >
                  <img src={path} alt="mobile" />
                  <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                    <FlexColumn w="100%" alignItems="center">
                      <Text
                        m="30px 0 10px 0"
                        size="35px"
                        letterSpacing="1.4px"
                        type={TEXT_TYPES.title}
                        color={COLORS.lightBlue}
                        weight={TEXT_WEIGHTS.bolder}
                        whiteSpace="pre-wrap"
                        textAlign="center"
                      >
                        {t(`videoCreation.subCards.${i}.title`)}
                      </Text>
                      <Text
                        w="80%"
                        size={TEXT_SIZES.s}
                        type={TEXT_TYPES.text}
                        color={COLORS.white}
                        weight={TEXT_WEIGHTS.tiny}
                        textAlign="center"
                        letterSpacing="0.56px"
                        lineHeight="30px"
                        whiteSpace="pre-wrap"
                      >
                        {t(`videoCreation.subCards.${i}.text`)}
                      </Text>
                    </FlexColumn>
                  </Animatable>
                </FlexColumn>
              ))}
            </FlexRow>
          </FlexColumn>
          <FlexColumn
            m="100px 0 0 0"
            w="100%"
            p="80px 0 0 0"
            alignItems="center"
            justifyContent="center"
            bg={`linear-gradient(180deg, ${COLORS.darkBlue}, rgba(79, 255, 255, 0.25))`}
          >
            <FlexColumn w="80%" alignItems="center">
              {cardsImages.slice(1).map((path, i) => (
                <FlexRow
                  key={`card-${i}`}
                  w="100%"
                  m="50px 0"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={isTablet ? '50px' : ''}
                  style={{
                    flexDirection: isTablet
                      ? 'column'
                      : i % 2 === 0
                      ? 'row'
                      : 'row-reverse',
                  }}
                >
                  <FlexColumn w={!isTablet ? '60%' : '100%'}>
                    <Animatable animationType={ANIMATIONS_TYPES.appendBottom}>
                      <FlexColumn w="100%" alignItems="center">
                        <Text
                          m="20px 0"
                          size="32px"
                          letterSpacing="1.4px"
                          type={TEXT_TYPES.title}
                          color={COLORS.lightBlue}
                          weight={TEXT_WEIGHTS.bolder}
                          whiteSpace="pre-wrap"
                          textAlign={isTablet ? 'center' : 'start'}
                        >
                          {t(`videoCreation.cards.${i + 1}.title`)}
                        </Text>
                        {texts.map((_, textIndex) => (
                          <Text
                            w="80%"
                            m="15px 0"
                            size={TEXT_SIZES.s}
                            type={TEXT_TYPES.text}
                            color={COLORS.white}
                            weight={TEXT_WEIGHTS.tiny}
                            letterSpacing="0.56px"
                            lineHeight="25px"
                            whiteSpace="pre-wrap"
                          >
                            {t(
                              `videoCreation.cards.${i + 1}.texts.${textIndex}`,
                            )}
                          </Text>
                        ))}
                      </FlexColumn>
                    </Animatable>
                  </FlexColumn>
                  <FlexColumn w="40%" alignItems="center">
                    <img src={path} alt="mobile" />
                  </FlexColumn>
                </FlexRow>
              ))}
            </FlexColumn>
            <FlexRow
              m="120px 0 0 0"
              w="100%"
              bg={COLORS.lightBlue}
              alignItems="center"
              p="100px 0"
              justifyContent="center"
            >
              <FlexRow
                w="80%"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isTablet ? 'column !important' : 'row'}
                style={{ transform: isTablet ? 'scale(0.9)' : 'none' }}
              >
                <Text
                  size="50px"
                  letterSpacing="1.5px"
                  type={TEXT_TYPES.title}
                  color="#262938"
                  weight={TEXT_WEIGHTS.bolder}
                  whiteSpace="pre-wrap"
                >
                  {t(`videoCreation.contactSection.title`)}
                </Text>
                <Card bg={COLORS.darkBlue} m="0 40px" p="20px 20px">
                  <img src={cardImgaePath} alt="message" />
                  <Text
                    m="30px 0"
                    type={TEXT_TYPES.title}
                    color={COLORS.lightBlue}
                    size={TEXT_SIZES.xxl}
                    weight={TEXT_WEIGHTS.tiny}
                    letterSpacing="1.2"
                  >
                    {t(`videoCreation.contactSection.subTitle`)}
                  </Text>
                  <Button buttonType={BUTTON_TYPES.filled}>
                    <Text m="20px">
                      {t(`videoCreation.contactSection.button`)}
                    </Text>
                  </Button>
                </Card>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
};
