import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopProjectManagement } from './ProjectManagement.desktop';
import { MobileProjectManagement } from './ProjectManagement.mobile';

export const ProjectManagement = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileProjectManagement />;

  return <DesktopProjectManagement />;
};
