import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopSoftwareModernization } from './SoftwareModernization.desktop';
import { MobileSoftwareModernization } from './SoftwareModernization.mobile';

export const SoftwareModernization = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileSoftwareModernization />;

  return <DesktopSoftwareModernization />;
};
