import { Routes, Route } from 'react-router-dom';
import { AboutUs } from 'pages/AboutUs/AboutUs';
import { Contact } from 'pages/Contact/Contact';
import { HowWeWork } from 'pages/HowWeWork/HowWeWork';
import { Mobile } from 'pages/Mobile/Mobile';
import { Web } from 'pages/Web/Web';
import { Landing } from './pages/Landing/Landing';
import { TV } from 'pages/TV/TV';
import { AI } from 'pages/AI/AI';
import { DataAndAnalytics } from 'pages/Data&Analytics';
import { SoftwareModernization } from 'pages/SoftwareModernization';
import { Integration } from 'pages/Integration';
import { DigitalProduct } from 'pages/DigitalProduct';
import { Design } from 'pages/Design';
import { MediaPlatform } from 'pages/MediaPlatform';
import { VideoStreaming } from 'pages/VideoStreaming';
import { FAQ } from 'pages/FAQ';
import { TermsOfService } from 'pages/TermsOfServices';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { CookiesPolicy } from 'pages/CookiesPolicy';
import { Footer, Header } from 'modules/sections/components';
import { ProjectManagement } from 'pages/ProjectManagement';
import { VideoCreation } from 'pages/VideoCreation';

export const routes = [
  { path: '/', element: <Landing /> },
  { path: 'company', element: <></> },
  { path: 'product', element: <></> },
  { path: 'development', element: <></> },
  { path: 'management', element: <></> },
  { path: 'news-room', element: <></> },
  { path: 'contact', element: <Contact /> },
  { path: 'about-us', element: <AboutUs /> },
  { path: 'how-we-work', element: <HowWeWork /> },
  { path: 'web', element: <Web /> },
  { path: 'mobile', element: <Mobile /> },
  { path: 'tv-apps', element: <TV /> },
  { path: 'ai', element: <AI /> },
  { path: 'data-&-analytics', element: <DataAndAnalytics /> },
  { path: 'software-modernization', element: <SoftwareModernization /> },
  { path: 'integration', element: <Integration /> },
  { path: 'digital-product', element: <DigitalProduct /> },
  { path: 'ux-&-ui-design', element: <Design /> },
  { path: 'project-management', element: <ProjectManagement /> },
  { path: 'media-platform', element: <MediaPlatform /> },
  { path: 'video-streaming', element: <VideoStreaming /> },
  { path: 'faq', element: <FAQ /> },
  { path: 'term&service', element: <TermsOfService /> },
  { path: 'privacy-policy', element: <PrivacyPolicy /> },
  { path: 'cookies-policy', element: <CookiesPolicy /> },
  { path: 'video-creation', element: <VideoCreation /> },
];

const Router = () => {
  return (
    <>
      <Header />
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} index path={path} element={element} />
        ))}
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
