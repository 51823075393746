import { motion, useInView } from 'framer-motion';
import { FC, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { COLORS } from './Flex';
// import ScaleText  from 'react-scale-text';

export const enum TEXT_TYPES {
  title = 'TITLE',
  text = 'TEXT',
}

export const enum TEXT_SIZES {
  xxs = '12px',
  xs = '15px',
  s = '20px',
  l = '26px',
  xl = '30px',
  xxl = '45px',
  mega = '55px',
}

export const enum TEXT_WEIGHTS {
  tiny = '200',
  bold = '600',
  bolder = '900',
}

export const enum LETTERS_SPACING {
  s = '10px',
  l = '20px',
  xl = '35px',
}

interface Props {
  type?: TEXT_TYPES;
  color?: COLORS | string;
  weight?: TEXT_WEIGHTS | string;
  size?: TEXT_SIZES | string;
  children?: ReactNode;
  m?: string;
  w?: string;
  textAlign?: string;
  className?: string;
  animatable?: boolean;
  textTransform?: string;
  letterSpacing?: string;
  whiteSpace?: string;
  lineHeight?: string;
  font?: string;
}

const StyledTitle = styled.h2<Props>`
  font-size: ${({ size }) => size || TEXT_SIZES.xxl};
  font-weight: ${({ weight }) => weight || TEXT_WEIGHTS.bold};
  color: ${({ color }) => color || COLORS.lightBlue};
  margin: ${({ m }) => m || 10};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  z-index: 2;
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  width: ${({ w }) => w || 'auto'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'auto'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'auto'};
  line-height: ${({ lineHeight }) => lineHeight || 'auto'};
  font: ${({ font }) => font || 'auto'};
`;

const StyledText = styled.span<Props>`
  font-size: ${({ size }) => size || COLORS.black};
  color: ${({ color }) => color || COLORS.black};
  font-weight: ${({ weight }) => weight || TEXT_WEIGHTS.bold};
  margin: ${({ m }) => m || 10};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  z-index: 2;
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  width: ${({ w }) => w || 'auto'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'auto'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'auto'};
  line-height: ${({ lineHeight }) => lineHeight || 'auto'};
  font: ${({ font }) => font || 'auto'};
`;

export const Text: FC<Props> = ({
  type = TEXT_TYPES.text,
  color = COLORS.black,
  weight = TEXT_WEIGHTS.bold,
  size = TEXT_SIZES.s,
  children = null,
  className = '',
  m,
  w = 'auto',
  textAlign,
  animatable,
  textTransform,
  letterSpacing,
  whiteSpace,
  lineHeight,
  font,
}) => {
  const visibilityRef = useRef(null);
  const isInView = useInView(visibilityRef, { once: true });

  const animatableProps = animatable
    ? {
        as: isInView
          ? type === TEXT_TYPES.title
            ? motion.h2
            : motion.span
          : '',
        transition: { duration: 1 },
        animate: { x: 0 },
        initial: { x: type === TEXT_TYPES.title ? '-40%' : '40%' },
      }
    : {};

  if (type === TEXT_TYPES.title) {
    return (
      <StyledTitle
        {...animatableProps}
        className={className}
        color={color}
        weight={weight}
        size={size}
        m={m}
        textAlign={textAlign}
        ref={visibilityRef}
        textTransform={textTransform}
        w={w}
        letterSpacing={letterSpacing}
        whiteSpace={whiteSpace}
        lineHeight={lineHeight}
        font={font}
      >
        {children}
      </StyledTitle>
    );
  }

  if (type === TEXT_TYPES.text) {
    return (
      <StyledText
        {...animatableProps}
        className={className}
        color={color}
        weight={weight}
        size={size}
        m={m}
        textAlign={textAlign}
        ref={visibilityRef}
        textTransform={textTransform}
        w={w}
        whiteSpace={whiteSpace}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
        font={font}
      >
        {children}
      </StyledText>
    );
  }

  return null;
};
