export const headerLinks = [
  {
    path: 'company',
    title: 'company',
    subLinks: [
      { path: 'about-us', title: 'about us' },
      { path: 'how-we-work', title: 'how we work' },
    ],
  },
  {
    path: 'product',
    title: 'product',
    subLinks: [
      { path: 'media-platform', title: 'media platform' },
      { path: 'video-streaming', title: 'video streaming' },
    ],
  },
  {
    path: 'development',
    title: 'development',
    subLinks: [
      { path: 'web', title: 'web' },
      { path: 'mobile', title: 'mobile' },
      { path: 'tv-apps', title: 'tv apps' },
      { path: 'ai', title: 'ai & machine learning' },
      { path: 'data-&-analytics', title: 'data & analytics' },
      { path: 'software-modernization', title: 'software modernization' },
      { path: 'integration', title: 'integration' },
    ],
  },
  {
    path: 'management',
    title: 'management',
    subLinks: [
      { path: 'video-creation', title: 'video creation' },
      { path: 'digital-product', title: 'digital product' },
      { path: 'project-management', title: 'project management' },
      { path: 'ux-&-ui-design', title: 'UX / UI design' },
    ],
  },
  {
    path: 'help-center',
    title: 'help-center',
    subLinks: [
      { path: 'faq', title: 'faq' },
      { path: 'term&service', title: 'term&service' },
      { path: 'privacy-policy', title: 'privacy policy' },
      { path: 'cookies-policy', title: 'cookies policy' },
    ],
  },
  { path: 'contact', title: 'contact' },
];
