import styled from 'styled-components';
import { StyledMobileSlider } from '../TopServices/styled';

export const StyledSlider = styled(StyledMobileSlider)`
  width: 110%;
  transform: translate(0%);

  .slick-list {
    height: 100%;
    width: 100%;
  }

  .slick-next {
    right: 2%;
    z-index: 5;
  }

  .slick-prev {
    left: 2%;
    z-index: 5;
  }

  .slick-arrow:hover {
    background: transparent;
  }

  .slick-arrow {
    width: 10%;
    height: 110%;
    background: transparent;
    color: #9a9a9a;
    content: '';
    transform: translateY(-130px);
  }

  .slide-wrapper {
    width: 100vw;
    transform: translateX(-50px);
  }
`;
