import { useTranslation } from 'react-i18next';

export enum NAME_SPACES {
  PAGES = 'pages',
  COMPONENTS = 'components',
  FORMS = 'forms',
}

export const useTranslationNameSpace = (nameSpace: NAME_SPACES) =>
  useTranslation([nameSpace]);
