import { COLORS } from 'modules/ui/components';
import styled from 'styled-components';

export const Marker = styled.div`
  width: 8px;
  height: 8px;
  background: ${COLORS.lightBlue};
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  margin-top: 10px;
`;
