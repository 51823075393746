import React from 'react';
import { FlexColumn, FlotableElement, Square } from 'modules/ui/components';
import { PageTitle } from 'modules/ui/components/PageTitle';
import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { LetsTalkSection } from 'modules/sections/components/LetsTalkSection';
import { OurOficesSection } from 'modules/sections/components/OurOficesSection/OurOficesSection';

export const DesktopContact = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);

  return (
    <FlexColumn w="100%" h="100%">
      <PageTitle title={t('contact.title')} />
      <FlexColumn
        w="100%"
        position="relative"
        bgSize="cover"
        bg="url(./assets/backgrounds/background-4.png)"
      >
        <LetsTalkSection />
        <OurOficesSection />
        <FlotableElement left="95%" top="5%" zIndex="0">
          <Square />
        </FlotableElement>
      </FlexColumn>
    </FlexColumn>
  );
};
