import { useMediaQuery } from 'react-responsive';
import { DesktopLanding } from './Landing.desktop';
import { MobileLanding } from './Landing.mobile';

export const Landing = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 880px)`,
  });

  if (isMobile) return <MobileLanding />;

  return <DesktopLanding />;
};
