import { FlexColumn } from 'modules/ui/components';
import styled from 'styled-components';

export const RotatedWrapper = styled(FlexColumn)`
  rotate: -45deg;
`;

export const Video = styled.iframe`
  width: 100%;
  height: 100%;
  z-index: 15;
  border: none;
`;

export const VideoButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
