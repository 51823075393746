import { useState } from 'react';
import { motion } from 'framer-motion';
import {
  COLORS,
  FlexColumn,
  FlexRow,
  Section,
  Square,
  Text,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { NAME_SPACES, useTranslationNameSpace } from 'i18n/hooks';
import { Video, VideoButton } from './styled';
import { SPACINGS } from 'modules/ui/components/Spacings';
import { useMediaQuery } from 'react-responsive';

const videoAnimationDuration = 0.5;

export const MainSection = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const onTogglePlayVideo = () => {
    setIsVideoPlaying(!isVideoPlaying);
  };

  const isTablet = useMediaQuery({
    query: `(max-width: ${1000}px)`,
  });

  return (
    <Section h="calc(100vh - 60px)">
      <FlexRow
        w="100%"
        h="100%"
        position="relative"
        alignItems="center"
        as={motion.div}
        animate={{ opacity: [0, 1] }}
        transition={{ duration: 1 }}
      >
        <FlexColumn w="60%" alignItems="center">
          <FlexColumn w="80%">
            <Text
              size={!isTablet ? TEXT_SIZES.xxl : '30px'}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              type={TEXT_TYPES.title}
              lineHeight="60px"
              animatable
            >
              {t('landing.mainSection.title')}
            </Text>
            <Text
              size={!isTablet ? TEXT_SIZES.l : '20px'}
              color={COLORS.white}
              weight={TEXT_WEIGHTS.tiny}
              lineHeight="35px"
              whiteSpace="pre-wrap"
              animatable
              m={`${SPACINGS.l} 0`}
            >
              {t('landing.mainSection.text')}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <Square
          zIndex="2"
          position="absolute"
          as={motion.div}
          animate={
            isVideoPlaying
              ? {
                  rotate: [-45, 0],
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: '0%',
                  top: '0%',
                }
              : {
                  rotate: [0, 45],
                  width: '35vw',
                  height: '35vw',
                  position: 'absolute',
                  left: '65%',
                  top: '15%',
                }
          }
          transition={{ duration: videoAnimationDuration }}
        >
          {isVideoPlaying ? (
            <FlexRow
              w="100%"
              h="100%"
              as={motion.div}
              animate={{ opacity: [0, 1] }}
              transition={{ duration: videoAnimationDuration }}
              onClick={onTogglePlayVideo}
            >
              <Video
                style={{
                  background: 'black',
                  zIndex: 5,
                  pointerEvents: isVideoPlaying ? 'none' : 'all',
                }}
                src="https://www.youtube.com/embed/jUKgxlxgAJg?autoplay=1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </FlexRow>
          ) : (
            <VideoButton
              as={motion.button}
              animate={{
                rotate: [-45, 315],
                scale: [1, 1.5, 1],
              }}
              transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 2 }}
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.3 },
              }}
              className="rotatable"
              onClick={onTogglePlayVideo}
            >
              <img alt="play" src="./assets/utils/video-button.png" />
            </VideoButton>
          )}
        </Square>
      </FlexRow>
    </Section>
  );
};
