import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';
import { DesktopVideoStreaming } from './VideoStreaming.desktop';
import { MobileVideoStreaming } from './VideoStreaming.mobile';

export const VideoStreaming = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  if (isMobile) return <MobileVideoStreaming />;

  return <DesktopVideoStreaming />;
};
