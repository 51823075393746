export const cards = new Array(4).fill(' ');
export const subCards = new Array(3).fill(' ');
export const tabs = new Array(5).fill(' ');

export const sliderMobileStyles = `
width: 110%;
transform: translateX(-10%);

.slick-arrow {
    width: 40px;
    background: none !important;
    height: 100%;
  };

  .slide {
    transform: translateX(-150px);
  };

  .img {
    max-width: 300px;
    height: auto;
  }

  .slick-prev {
    left: 5%;
  };

  .slick-next {
    right: -10%
  };

  .slick-slide div {
    width: 80%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .slick-slide {
    transform: translateX(5px);
  }
`;

export const sliderTabsMobileStyles = `
width: 100%;
transform: translateX(-20px);

.slick-arrow {
    width: 40px;
    background: none !important;
    height: 100%;
  };

  .slide {
    transform: translateX(-150px);
  };

  .img {
    max-width: 200px;
    height: auto;
  }

  .slick-prev {
    left: 15px;
  };

  .slick-next {
    right: -15px
  };

  .slick-slide div {
    width: 80%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .slick-slide {
    transform: translateX(5px);
  }
`;
