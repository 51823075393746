import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import { Partners, TopServices } from 'modules/sections/components';
import { MobileGetInTouchSection } from 'modules/sections/components/GetInTouchSection/GetInTouchSection.mobile';
import { textsQuantities } from 'modules/sections/components/HowWeDoSection/utils';
import { MobileVideo } from 'modules/sections/components/Video/Video.mobile';
import {
  COLORS,
  FlexColumn,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  Text,
  Button,
  BUTTON_TYPES,
} from 'modules/ui/components';
import { useNavigate } from 'react-router-dom';

export const MobileLanding = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const navigate = useNavigate();

  return (
    <FlexColumn w="100%">
      <FlexColumn
        w="100%"
        alignItems="center"
        bg="url(./assets/backgrounds/background-4.png)"
        bgPosition="30% 10%"
      >
        <FlexColumn w="80%" m="30px 0 40px 0" gap="20px">
          <Text
            m="0"
            size="33px"
            color={COLORS.white}
            weight={TEXT_WEIGHTS.tiny}
            type={TEXT_TYPES.title}
            lineHeight="40px"
          >
            {t('landing.mainSection.title')}
          </Text>
          <Text
            size="21px"
            color={COLORS.white}
            weight={TEXT_WEIGHTS.tiny}
            lineHeight="26px"
          >
            {t('landing.mainSection.text')}
          </Text>
        </FlexColumn>
        <MobileVideo
          h="400px"
          src="https://www.youtube.com/embed/jUKgxlxgAJg?rel=0&autoplay=1&muted=1"
        />
      </FlexColumn>
      <Partners />
      <FlexColumn
        w="100%"
        alignItems="center"
        bg="url(./assets/backgrounds/background-4.png)"
        bgPosition="30% 10%"
      >
        <FlexColumn w="80%" alignItems="center">
          <Text
            w="100%"
            size="36px"
            lineHeight="32px"
            color={COLORS.lightBlue}
            weight={TEXT_WEIGHTS.bolder}
            type={TEXT_TYPES.title}
          >
            {t('landing.companySection.title')}
          </Text>
          <Text
            size="16px"
            color={COLORS.white}
            weight={TEXT_WEIGHTS.tiny}
            m={'15px 0'}
            animatable
            lineHeight="24px"
          >
            <strong>Lab K19</strong> {t('landing.companySection.text.0')}
          </Text>
          <Text
            size="16px"
            color={COLORS.white}
            weight={TEXT_WEIGHTS.tiny}
            animatable
            m="20px 0"
            lineHeight="24px"
          >
            {t('landing.companySection.text.1')}
          </Text>
        </FlexColumn>
        <TopServices />
        <FlexColumn
          w="100%"
          m="40px 0"
          alignItems="center"
          bg={COLORS.lightBlue}
        >
          <FlexColumn w="80%" alignItems="center" gap="50px">
            <FlexColumn w="100%" alignItems="center" m="20px 0 0 0">
              <Text
                w="100%"
                m="0 0 22px 0"
                size="36px"
                lineHeight="32px"
                color={COLORS.darkBlue}
                weight={TEXT_WEIGHTS.bolder}
                type={TEXT_TYPES.title}
              >
                {t('landing.whatWeDoSection.title')}
              </Text>
              <img
                src="./assets/utils/dev-sign.png"
                alt="development"
                width={140}
                height={92}
                style={{ margin: '27px 0' }}
              />
              <Text
                m="0px 0px 15px 0"
                size="40px"
                type={TEXT_TYPES.title}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
              >
                {t('landing.whatWeDoSection.cards.0.title')}
              </Text>
              {textsQuantities[0].map((_, i) => (
                <Text
                  size="28px"
                  color={COLORS.darkBlue}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.9px"
                  lineHeight="35px"
                  textAlign="center"
                >
                  {t(`landing.whatWeDoSection.cards.0.texts.${i}`)}
                </Text>
              ))}
            </FlexColumn>
            <FlexColumn w="100%" alignItems="center" m="30px 0 0 0">
              <img
                src="./assets/utils/puzzle-sign.png"
                alt="strategy"
                style={{ margin: '27px 0' }}
              />
              <Text
                m="0px 0px 15px 0"
                size="40px"
                type={TEXT_TYPES.title}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
              >
                {t('landing.whatWeDoSection.cards.1.title')}
              </Text>
              {textsQuantities[1].map((_, i) => (
                <Text
                  size="28px"
                  color={COLORS.darkBlue}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.9px"
                  lineHeight="35px"
                  textAlign="center"
                >
                  {t(`landing.whatWeDoSection.cards.1.texts.${i}`)}
                </Text>
              ))}
            </FlexColumn>
            <FlexColumn m="40px 0">
              <Button
                buttonType={BUTTON_TYPES.filled}
                onClick={() => navigate('/about-us')}
              >
                {t('landing.whatWeDoSection.button')}
              </Button>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          m="20px 0 0 0"
          alignItems="center"
          bg={COLORS.darkBlue}
        >
          <FlexColumn w="80%" alignItems="center" gap="50px">
            <FlexColumn w="100%" alignItems="center" m="30px 0 0 0">
              <img src="./assets/utils/agile-sign.png" alt="development" />
              <Text
                m="15px 0"
                size="40px"
                type={TEXT_TYPES.title}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
                color={COLORS.lightBlue}
              >
                {t('landing.howWeDoSection.cards.0.title')}
              </Text>
              {textsQuantities[0].map((_, i) => (
                <Text
                  size="28px"
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.9px"
                  lineHeight="35px"
                  textAlign="center"
                >
                  {t(`landing.howWeDoSection.cards.0.texts.${i}`)}
                </Text>
              ))}
            </FlexColumn>
            <FlexColumn w="100%" alignItems="center" m="30px 0 0 0">
              <img src="./assets/utils/folder-sign.png" alt="strategy" />
              <Text
                m="15px 0"
                size="40px"
                type={TEXT_TYPES.title}
                weight={TEXT_WEIGHTS.bolder}
                textAlign="center"
                color={COLORS.lightBlue}
              >
                {t('landing.howWeDoSection.cards.1.title')}
              </Text>
              {textsQuantities[1].map((_, i) => (
                <Text
                  size="28px"
                  color={COLORS.white}
                  weight={TEXT_WEIGHTS.tiny}
                  letterSpacing="0.9px"
                  lineHeight="35px"
                  textAlign="center"
                >
                  {t(`landing.howWeDoSection.cards.1.texts.${i}`)}
                </Text>
              ))}
            </FlexColumn>
            <FlexColumn m="40px 0">
              <Button buttonType={BUTTON_TYPES.filled}>
                {t('landing.howWeDoSection.button')}
              </Button>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          w="100%"
          p="100px 0"
          bg={COLORS.darkBlue}
          alignItems="center"
        >
          <FlexColumn
            w="100%"
            bg="url(./assets/backgrounds/bg-mobile.png)"
            alignItems="center"
            justifyContent="center"
          >
            <MobileVideo
              h="400px"
              src="https://www.youtube.com/embed/uVUfUOd1-fQ?autoplay=1"
            />
          </FlexColumn>
        </FlexColumn>
        <MobileGetInTouchSection
          title={t(`landing.getInTouchSection.title`)!}
        />
      </FlexColumn>
    </FlexColumn>
  );
};
