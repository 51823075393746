import { useInView, motion } from 'framer-motion';
import { Section, FlexRow, COLORS } from 'modules/ui/components';
import { useRef } from 'react';
import { partnersImages } from './utils';

export const MobilePartners = () => {
  const container = useRef(null);
  const isInView = useInView(container, { once: true });

  return (
    <Section h="230px">
      <FlexRow
        w="calc(100% - 80px)"
        h="100%"
        p="0 40px"
        alignItems="center"
        justifyContent="space-between"
        bg={COLORS.lightBlue}
        gap="50px"
        ref={container}
        zIndex="3"
        style={{ overflow: 'auto' }}
      >
        {partnersImages.map((src, i) => (
          <FlexRow
            w="auto"
            justifyContent="center"
            key={`partner-${i}`}
            as={isInView ? motion.div : FlexRow}
            initial={{ y: `${-20 * (i + 1)}px`, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0 + i * 0.3 }}
            m="0 5%"
          >
            <img height={120} src={src} alt={`partner-${i}`} />
          </FlexRow>
        ))}
      </FlexRow>
    </Section>
  );
};
